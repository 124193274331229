import React, { ReactElement } from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { IPropsForm } from './Enrollment.interfaces'
import { EnrollmentFormAdapter } from './EnrollmentForm.constants'
import EnrollmentFieldChecker from './Enrollment.fieldChecker'
import validate from './validate'

const EnrollmentForm = (props: IPropsForm & InjectedFormProps | IPropsForm): ReactElement => {
  return (
  <>

      <div>{props.children}</div>
      <EnrollmentFieldChecker nameField='submitButton' excludedFields={props.excludedFields}>

      </EnrollmentFieldChecker>
   </>
  )
}

EnrollmentForm.defaultProps = {
  invalid: false,
  handleSubmit () {}
}

EnrollmentForm.displayName = 'EnrollmentForm'

export { EnrollmentForm }

export default reduxForm<any, IPropsForm>({
  form: EnrollmentFormAdapter.NAME,
  enableReinitialize: true,
  validate,
  shouldValidate () {
    // TODO find better way to retrigger validation because its not good for performance reason
    return true
  }
})(EnrollmentForm)
