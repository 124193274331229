const localStorage = window.localStorage

export function setItemToLS (name: string, value: string): void {
  localStorage.setItem(name, value)
}

export function getItemFromLS (name: string): string | null {
  return localStorage.getItem(name)
}

export function removeItemFromLS (name: string) {
  localStorage.removeItem(name)
}
