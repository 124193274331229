import React, { ReactElement } from 'react'
import { Translate } from 'react-localize-redux'
import cn from 'classnames'

import styles from '../Fields.module.scss'

interface IProps {
  date: string
  day: string
  error?: string
}

const CalendarView = ({ date, day, error }: IProps): ReactElement => {
  const isActive = day !== '00'

  return (
    <div className={styles.calendar}>
      <svg
        viewBox='0 0 100 100'
        fill='#ffffff'
        className={cn(styles.calenderImg, { [styles.calenderImgActive]: isActive })}
      >
        <rect fill='transparent' stroke='inherit' x='10' y='10' width='80' height='80' rx='10' />
        <path stroke='inherit' d='M 10 30 H 90' />
        <path stroke='inherit' d='M 30 20 V 0' />
        <path stroke='inherit' d='M 70 20 V 0' />
        <text x='50%' y='60px' dominantBaseline='middle' textAnchor='middle'>{day}</text>
      </svg>

      {date &&
        <p className={styles.calendarDate}>{date}</p>
      }

      {error &&
        <p className={styles.calendarError}><Translate id={error} /></p>
      }
    </div>
  )
}

export default CalendarView
