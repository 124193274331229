import { ThunkDispatch } from 'redux-thunk'
import { Dispatch } from 'redux'
import Cookies from 'js-cookie'
import { removeItemFromLS } from 'shared/helpers/LocalStorage'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import { actionCoreApi, buildActionType } from 'core/utils/api'
import { IAppState } from 'core/store/store'
import HTTPClient from 'core/services/HTTPClient'
import { actionErrorNotifier } from 'core/utils/helpers'
import { IRouteParams, IVerificationFormData } from 'public-pages/verification/Verification.interfaces'
import { ISetupUserFormValues } from 'public-pages/setup-user/SetupUser.interfaces'
import { ISetupLicenseFormValues } from 'public-pages/license/License.interfaces'
import { fetchProfile } from './Profile.actions'
import { IAction } from './Actions.interfaces'
import ActionsList from './Actions.list'
import { ActionType } from '../utils/interfaces'
import {
  ACCOUNT_EXPIRY_NOTIFICATION,
  DCONE_ACCESS_TOKEN_COOKIE_NAME,
  DCONE_REFRESH_TOKEN_COOKIE_NAME,
  PAGE_LEVEL_ERROR_NOTIFICATION,
  PAGE_LEVEL_WARN_NOTIFICATION
} from 'core/utils/constants'
import { imauthLogoutUrl } from 'configs/Path.configs'
import { showNotification } from './Notifications.actions'
import { generateRandomId } from 'shared/helpers/utils'
import { NotificationTypes } from 'reducers/Reducers.interfaces'

export const setupUser = (formData: ISetupUserFormValues) =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.SetupUser,
    HTTPUrlHelper.getSetupUserAPI(),
    'POST',
    formData
  ))

export const setupLicense = (formData: ISetupLicenseFormValues): ActionType =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.SetupLicense,
    HTTPUrlHelper.getSetupLicenseAPI(),
    'POST',
    formData
  ))

export function logout () {
  removeItemFromLS('activeSection')
  removeItemFromLS(ACCOUNT_EXPIRY_NOTIFICATION)
  removeItemFromLS(PAGE_LEVEL_ERROR_NOTIFICATION)
  removeItemFromLS(PAGE_LEVEL_WARN_NOTIFICATION)
  removeItemFromLS('account')
  window.location.href = `${imauthLogoutUrl}`
  return { type: ActionsList.AuthLogout }
}

export function setHubVisible () {
  return { type: ActionsList.SetHubVisible }
}

export function updateLicense (formData: ISetupLicenseFormValues) {
  return async (dispatch: ThunkDispatch<any, any, IAction>): Promise<void> => {
    dispatch({ type: ActionsList.UpdateLicenseRequest })
    const URL: string = HTTPUrlHelper.getUpdateLicenseAPI()
    const { data, status } = await HTTPClient.POST(URL, formData)
    if (status === 200) {
      dispatch({ type: ActionsList.UpdateLicenseSuccess })
      dispatch(showNotification({
        id: generateRandomId(),
        message: 'notifications.license.licenseUpdateTitle',
        type: NotificationTypes.success
      }))
    }
    if (data.error) {
      dispatch({ type: ActionsList.UpdateLicenseFailure })
      dispatch(showNotification({
        id: generateRandomId(),
        message: data.error.message ? `${data.error.message}` : `notifications.exceptions.${data.error.status}`,
        type: NotificationTypes.error
      }))
    }
  }
}

export function refreshToken () {
  return async (dispatch: Dispatch): Promise<void> => {
    const refreshToken: string | undefined = Cookies.get(DCONE_REFRESH_TOKEN_COOKIE_NAME)

    if (!refreshToken) {
      dispatch(logout())
    }

    try {
      const URL: string = HTTPUrlHelper.getRefreshTokenAPI()
      const { data, status } = await HTTPClient.POST(URL, { refresh_token: refreshToken })

      if (data && status === 200) {
        dispatch({
          type: buildActionType(ActionsList.AuthToken, 'success'),
          payload: {
            expiresAt: data.expires_at
          }
        })

        Cookies.remove(DCONE_REFRESH_TOKEN_COOKIE_NAME, { domain: window.location.host });
        Cookies.set(DCONE_ACCESS_TOKEN_COOKIE_NAME, data.access_token, {secure: true})
        Cookies.set(DCONE_REFRESH_TOKEN_COOKIE_NAME, data.refresh_token, {secure: true})
      }
    } catch {
      dispatch(logout())
    }
  }
}

export const verifyUser = (formData: IVerificationFormData, routeParams: IRouteParams) =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.VerifyUser,
    HTTPUrlHelper.getPasswordVerificationAPI(routeParams.userId),
    'POST',
    {
      verification_code: routeParams.verificationCode,
      login: formData.login,
      password: formData.password
    }
  ))

export function loginSuccess (redirectUrl: string, accessToken: string, expiresAt: string) {
  return async (dispatch: ThunkDispatch<IAppState, any, IAction>): Promise<void> => {
    dispatch({
      type: buildActionType(ActionsList.AuthToken, 'success'),
      payload: {
        accessToken: accessToken,
        expiresAt: expiresAt
      }
    })
    dispatch(fetchProfile())
  }
}
