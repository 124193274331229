import { formValueSelector } from 'redux-form'
import { IFields } from './interfaces'
import { IAppState } from '../store/store'

export class FormAdapter {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(formFields: IFields, formName: string) {
    this.formName = formName
    this.formFields = formFields
  }

  private readonly formName: string
  private readonly formFields: IFields

  public get fields(): IFields {
    return this.formFields
  }

  public get name(): string {
    return this.formName
  }

  public get formSelector(): (state: IAppState, ...field: string[]) => any {
    return formValueSelector(this.formName)
  }
}
