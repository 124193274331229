import React, { Component, ReactElement } from 'react'
import { ymdFormat } from 'core/utils/constants'
import moment, { Moment } from 'moment'
import { Translate } from 'react-localize-redux'
import {
  DatePicker
} from '@digicert/dcone-common-ui'
import cn from 'classnames'

import CalendarView from './CalerndarView'
import { disabledDateFromYesterday } from 'shared/helpers/DateTimeHelper'
import { IDatePickerFieldProps } from '../Fields.interfaces'
import styles from '../Fields.module.scss'
import { pipeValidators } from 'core/utils/helpers'

interface IState {
  date: string | null
  day: string
}

export default class DatePickerField extends Component<IDatePickerFieldProps, IState> {
  state = { date: '', day: '00' }
  static getDerivedStateFromProps (nextProps: IDatePickerFieldProps, prevState: IState): IState | null {
    if (nextProps.input.value && nextProps.input.value !== prevState.date) {
      const { value } = nextProps.input
      return {
        date: value,
        day: value ? moment(value).date().toString() : '00'
      }
    }

    return null
  }

  static defaultProps = {
    disabledDateValidators: []
  }

  public render (): ReactElement {
    const {
      label,
      input,
      placeholder,
      calendarView,
      meta: { touched, error },
      disabledDateValidators,
      required,
      dateFormat
    } = this.props

    return (
      <div className={cn(styles.inputWrap, { [styles.calendarView]: calendarView })}>

        {calendarView &&
          <CalendarView
            date={this.state.date}
            day={this.state.day}
            error={(touched && error) && error}
          />
        }

        <Translate>
          {({ translate }) =>
            <DatePicker
              label={label ? required ? translate(label) + ' * ' : translate(label) : ''}
              onChange={this.handleChange}
              disabledDate={pipeValidators(disabledDateFromYesterday, ...disabledDateValidators)}
              //@ts-ignore
              placeholder={placeholder ? translate(placeholder) : ''}
              value={this.state.date ? moment(this.state.date) : null}
              format={dateFormat}
            />
          }
        </Translate>

        {touched && !calendarView && (error &&
          <label className={styles.errorLabel}><Translate id={error} /></label>
        )}
      </div>
    )
  }

  private handleChange = (momentDate: Moment | null): void => {
    this.setState({
      date: momentDate ? momentDate.format(ymdFormat) : null,
      day: momentDate ? momentDate.date().toString() : ''
    })

    this.props.input.onChange(momentDate ? momentDate.format('YYYY-MM-DD') : null)
  }
}
