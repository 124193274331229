import { actionCoreApi } from 'core/utils/api'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import ActionsList from './Actions.list'
import { actionErrorNotifier } from '../utils/helpers'

export const fetchLocales = () =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.FetchLocales,
    HTTPUrlHelper.getLocalesAPI()
  ))

export const fetchFeatures = () =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.GetFeatures,
    HTTPUrlHelper.getFeaturesAPI()
  ))