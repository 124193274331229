import { ThunkDispatch } from 'redux-thunk'
import { destroy } from 'redux-form'
import { Dispatch } from 'react'
import { ActionType } from 'core/utils/interfaces'
import ActionsList from 'core/actions/Actions.list'
import { actionCoreApi } from 'core/utils/api'
import { actionErrorNotifier } from 'core/utils/helpers'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import { IAppState } from '../store/store'
import { IAction } from 'secure-pages/tenants/Tenants.types'

export function destroyForm (name: string): (dispatch: ThunkDispatch<IAppState, any, IAction>) => Promise<void> {
  return async (dispatch: Dispatch<IAction>): Promise<void> => {
    dispatch(destroy(name))
  }
}

export function checkLicense (): ActionType {
  return actionErrorNotifier(actionCoreApi<IAction>(
    ActionsList.FetchLicense,
    HTTPUrlHelper.getLicense()
  ))
}
