import { BasePathConst } from '../../../configs/Path.configs'

export const outdatedVersionError = 'outdatedVersion'
export const invalidSemverError = 'invalidSemver'
export const notSupportedOSError = 'notSupportedOS'

export const minimalVersion = '3.3.1'

export const ddcWizardInstallLink = 'https://pki-ddc.symauth.com/desktopclient'
export const ddcInternalInstallLink = extension =>
  `${BasePathConst}/management-files/ddc/DigiCert-Desktop-Installer-3.3.1.${extension}`

export const initTimeout = 5000
