import ActionsList from 'core/actions/Actions.list'
import { IAction } from 'core/actions/Actions.interfaces'
import { buildActionType } from 'core/utils/api'
import { addPropsToObjectInArray, updateObjectInArray } from './Reducers.helpers'
import { IAccounts } from './Reducers.interfaces'

const initialState: IAccounts = {
  items: [],
  total: 0,
  isLoading: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false
}

export default function accounts (state: IAccounts = initialState, action: IAction) {
  switch (action.type) {
    // fetch and sort accounts
    case buildActionType(ActionsList.FetchAccounts, 'request'):
      return { ...state, isLoading: true }
    case buildActionType(ActionsList.FetchAccounts, 'success'):
      return {
        ...state,
        isLoading: false,
        items: action.payload.items,
        total: action.payload.total
      }
    case buildActionType(ActionsList.FetchAccounts, 'failure'):
      return { ...state, isLoading: false }

    // edit single Account
    case buildActionType(ActionsList.EditAccount, 'request'):
      return { ...state, isEditing: true }
    case buildActionType(ActionsList.EditAccount, 'success'):
    case buildActionType(ActionsList.EditAccount, 'failure'):
      return { ...state, isEditing: false }

    // create new Account
    case buildActionType(ActionsList.CreateAccount, 'request'):
      return { ...state, isCreating: true }
    case buildActionType(ActionsList.CreateAccount, 'success'):
    case buildActionType(ActionsList.CreateAccount, 'failure'):
      return { ...state, isCreating: false }

    // fetch Account by id
    case buildActionType(ActionsList.FetchAccount, 'request'):
      return { ...state, isLoading: true }
    case buildActionType(ActionsList.FetchAccount, 'success'):
      return {
        ...state,
        isLoading: false,
        items: updateObjectInArray(state.items, action.payload)
      }
    case buildActionType(ActionsList.FetchAccount, 'failure'):
      return { ...state, isLoading: false }

    //  delete account
    case buildActionType(ActionsList.DeleteAccount, 'request'):
      return { ...state, isDeleting: true }
    case buildActionType(ActionsList.DeleteAccount, 'success'):
    case buildActionType(ActionsList.DeleteAccount, 'failure'):
      return { ...state, isDeleting: false }

    // flag for customer creation
    case buildActionType(ActionsList.CreateCustomer, 'failure'):
    case buildActionType(ActionsList.CreateCustomer, 'request'):
      return { ...state }
    case buildActionType(ActionsList.CreateCustomer, 'success'):
      return { ...state }

    // fetch account templates
    case buildActionType(ActionsList.FetchAccountTemplates, 'success'):
      return {
        ...state,
        items: addPropsToObjectInArray(state.items, action.payload, 'templates')
      }

    case ActionsList.AuthLogout:
      return initialState

    default:
      return state
  }
}
