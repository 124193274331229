import { actionCoreApi } from 'core/utils/api'
import ActionsList from 'core/actions/Actions.list'
import HTTPUrlHelper from '../../shared/helpers/HTTPUrlHelper'
import { ThunkDispatch } from 'redux-thunk'
import { IAction } from 'core/actions/Actions.interfaces'
import HTTPClient from 'core/services/HTTPClient'

export function getLicenseByApp (appName: string) {
  return async (dispatch: ThunkDispatch<{}, {}, IAction>): Promise<void> => {
    dispatch({ type: ActionsList.FetchLicenseByApp })
  
    const URL: string = HTTPUrlHelper.getAMLicenseByApp(appName)
    const { data, status } = await HTTPClient.GET(URL)
  }
}

export const getLicense = () =>
actionCoreApi(
  ActionsList.GetLicense,
  HTTPUrlHelper.getUpdateLicenseAPI()
)

export const getLicenseDetails = () =>
actionCoreApi(
  ActionsList.GetLicenseDetails,
  HTTPUrlHelper.getLicenseDetailsAPI()
)