import ActionsList from '../core/actions/Actions.list'
import { IAction } from '../core/actions/Actions.interfaces'
import { IUsers } from './Reducers.interfaces'
import { updateObjectInArray } from './Reducers.helpers'

const initialState: IUsers = {
  items: [],
  total: 0,
  isLoading: false,
  isEditing: false
}

export default function users (state: IUsers = initialState, action: IAction) {
  switch (action.type) {
    // fetch and sort users
    case ActionsList.FetchUsersListRequest:
    case ActionsList.SortUsersRequest:
      return { ...state, isLoading: true }
    case ActionsList.FetchUsersListSuccess:
    case ActionsList.SortUsersSuccess:
      return {
        ...state,
        isLoading: false,
        items: action.payload.items,
        total: action.payload.total
      }
    case ActionsList.FetchUsersListFailure:
    case ActionsList.SortUsersFailure:
      return { ...state, isLoading: false }

    // edit user
    case ActionsList.EditUserRequest:
      return { ...state, isEditing: true }
    case ActionsList.EditUserSuccess:
    case ActionsList.EditUserFailure:
      return { ...state, isEditing: false }

    // create new user
    case ActionsList.CreateUserRequest:
      return { ...state, isLoading: true }
    case ActionsList.CreateUserSuccess:
    case ActionsList.CreateUserFailure:
      return { ...state, isLoading: false }

    // fetch user by id
    case ActionsList.FetchUserRequest:
      return { ...state, isLoading: true }
    case ActionsList.FetchUserSuccess:
      return {
        ...state,
        isLoading: false,
        items: updateObjectInArray(state.items, action.payload)
      }
    case ActionsList.FetchUserFailure:
      return { ...state, isLoading: false }

    case ActionsList.UpdateUserAgreementRequest:
      return { ...state, isLoading: true }
    case ActionsList.UpdateUserAgreementSuccess:
      return { ...state, isLoading: false }
    case ActionsList.UpdateUserAgreementFailure:
      return { ...state, isLoading: false }
    case ActionsList.AuthLogout:
      return initialState

    default:
      return state
  }
}
