import { ActionType, dispatcherActionType } from 'core/utils/interfaces'
import { IFilters, ISort } from 'core/actions/Actions.interfaces'
import { actionErrorNotifier } from 'core/utils/helpers'
import { actionCoreApi } from 'core/utils/api'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import OrganizationsActions from './Organizations.constants'

export const fetchOrganizations = (params: ISort, filters?: IFilters): dispatcherActionType<any> =>
  actionErrorNotifier(actionCoreApi(
    OrganizationsActions.FetchOrganizations,
    HTTPUrlHelper.getFilteredAPI(HTTPUrlHelper.getOrganizationsAPI(), params, filters)
  ))

export const createOrganization = (formData: any): ActionType => actionErrorNotifier(
  actionCoreApi(
    OrganizationsActions.CreateOrganization,
    HTTPUrlHelper.getOrganizationsAPI(),
    'POST',
    formData
  ), true,
  {
    title: '',
    message: 'notifications.organizations.createOrganizationSuccess'
  }
)

export const fetchOrganizationById = (id: string): ActionType => actionErrorNotifier(actionCoreApi(
  OrganizationsActions.FetchOrganization,
  HTTPUrlHelper.getOrganizationByIdAPI(id)
))

export const editOrganization = (id: string, formData): ActionType =>
  actionErrorNotifier(
    actionCoreApi(
      OrganizationsActions.EditOrganization,
      HTTPUrlHelper.getOrganizationByIdAPI(id),
      'PUT',
      formData
    ), true,
    {
      title: '',
      message: 'notifications.organizations.editOrganizationSuccess'
    }
  )

export const deleteOrganization = (ids: string[]): ActionType =>
  actionErrorNotifier(
    actionCoreApi(
      OrganizationsActions.DeleteOrganization,
      ids.map((id: string): string => HTTPUrlHelper.getOrganizationByIdAPI(id)),
      'DELETE'
    ), true,
    {
      title: '',
      message: 'notifications.organizations.deleteOrganizationSuccess'
    }
  )
