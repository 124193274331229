import React, { ChangeEvent, memo, ReactElement, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { setupUser } from 'core/actions/Auth.actions'
import history from 'core/history/history'
import { BasePathConst, imauthLoginUrl } from 'configs/Path.configs'
import { ISetupUserFormValues, ISetupUserProps } from './SetupUser.interfaces'
import styles from './SetupUser.module.scss'
import {
  DCONE_REFRESH_TOKEN_COOKIE_NAME,
  EMAIL_REGEXP,
  MAX_PASSWORD_CHARS,
  MIN_PASSWORD_CHARS,
  PASSWORD_REGEX,
  USER_NAME_REGEXP
} from 'core/utils/constants'
import Cookies from 'js-cookie'
import {
  Form,
  FormFieldSize,
  FormGroup,
  Input,
  InputType,
  PublicContent,
  PublicFooter,
  PublicHeader,
  Spinner,
  SubmitButton,
  Tooltip
} from '@digicert/dcone-common-ui'
import { checkLicense } from 'core/actions/Common'
import { translate } from '../../shared/helpers/utils'

const SetupUser = (
  {
    checkLicense,
    setupUser,
  }: ISetupUserProps): ReactElement => {
  const [ loading, setLoading ] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value)
  }

  const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
  }

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const onUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value)
  }

  const onUserNameFocus = () => {
    if(userName === '') {
      setUserName(email)
    }
    else {
      setUserName(userName)
    }
  }

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const onValidatePassword = () => {
    if(password === '') {
      return translate('common.form.validation.required')
    }
    if(password.length < MIN_PASSWORD_CHARS) {
      return translate('common.form.validation.invalidPasswordLength')
    }

    if(password.length > MAX_PASSWORD_CHARS) {
      return translate('common.form.validation.invalidPasswordMaxLength')
    }

    const passwordRegExp = new RegExp(PASSWORD_REGEX);
    if(!passwordRegExp.test(password)) {
      return translate('common.form.validation.enterValidPassword')
    }

    return '';
  }

  const onConfirmPassWordChange = (event) => {
    setConfirmPassword(event.target.value)
  }

  const onValidateConfirmPassword = () => {
    if(confirmPassword === '') {
      return translate('common.form.validation.required')
    }
    if(confirmPassword.length < MIN_PASSWORD_CHARS) {
      return translate('common.form.validation.invalidPasswordLength')
    }
    if(confirmPassword !== password) {
      return translate('common.form.validation.passwordConfirmationMismatch')
    }
    return '';
  }

  const renderToolTipContent = () => {
    return (
      <dl className={styles.toolTipList} id='passcode-requirements'>
        <dt><Translate id='common.passcodeRequirements'/></dt>
        <dd><Translate id='common.minPasscodeCharacters'/></dd>
        <dd><Translate id='common.maxPasswordCharacters'/></dd>
        <dd><Translate id='common.atLeastOneEach'/>:</dd>
        <dd className={styles.toolTipNestedItem}><Translate id='common.lowerCaseLetter'/></dd>
        <dd className={styles.toolTipNestedItem}><Translate id='common.upperCaseLetter'/></dd>
        <dd className={styles.toolTipNestedItem}><Translate id='common.symbol'/></dd>
        <dd className={styles.toolTipNestedItem}><Translate id='common.number'/></dd>
      </dl>
    )
  }

  const submitUser = (e) => {
    e.preventDefault()

    const formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      user_name: userName,
      password: password,
      repeatedPassword: confirmPassword
    }

    setupUser(formData).then((res:ISetupUserFormValues) => {
      res && history.push(`${imauthLoginUrl}?returnPath=/account/accounts`)
    })
  }

  useEffect(() => {
    if (Cookies.get(DCONE_REFRESH_TOKEN_COOKIE_NAME)) {
      history.push(`${BasePathConst}/accounts`)
    }
  }, [])

  useEffect(() => {
    checkLicense()
    .then((response: any) => {
      if (response) {
        history.push(response.redirect_url)
      }
    })
    .finally(() => {
      setLoading(false)
    })
  }, [checkLicense])

  if (!loading) {
    return (
      <div className={styles.setupUser}>
        <PublicHeader disableLanguageSelection/>

        <PublicContent>
          <div className={styles.content}>
            <div className={styles.contentHeader}>
              <h1><Translate id='setupUser.headline'/></h1>
              <p><Translate id='setupUser.description'/></p>
            </div>

            <Form center onSubmit={(e) => {submitUser(e)}} size={FormFieldSize.L}>
              <div className={styles.nameGroup}>
                <Input
                  id='first-name'
                  label={<Translate id='common.form.firstName'/>}
                  required
                  value={firstName}
                  errors={{valueMissing: translate('common.form.validation.required')}}
                  onChange={onFirstNameChange}
                />
                <Input
                  id='last-name'
                  label={<Translate id='common.form.lastName'/>}
                  required
                  value={lastName}
                  errors={{valueMissing: translate('common.form.validation.required')}}
                  onChange={onLastNameChange}
                />
              </div>

              <FormGroup>
                <Input
                  id='email'
                  size={FormFieldSize.L}
                  label={<Translate id='common.form.email'/>}
                  required
                  value={email}
                  onChange={onEmailChange}
                  pattern={EMAIL_REGEXP}
                  errors={{
                    typeMismatch: translate('notifications.errors.invalidEmailError'),
                    valueMissing: translate('common.form.validation.required'),
                    patternMismatch: translate('notifications.errors.invalidEmailError')
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Input
                  id='user-name'
                  size={FormFieldSize.L}
                  label={<Translate id='common.form.username'/>}
                  required
                  value={userName}
                  pattern={USER_NAME_REGEXP}
                  errors={{
                    valueMissing: translate('common.form.validation.required'),
                    patternMismatch: translate('common.form.validation.invalidUserName')
                  }}
                  onChange={onUserNameChange}
                  onFocus={onUserNameFocus}
                />
              </FormGroup>

              <FormGroup colWidth={FormFieldSize.L}>
                <Tooltip
                  title={renderToolTipContent()}
                  placement='right'
                  trigger={['click', 'focus']}
                  getPopupContainer={(triggerNode) => triggerNode}
                  theme='light'
                >
                  <Input
                    aria-labelledby='passcode-requirements'
                    id='password'
                    type={InputType.PASSWORD}
                    size={FormFieldSize.L}
                    label={<Translate id='common.form.password'/>}
                    required
                    value={password}
                    pattern={PASSWORD_REGEX}
                    errors={{
                      valueMissing: translate('common.form.validation.required'),
                      patternMismatch: translate('common.form.validation.enterValidPassword')
                    }}
                    onChange={onPasswordChange}
                    validate={onValidatePassword}
                    visibilityToggle
                  />
                </Tooltip>
              </FormGroup>

              <FormGroup colWidth={FormFieldSize.L}>
                <Tooltip
                  title={renderToolTipContent()}
                  placement='right'
                  trigger={['click', 'focus']}
                  getPopupContainer={(triggerNode) => triggerNode}
                  theme='light'
                >
                  <Input
                    aria-labelledby='passcode-requirements'
                    id='confirm-password'
                    type={InputType.PASSWORD}
                    size={FormFieldSize.L}
                    label={<Translate id='verification.confirmPassword'/>}
                    required
                    value={confirmPassword}
                    pattern={PASSWORD_REGEX}
                    errors={{
                      valueMissing: translate('common.form.validation.required'),
                      patternMismatch: translate('common.form.validation.enterValidPassword')
                    }}
                    onChange={onConfirmPassWordChange}
                    validate={onValidateConfirmPassword}
                    visibilityToggle
                  />
                </Tooltip>
              </FormGroup>

              <SubmitButton>
                <Translate id='common.form.buttons.login'/>
              </SubmitButton>
            </Form>
          </div>
        </PublicContent>

        <PublicFooter/>
      </div>
    )
  }  else {
    return (
      <div className='initial-spin-wrap'>
        <Spinner />
      </div>
    )
  }
}

SetupUser.displayName = 'SetupUser'

export default connect(
  null,
  {
    setupUser,
    checkLicense,
  }
)(memo(SetupUser))
