import ActionsList from 'core/actions/Actions.list'
import { IAction } from 'core/actions/Actions.interfaces'
import { IFeatures } from './Reducers.interfaces'
import { buildActionType } from 'core/utils/api'

const initialState: IFeatures = {
  roles: false
}

export default function features (state: IFeatures = initialState, action: IAction) {
  switch (action.type) {
    case buildActionType(ActionsList.GetFeatures, 'request'):
      return { ...state, isLoading: true }
    case buildActionType(ActionsList.GetFeatures, 'success'):
      return {
        ...state,
        ...action.payload,
        isLoading: false
      }
    case buildActionType(ActionsList.GetFeatures, 'failure'):
      return { ...state, isLoading: false }

    default:
      return state
  }
}
