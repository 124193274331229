import ActionsList from 'core/actions/Actions.list'
import { IAction } from 'core/actions/Actions.interfaces'
import { ILocales } from './Reducers.interfaces'
import { buildActionType } from 'core/utils/api'

const initialState: ILocales = {
  locales: []
}

export default function locales (state: ILocales = initialState, action: IAction) {
  switch (action.type) {
    case buildActionType(ActionsList.FetchLocales, 'request'):
        return {
          ...state
        }
      case buildActionType(ActionsList.FetchLocales, 'success'):
        return {
          ...state,
          locales: action.payload.map(function(obj) { 
            obj['id'] = obj['code'];
            delete obj['code']; 
            obj['name'] = obj['language']
            delete obj['language']
            return obj; 
        }) 
        }
    default:
      return state
  }
}
