import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchEnrollmentById, generateCertificateForEnrollment, redeemEnrollmentById } from './PublicEnrollment.actions'
import { PublicEnrollmentProps } from './PublicEnrollment.types'
import styles from './PublicEnrollment.module.scss'
import { CertificateObtaining } from 'public-pages/certificate/CertificateObtaining'
import { PageLevelBanner, PublicContent, PublicFooter, PublicHeader, Spinner } from '@digicert/dcone-common-ui'
import { generateRandomId } from 'shared/helpers/utils'
import { DTAHWTokenSelector } from 'shared/components/dta/dta-hw-token-selector/DTAHWTokenSelector'
import { get } from 'lodash-es'
import EnrollmentForm from '../EnrollmentForm'
import { DTAInitializerComponent } from 'shared/components/dta/dta-initializer/DTAInitializer'
import { generateCSR, getDTATokenId } from 'shared/helpers/dta.helpers'
import { DdcHWTokenSelector, HardwareTokenOption } from 'shared/components/ddc-hw-token-selector/DdcHWTokenSelector'
import { KeyStoreID } from 'core/services/ddc/ddc.constants'
import { Token } from 'core/services/dta/DTAClient'
import { DDC } from 'core/services/ddc/ddc'
import { Translate } from 'react-localize-redux'
import { PKIClientInstance } from 'core/services/dta/DTAClientInstane'
import { getErrorMessage } from 'shared/helpers/ErrorMessage.helpers'
import { DTAClientErrors } from 'core/services/dta/DTAClientErrors'
import { DdcInitializerComponent } from 'shared/components/ddc-initializer/DdcInitializer'

const PublicEnrollmentComponent = (props: PublicEnrollmentProps): ReactElement | null => {
  const { fetchEnrollmentById, redeemEnrollmentById, generateCertificateForEnrollment } = props
  const { enrollmentId, enrollmentCode } = props.match.params
  const [dtaInitialized, setDtaInitialized] = useState(false)
  const [ddcInitialized, setDdcInitialized] = useState(false)
  const [x509, setX509] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [predefinedKeyStoreId, setPredefinedKeyStoreId] = useState<KeyStoreID>()
  const [predefinedKeyStoreProfileName, setPredefinedKeyStoreProfileName] = useState('')
  const [ddcHwTokenSection, setDdcHwTokenSection] = useState(false)
  const [dtaHwTokenSection, setDtaHwTokenSection] = useState(false)

  const [selectedHardwareOption, setSelectedHardwareOption] = useState<HardwareTokenOption>()
  const [isDDCHardwareTokensError, setDDCHardwareTokensError] = useState(false)
  const [keystoreLocation, setKeystoreLocation] = useState('')
  const [fullName, setFullName] = useState('')
  const [selectedDTAToken, setSelectedDTAToken] = useState<Token | null>()
  const [certInstalled, setCertInstalled] = useState(false)
  const [, setHWTokensEmpty] = useState(false)
  const [ddcInstalled, setDdcInstalled] = useState(false)
  const [dtaInstalled, setDtaInstalled] = useState(false)
  const [ddcWithDcksSection, setDdcWithDcksSection] = useState(false)



  useEffect(() => {
    fetchEnrollmentById(enrollmentId, enrollmentCode).then((response) => {
      if (response) {
        setKeystoreLocation(response.keystore_location)
        setFullName(response.first_name + ' ' + response.last_name)
      }
    }
    )
    Promise.resolve()
      .then(() => PKIClientInstance.get().then(res => console.log(res)))
      .then(instance => {
        setIsLoading(false)
        setDtaInstalled(true)
        Promise.resolve(instance)
      })
      .catch(error => {
        const parsedError = getErrorMessage(error)
        //DTA not installed
        if (parsedError === DTAClientErrors.NOT_INSTALLED) {
          setDtaInitialized(false)
          setDtaInstalled(false)
          Promise.resolve()
            .then(() => DDC.initialize())
            //DDC installed
            .then(ddcInstance => {
              if (ddcInstance) {
                Promise.resolve(ddcInstance)
                setDdcInitialized(false)
                setIsLoading(false)
                setDdcInstalled(true)
              }
            })
            .catch(error => {
              if(error) {
                setDtaInstalled(true)
                setDdcInstalled(false)
                setDdcInitialized(false)
                setIsLoading(false)
              }
            })
        }
      })
  }, [])

  const handleSubmit = () => {
    setIsLoading(true)
    let keyStoreId
    if (get(selectedDTAToken, 'keyStoreId')) {
        keyStoreId = get(selectedDTAToken, 'keyStoreId')
        generateCsr(keyStoreId)
    }
    else {
        getDTATokenId(keystoreLocation).then((dtaTokenRes: any) => {
        generateCsr(dtaTokenRes)
    })
    }


  }

  const generateCsr = (keyStoreId : String) => {
          generateCSR(keyStoreId).then((response: any) => {
            const csr = "-----BEGIN CERTIFICATE REQUEST-----\n" + response.replace(/.{64}/g, '$&\n') + "\n-----END CERTIFICATE REQUEST-----"
            const date = new Date();
            date.setFullYear(date.getFullYear() + 1);
            const payload = {
              name: fullName + '-' + generateRandomId(),
              end_date: date,
              csr: csr,
              common_name: fullName,
              enrollment_method: 'DTA',
              keystore_type: keystoreLocation.toUpperCase()
            }

            generateCertificateForEnrollment(payload, enrollmentId).then((response: any) => {
              setCertInstalled(true)
              setDtaHwTokenSection(false)
              setPredefinedKeyStoreId(keyStoreId)
              setPredefinedKeyStoreProfileName((get(selectedHardwareOption, 'profileName') || ''))
              if (response) {
                setX509(response.x509_cert)
              }
              redeemEnrollmentById(enrollmentId)
            })
          })
  }

  const handleDDCSubmit = () => {
    setIsLoading(true)
    const predefinedKeyStoreId = get(selectedHardwareOption, 'keyStoreId') || DDC.getCurrentBrowserKeyStoreId()
    const predefinedKeyStoreProfileName = get(selectedHardwareOption, 'profileName') || ''
    DDC.generateCSRBasedOnProfile(predefinedKeyStoreId, keystoreLocation === 'hardware_token', predefinedKeyStoreProfileName).then((csrRes: any) => {
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      const payload = {
        name: fullName + '-' + generateRandomId(),
        end_date: date,
        csr: csrRes.csr,
        common_name: fullName,
        enrollment_method: 'DDC',
        keystore_type: keystoreLocation.toUpperCase()
      }

      generateCertificateForEnrollment(payload, enrollmentId).then((response: any) => {
        response['predefinedKeyStoreId'] = predefinedKeyStoreId
        response['predefinedKeyStoreProfileName'] = predefinedKeyStoreProfileName
        setX509(response.x509_cert)
        setPredefinedKeyStoreId(predefinedKeyStoreId)
        setPredefinedKeyStoreProfileName(predefinedKeyStoreProfileName)
        setCertInstalled(true)
        setDdcHwTokenSection(false)
        setDdcInstalled(false)
        redeemEnrollmentById(enrollmentId)
      })
    })
  }

  const getMainContentByFlow = (dta: boolean, ddc: boolean) => {
    if (dta || ddc) {
      if (!certInstalled) {
        if (ddc) {
          if (keystoreLocation !== 'hardware_token') {
            handleDDCSubmit()
          }
          else {
            setDdcHwTokenSection(true)
          }
        } else {
          if (keystoreLocation != 'hardware_token') {
            handleSubmit()
          }
          else {
            setDtaHwTokenSection(true)
          }
        }
      }
    }
  }

  const installDDC = useCallback( () => {
    if(ddcInstalled) {
      setDdcInstalled(false)
        if (keystoreLocation === 'DIGICERT_SOFTWARE') {
          setDdcWithDcksSection(true)
        } else {
          return (
            <>
              {keystoreLocation && (
                getMainContentByFlow(false, true)
              )}
            </>
          )
        }
    } 
  },[keystoreLocation, ddcInstalled])


  useEffect(() => {
    if (selectedDTAToken) {
      setTimeout(()=>{
        handleSubmit()
      },500)
    }
  },[selectedDTAToken])



  useEffect(() => {
    if (dtaInitialized && keystoreLocation && fullName) {
      setDdcInstalled(false)
      setDtaInstalled(false)
      setDdcInitialized(false)
      setDtaInstalled(false)
      setDdcWithDcksSection(false)
      getMainContentByFlow(true, false)
    }
    if(x509) {
      setIsLoading(false)
    }
  }, [dtaInitialized, keystoreLocation, fullName, ddcInitialized, x509])

  if (isLoading) {
    return (<Spinner />)
  }

  return (<section className={styles.publicEnrollment}>
    <PublicHeader disableLanguageSelection />
    <PublicContent>
      <div className='form-wrap'>
        {(ddcHwTokenSection) &&
          (
            <EnrollmentForm
              onSubmit={handleDDCSubmit}
              initialValues={['']}
              excludedFields={['']}
              isDDCHardwareTokensError={isDDCHardwareTokensError}
            >
              <DdcHWTokenSelector
                onChange={setSelectedHardwareOption}
                onEmptyTokens={() => setHWTokensEmpty(true)}
                handleSubmit={() => handleDDCSubmit}
              />
            </EnrollmentForm>
          )
        }
        {(dtaHwTokenSection) &&
          (
            <EnrollmentForm
              onSubmit={handleSubmit}
              initialValues={['']}
              excludedFields={['']}
            >
              <DTAHWTokenSelector
                key='hw-token-selector'
                onChange={(token) => {
                  setSelectedDTAToken(token)
                }}
              />

            </EnrollmentForm>
          )
        }
        {(x509) &&
          (
            <CertificateObtaining x509_cert={x509} predefinedKeyStoreId={predefinedKeyStoreId} predefinedKeyStoreProfileName={predefinedKeyStoreProfileName} ddc={ddcInitialized} />
          )
        }
        {(ddcWithDcksSection) && 
          <>
          <div className='form-wrap'>
            <PageLevelBanner
              bannerType='info'
              title={<Translate id={'dta.genericBannerTitle'} />}
              description={<Translate id={'dta.info.dtaRequired'} />}
            />
          </div>
          <br /><br />
          <div className='form-wrap'>
            <DTAInitializerComponent onInitialize={setDtaInitialized} needToInitialize={true} keyStoreLocation={keystoreLocation} unInitializeDdc={setDdcInitialized}/>
          </div>
        </>
        }
        {((ddcInstalled) && !ddcInitialized && keystoreLocation !== 'DIGICERT_SOFTWARE') ?  
          <DdcInitializerComponent onInitialize={setDdcInitialized} setIsLoading={setIsLoading}/>
          :  installDDC()
        }
        {(!dtaInitialized && dtaInstalled) &&
          //initialize DTA
          (
            <DTAInitializerComponent onInitialize={setDtaInitialized} needToInitialize={true} keyStoreLocation={keystoreLocation} />
          )
        }
      </div>
    </PublicContent>
    <PublicFooter />
  </section>)
}

export default connect(
  null,
  { fetchEnrollmentById, generateCertificateForEnrollment, redeemEnrollmentById }
)(PublicEnrollmentComponent)