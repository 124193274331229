import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import History from 'core/history/history'
import { setupLicense } from 'core/actions/Auth.actions'
import { BasePathConst } from 'configs/Path.configs'
import { ISetupLicenseProps } from '../License.interfaces'
import { checkLicense } from 'core/actions/Common'
import {
  Form,
  FormFieldSize,
  FormGroup,
  PublicContent,
  PublicFooter,
  PublicHeader,
  Spinner,
  SubmitButton,
  TextArea
} from '@digicert/dcone-common-ui'
import { translate } from '../../../shared/helpers/utils'
import styles from './SetupLicense.module.scss'
import Cookies from 'js-cookie'
import { DCONE_REFRESH_TOKEN_COOKIE_NAME } from '../../../core/utils/constants'

export const SetupLicense = (props: ISetupLicenseProps) => {
  const { checkLicense } = props
  const [ loading, setLoading ] = useState(true)
  const [license, setLicense] = useState('')

  useEffect(() => {
    if (Cookies.get(DCONE_REFRESH_TOKEN_COOKIE_NAME)) {
      History.push(`${BasePathConst}/accounts`)
    }
  }, [])

  const onLicenseChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setLicense(event.target.value)
  }

  const submitLicense = (e) => {
    e.preventDefault()
    const formData = {
      body: license
    }
    props.setupLicense(formData).then((res: any) => {
      res && History.push(`${BasePathConst}/setup-user`)
    })
  }

  useEffect(() => {
    checkLicense()
    .then((response: any) => {
      History.push(response.redirect_url)
    })
    .finally(() => {
      setLoading(false)
    })
  }, [checkLicense])

  if (!loading) return (
    <div className={styles.setupLicense}>
      <PublicHeader disableLanguageSelection/>

      <PublicContent>
        <div className={styles.content}>
          <div className={styles.contentHeader}>
            <h1><Translate id='setupLicense.headLine'/></h1>
            <p><Translate id='setupLicense.getStarted'/></p>
          </div>

          <Form center onSubmit={(e) => {submitLicense(e)}} size={FormFieldSize.L}>
            <FormGroup>
              <TextArea
                required
                label={translate('setupLicense.accountLicense')}
                placeholder={translate('setupLicense.enterLicense') as string}
                errors={{
                  valueMissing: translate('common.form.validation.required'),
                }}
                value={license}
                rows={5}
                onChange={onLicenseChange}
              />
            </FormGroup>

            <SubmitButton>
              <Translate id='common.form.buttons.nextBtn'/>
            </SubmitButton>
          </Form>
        </div>
      </PublicContent>

      <PublicFooter/>
    </div>
  )
  else return (
    <Spinner />
  )
}

SetupLicense.displayName = 'SetupLicense'

export default connect(
  null,
  { setupLicense, checkLicense }
)(memo(SetupLicense))
