import React, { ReactElement, useState } from 'react'
import { translate } from '../../../helpers/Translation.helpers'
import { marginTop } from '../../../helpers/styleUtils'
import { Button, Input, PageLevelBanner, PageLevelBannerType, StandardModal } from '@digicert/dcone-common-ui'
import { Space } from '../../../layouts/space/Space'
import { getDCKeyStoreToken, initDCKSToken } from '../../../helpers/dta.helpers'
import { getErrorMessage } from '../../../helpers/ErrorMessage.helpers'
import { Spin } from 'antd'

const pinMinLength = 4

export const DCKSInitialize = (): ReactElement => {
  const [isModalVisible, showModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [adminPIN, setAdminPIN] = useState('')
  const [userPIN, setUserPIN] = useState('')
  const [initError, setInitError] = useState('')

  const openModal = () => {
    showModal(true)
    setInitError('')
  }

  const initialize = async () => {
    setIsLoading(true)
    setInitError('')
    const token = await getDCKeyStoreToken()

    if (!token) return

    try {
      await initDCKSToken(token.id!, adminPIN, userPIN, 'DigiCert Software KeyStore')
      showModal(false)
    } catch (e) {
      setInitError(getErrorMessage(e))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <PageLevelBanner
        bannerType={PageLevelBannerType.INFO}
        title={<b>{translate('dta.error.DCKSUninitialized.title')}</b>}
        description={
          <div>
            <span className='pre-line'>
              <br />
              {translate('dta.error.DCKSUninitialized.description')}
            </span>
            <div style={marginTop(8)}>
              {
                <Button buttonType='primary' onClick={openModal}>
                  {translate('common.initialize')}
                </Button>
              }
            </div>
          </div>
        }
      />

      <StandardModal
        closable
        display={isModalVisible}
        headline={translate(`dta.dcksInitialize.modalTitle`)}
        buttonLabel={translate('common.initialize')}
        buttonCancelLabel={translate('common.form.buttons.cancelBtn')}
        onOkCallBack={() => initialize()}
        disableButton={!(adminPIN && userPIN && adminPIN.length >= pinMinLength && userPIN.length >= pinMinLength) || isLoading}
        onCancelCallBack={() => showModal(false)}
      >
        <Spin spinning={isLoading}>
          <Space direction='v' size='m'>
            {initError && (
              <PageLevelBanner
                bannerType={PageLevelBannerType.ERROR}
                title={translate('dta.dcksInitialize.initErrorTitle')}
                description={initError}
              />
            )}
            <Input
              label={translate('dta.dcksInitialize.adminPIN')}
              type='password'
              required
              minLength={4}
              onChange={event => setAdminPIN(event.target.value)}
              value={adminPIN}
              errors={{
                valueMissing: translate('common.form.validation.required'),
                tooShort: translate('common.form.validation.minimalLength', { min: pinMinLength.toString() })
              }}
            />
            <Input
              label={translate('dta.dcksInitialize.userPIN')}
              type='password'
              required
              minLength={4}
              onChange={event => setUserPIN(event.target.value)}
              value={userPIN}
              errors={{
                valueMissing: translate('common.form.validation.required'),
                tooShort: translate('common.form.validation.minimalLength', { min: pinMinLength.toString() })
              }}
            />
          </Space>
        </Spin>
      </StandardModal>
    </div>
  )
}
