import { RouterConfig } from 'core/utils/constants'
import { IParams } from './Breadcrumbs.interfaces'

export type BreadcrumbName = string | { label: string, disabled?: boolean }

export function breadcrumbNameMap (params: IParams): Record<string, BreadcrumbName> {
  const { id, organizationId, contactId, configId, tokenId } = params
  return {
    [RouterConfig.urlMap.enrollments]: 'breadcrumb.table.enrollment',
    [RouterConfig.urlMap.adminProfile]: 'breadcrumb.details.adminProfile',
    [RouterConfig.urlMap.editAdminProfile]: 'breadcrumb.edit.adminProfile',
    [RouterConfig.urlMap.access]: 'breadcrumb.access',
    [RouterConfig.administrators.table]: 'breadcrumb.table.admin',
    [RouterConfig.administrators.create]: 'breadcrumb.create.admin',
    [RouterConfig.administrators.rolesEdit(id)]: 'breadcrumb.edit.admin',
    [RouterConfig.administrators.editRoles(id)]: 'breadcrumb.edit.updateRoles',
    [RouterConfig.administrators.createUserWithRole]: 'breadcrumb.create.userWithRole',
    [RouterConfig.administrators.createServiceUserWithRole]:'breadcrumb.create.createServiceUser',
    [RouterConfig.administrators.editServiceUserWithRole(id)]: 'breadcrumb.edit.admin',
    [RouterConfig.administrators.bulkCreate]: 'breadcrumb.create.bulkAdmin',
    [RouterConfig.administrators.bulkCreateWithRole]: 'breadcrumb.create.bulkAdminRole',
    [RouterConfig.administrators.details(id)]: 'breadcrumb.details.admin',
    [RouterConfig.administrators.edit(id)]: 'breadcrumb.edit.admin',
    [RouterConfig.apiTokens.table]: 'breadcrumb.table.apiToken',
    [RouterConfig.administrators.apiToken(id)]: 'breadcrumb.create.apiToken',
    [RouterConfig.administrators.apiTokenEdit(id,tokenId)]:'breadcrumb.edit.apiToken',
    [RouterConfig.administrators.uploadCert(id)]: 'breadcrumb.create.uploadCert',
    [RouterConfig.clientAuth.table]: 'breadcrumb.table.clientAuth',
    [RouterConfig.administrators.clientAuth(id)]: 'breadcrumb.create.clientAuth',
    [RouterConfig.accounts.table]: 'breadcrumb.table.account',
    [RouterConfig.accounts.createAccount]: 'breadcrumb.create.account',
    [RouterConfig.accounts.edit(id)]: 'breadcrumb.edit.account',
    [RouterConfig.accounts.details(id)]: 'breadcrumb.details.account',
    [RouterConfig.accounts.createAccountSignIn(id)]: 'breadcrumb.details.config',
    [RouterConfig.accounts.editStandardSignIn(id)]: 'breadcrumb.details.standard',
    [RouterConfig.accounts.editMfa(id)]: "breadcrumb.details.editMfa",
    [RouterConfig.accounts.createAccountSigningClient(id)]: 'breadcrumb.details.createSigningClient',
    [RouterConfig.accounts.editAccountSigningClient(id, configId)]: 'breadcrumb.details.editSigningClient',
    [RouterConfig.profiles.table]: 'breadcrumb.table.profile',
    [RouterConfig.profiles.create]: 'breadcrumb.create.profile',
    [RouterConfig.profiles.edit(id)]: 'Edit profile',
    [RouterConfig.profiles.details(id)]: { label: 'Profile details', disabled: true },
    [RouterConfig.urlMap.certificates]: 'breadcrumb.table.certificate',
    [RouterConfig.ca.dashboard]: 'breadcrumb.ca',
    [RouterConfig.ca.tableRoots]: 'breadcrumb.table.rootCA',
    [RouterConfig.ca.tableIca]: 'breadcrumb.table.issuingCA',
    [RouterConfig.ca.detailsRoots(id)]: 'breadcrumb.details.rootCA',
    [RouterConfig.ca.detailsIca(id)]: 'breadcrumb.details.issuingCA',
    [RouterConfig.businessUnits.table]: 'breadcrumb.table.businessUnit',
    [RouterConfig.businessUnits.create]: 'breadcrumb.create.businessUnit',
    [RouterConfig.businessUnits.edit(id)]: 'breadcrumb.edit.businessUnit',
    [RouterConfig.businessUnits.details(id)]: 'breadcrumb.details.businessUnit',
    [RouterConfig.organizations.table]: 'breadcrumb.table.organization',
    [RouterConfig.organizations.create]: 'breadcrumb.create.organization',
    [RouterConfig.organizations.details(id || organizationId)]: 'breadcrumb.details.organization',
    [RouterConfig.organizations.edit(id)]: 'breadcrumb.edit.organization',
    [RouterConfig.organizations.createContact(id)]: 'breadcrumb.create.contact',
    [RouterConfig.organizations.editContact(organizationId, contactId)]: 'breadcrumb.edit.contact',
    [RouterConfig.urlMap.reporting]: 'breadcrumb.reporting',
    [RouterConfig.logging.table]: 'breadcrumb.table.logging',
    [RouterConfig.logging.details(id)]: 'breadcrumb.details.auditEvent',
    [RouterConfig.urlMap.integrationGuides]: 'breadcrumb.integrationGuides',
    [RouterConfig.urlMap.clientTools]: 'breadcrumb.clientTools',
    [RouterConfig.urlMap.serviceUser]: 'breadcrumb.serviceUser',
    [RouterConfig.serviceUser.details(id)]: 'breadcrumb.details.serviceUser',
    [RouterConfig.serviceUser.create]: 'breadcrumb.create.createServiceUser',
    [RouterConfig.serviceUser.clientAuth(id)]: 'breadcrumb.create.clientAuth',
    [RouterConfig.serviceUser.edit(id)]: 'breadcrumb.edit.updateServiceUser',
    [RouterConfig.manageUserRoles.roles]: 'breadcrumb.table.roles',
    [RouterConfig.manageUserRoles.create()]: 'breadcrumb.create.role',
    [RouterConfig.manageUserRoles.edit(id)]: 'breadcrumb.edit.role',
    [RouterConfig.manageUserRoles.clone(id)]: 'breadcrumb.edit.cloneRole',
    [RouterConfig.manageUserRoles.detail(id)]: 'breadcrumb.details.roleDetail',
    [RouterConfig.accounts.updateClientAuthCertLogin(id)]: 'breadcrumb.edit.ClientAuthCertLoginUpdate',
    [RouterConfig.accounts.openIdIntegration(id)]: 'breadcrumb.openIdConnect',
    [RouterConfig.platformSettings.settings]: 'breadcrumb.settings',
    [RouterConfig.platformSettings.addOauth()]: 'breadcrumb.settingsOAuthIntegration',
    [RouterConfig.platformSettings.editOauth(id)]: 'breadcrumb.settingsEditOAuthIntegration',
    [RouterConfig.platformSettings.updateLicense]: 'breadcrumb.settingsUpdateLicense',
    [RouterConfig.platformSettings.updateStandardSignIn(id)]: 'breadcrumb.settingsUpdateStandardSignIn',
    [RouterConfig.platformSettings.addAccountSignIn(id)]: 'breadcrumb.settingsAddAccountSignIn',
    [RouterConfig.platformSettings.updateMfa(id)]: 'breadcrumb.settingsUpdateMfa',
    [RouterConfig.platformSettings.settingsOpenIdIntegration(id)]: 'breadcrumb.settingsOpenIdIntegration',
  }
}
