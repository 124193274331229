// -----------------------------------------------------------------
// PKIUtils: Utilities for PKI
// -----------------------------------------------------------------
export default class PKIUtils {
  // ---------------------------------------------------------------
  // fmt2pem(): Insert CR/NL every 64 characters
  // ---------------------------------------------------------------
  static fmt2pem (pemString) {
    const stringLength = pemString.length
    let resultString = ''
    for (let i = 0, count = 0; i < stringLength; i++, count++) {
      if (count > 63) {
        resultString = `${resultString}\r\n`
        count = 0
      }
      resultString = `${resultString}${pemString[i]}`
    }
    return resultString
  }
  // ---------------------------------------------------------------
  // ary2b64(): Uint8Array to Base64
  // ---------------------------------------------------------------
  static ary2b64 (bytes) {
    var len = bytes.byteLength
    var binary = ''
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[ i ])
    }
    // eslint-disable-next-line no-undef
    return btoa(binary)
  }
  // -----------------------------------------------------------------
  // b642ary(): Convert Base64 to Uint8Array
  // -----------------------------------------------------------------
  static b642ary (b64) {
    // eslint-disable-next-line no-undef
    var str = atob(b64)
    var stringLength = str.length
    var resultBuffer = new ArrayBuffer(stringLength)
    var resultView = new Uint8Array(resultBuffer)
    for (var i = 0; i < stringLength; i++) {
      resultView[i] = str.charCodeAt(i)
    }
    return resultBuffer
  }
  // ---------------------------------------------------------------
  // ary2str(): Uint8Array to String
  // ---------------------------------------------------------------
  static ary2str (bytes) {
    var binary = ''
    // console.log("PKIUtils.ary2str():bytes=" + bytes);
    if (bytes) {
      if (bytes instanceof Uint8Array) {
        // console.log("PKIUtils.ary2str():bytes = Uint8Array");
        for (let i = 0; i < bytes.byteLength; i++) {
          binary += String.fromCharCode(bytes[ i ])
        }
      } else if (bytes instanceof ArrayBuffer) {
        // console.log("PKIUtils.ary2str():bytes = ArrayBuffer");
        let arybuf = new Uint8Array(bytes)
        for (let i = 0; i < arybuf.byteLength; i++) {
          binary += String.fromCharCode(arybuf[ i ])
        }
      }
    }
    return binary
  }
  // -----------------------------------------------------------------
  // str2ary(): Convert String to Uint8Array
  // -----------------------------------------------------------------
  static async str2ary (str) {
    var stringLength = str.length
    var resultBuffer = new ArrayBuffer(stringLength)
    var resultView = new Uint8Array(resultBuffer)
    for (var i = 0; i < stringLength; i++) {
      resultView[i] = str.charCodeAt(i)
    }
    return resultBuffer
  }
  // ---------------------------------------------------------------
  // ary2hex(): Convert Uint8Array to String
  // ---------------------------------------------------------------
  static ary2hex (byteArray) {
    if (byteArray instanceof ArrayBuffer) {
      return Array.prototype.map.call(
        new Uint8Array(byteArray), x =>
          ('00' + x.toString(16)).slice(-2)).join('')
    } else {
      var arybuf = new Uint8Array(byteArray)
      return Array.from(arybuf, function (byte) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2)
      }).join('')
    }
  }
  // ---------------------------------------------------------------
  // hex2ary(): Convert HexString to Uint8Array
  // ---------------------------------------------------------------
  static hex2ary (hexString) {
    // remove the leading 0x
    hexString = hexString.replace(/^0x/, '')
    // ensure even number of characters
    if (hexString.length % 2 !== 0) {
      // console.log('WARNING: expecting an even number of characters in the hexString')
    }
    // check for some non-hex characters
    var bad = hexString.match(/[G-Z\s]/i)
    if (bad) {
      // console.log('WARNING: found non-hex characters', bad)
    }
    // split the string into pairs of octets
    var pairs = hexString.match(/[\dA-F]{2}/gi)

    // convert the octets to integers
    var integers = pairs.map(function (s) {
      return parseInt(s, 16)
    })
    return new Uint8Array(integers)
  }
  static removeHeader (str) {
    let result = null
    if (str) {
      let pem = str
      pem = pem.replace('-----BEGIN CERTIFICATE REQUEST-----', '')
      pem = pem.replace('-----END CERTIFICATE REQUEST-----', '')
      pem = pem.replace('-----BEGIN PRIVATE KEY-----', '')
      pem = pem.replace('-----END PRIVATE KEY-----', '')
      pem = pem.replace('-----BEGIN CERTIFICATE-----', '')
      pem = pem.replace('-----END CERTIFICATE-----', '')
      pem = pem.replace(/^\n/gm, '')
      pem = pem.replace(/^\r/gm, '')
      result = pem
    }
    return result
  }
}
