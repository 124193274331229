import { ISigningProviders } from 'reducers/Reducers.interfaces'
import AccountSignigClientConstants from './AccountSigningClient.constants'
import { IAction } from './AccountSigningClient.types'
import ActionsList from 'core/actions/Actions.list'

const initialState: ISigningProviders = {
  items: []
}

export default function accountSigningClient (state: ISigningProviders = initialState, action: IAction): ISigningProviders {
  switch (action.type) {
    // fetch all signing providers
    case ActionsList.FetchOauthClients:
      return { ...state }
    case AccountSignigClientConstants.FetchAccountSignigClientSuccess:
        return {
          ...state,
          items: action.payload[0]
        }
    case AccountSignigClientConstants.FetchAccountSignigClientFailure:
      return { ...state}  

    default:
      return state
  }
}
