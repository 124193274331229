import {IlicenseCard} from '../Tenant.types'

export enum EditButtonRoleTypes {
  BUTTON = 'button',
  LINK= 'link'
}

export interface LicenseCardProps {
  data:Array<IlicenseCard>
  title?: string
  onEditClick: (id:string, data: any)=>void
  id: string
  canEdit: boolean
  // in some cases a button will navigate a user to a page
  // screen readers should read the appropriate role
  editButtonRole?: EditButtonRoleTypes
}