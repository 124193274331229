import { createSelector } from 'reselect'
import { IAppState } from '../store/store'

const getPermissionsList = ({ profile: { data } }: IAppState) => data ? data.permissions : null

export enum ApplicationPermissionMap {
  'canViewCMLicense' = 'VIEW_CM_LICENSE',
  'canManageCMLicense' = 'MANAGE_CM_LICENSE',
  'canViewIOTLicense' = 'VIEW_IOT_LICENSE',
  'canManageIOTLicense' = 'MANAGE_IOT_LICENSE',
  'canViewEMLicense' = 'VIEW_EM_LICENSE',
  'canManageEMLicense' = 'MANAGE_EM_LICENSE',
  'canViewSMLicense' = 'VIEW_SM_LICENSE',
  'canManageSMLicense' = 'MANAGE_SM_LICENSE',
  'canViewDSMLicense' = 'VIEW_DSM_LICENSE',
  'canManageDSMLicense' = 'MANAGE_DSM_LICENSE',
  'canManageAccount' = 'MANAGE_AM_ACCOUNT',
  'canViewAMLicense' = 'VIEW_AM_LICENSE',
  'canManageAMLicense' = 'MANAGE_AM_LICENSE',
  'canViewTMLicense' = 'VIEW_TM_LICENSE',
  'canManageTMLicense' = 'MANAGE_TM_LICENSE',
  'canManageSystemSettings' = 'MANAGE_AM_SYSTEM_SETTINGS',
  'canViewDEVCTMLicense' = 'VIEW_DEVCTM_LICENSE',
  'canManageDEVCTMLicense' = 'MANAGE_DEVCTM_LICENSE'

}

export enum PermissionMap {
  'VIEW_TENANT' = 'canViewTenant',
  'MANAGE_TENANT' = 'canManageTenant',
  'VIEW_AM_USER' = 'canViewUser',
  'MANAGE_AM_SYSTEM_USER' = 'canManageSystemUser',
  'MANAGE_AM_ACCOUNT_USER' = 'canManageAccountUser',
  'VIEW_AM_ACCOUNT' = 'canViewAccount',
  'MANAGE_AM_ACCOUNT' = 'canManageAccount',
  'VIEW_AM_ORGANIZATION' = 'canViewOrganization',
  'MANAGE_AM_ORGANIZATION' = 'canManageOrganization',
  'MANAGE_AM_PERMISSION' = 'canManagePermission',
  'VIEW_SEAT' = 'canViewSeat',
  'MANAGE_SEAT' = 'canManageSeat',
  'VIEW_ENROLLMENT' = 'canViewEnrollment',
  'MANAGE_ENROLLMENT' = 'canManageEnrollment',
  'VIEW_CA' = 'canViewCA',
  'MANAGE_CA' = 'canManageCA',
  'VIEW_PROFILE' = 'canViewProfile',
  'MANAGE_PROFILE' = 'canManageProfile',
  'MANAGE_AM_LICENSE' = 'canManageLicense',
  'VIEW_AM_LICENSE' = 'canViewLicense',
  'VIEW_LICENSED_SEATS' = 'canViewLicensedSeats',
  'MANAGE_LICENSED_SEATS' = 'canManageLicensedSeats',
  'VIEW_TEMPLATE' = 'canViewTemplate',
  'MANAGE_TEMPLATE' = 'canManageTemplate',
  'VIEW_CERTIFICATE' = 'canViewCertificate',
  'REVOKE_CERTIFICATE' = 'canRevokeCertificate',
  'VIEW_AM_AUDIT_LOG' = 'canViewAuditLog',
  'MANAGE_AM_SYSTEM_SETTINGS' = 'canManageSystemSettings',
  'VIEW_AM_ROLE' = 'canViewUserRole',
  'MANAGE_AM_ROLE' ='canManageUserRole'
}

export interface IPermissions {
  canViewTenant: boolean
  canManageTenant: boolean
  canViewUser: boolean
  canManageSystemUser: boolean
  canManageAccountUser: boolean
  canViewAccount: boolean
  canManageAccount: boolean
  canViewOrganization: boolean
  canManageOrganization: boolean
  canManagePermission: boolean
  canViewSeat: boolean
  canManageSeat: boolean
  canViewEnrollment: boolean
  canManageEnrollment: boolean
  canViewCA: boolean
  canManageCA: boolean
  canViewProfile: boolean
  canManageProfile: boolean
  canManageLicense: boolean
  canViewLicense: boolean
  canViewLicensedSeats: boolean
  canManageLicensedSeats: boolean
  canViewTemplate: boolean
  canManageTemplate: boolean
  canViewCertificate: boolean
  canRevokeCertificate: boolean
  canViewAuditLog: boolean
  canManageSystemSettings: boolean
  canViewUserRole: boolean
  canManageUserRole: boolean
}

export const getPermissions = createSelector(
  [getPermissionsList],
  (profilePermissions) => {
    if (!profilePermissions) return null

    return profilePermissions.reduce((result: IPermissions, item) => {
      return {
        ...result,
        [PermissionMap[item.name]]: true
      }
    }, {} as IPermissions)
  }
)
