import axios from 'axios'
import { API } from 'core/utils/constants'
import Store from '../store/store'
import { apiEnd, apiError, apiStart, apiSuccess } from './api'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action)
  if (action.type !== API) return
  const accessToken = Store.getState().auth.accessToken
  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    label,
    headers,
  } = action.payload

  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data'

  // axios default configs
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || ''
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

  if (label) {
    dispatch(apiStart(label))
  }

  axios
    .request({
      url,
      method,
      headers,
      [dataOrParams]: data,
    })
    .then(({ data }): void => {
      dispatch(apiSuccess(label, data))
      onSuccess(data)
    })
    .catch((error: any): void => {
      console.error(error, 'error')
      dispatch(apiError(label, error))
      onFailure(error)
    })
    .finally((): void => {
      dispatch(apiEnd(label))
    })
}

export default apiMiddleware
