import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import {
  Button,
  ButtonType,
  Form,
  FormFieldSize,
  FormGroup,
  Input,
  Select,
  SubmitButton
} from '@digicert/dcone-common-ui'
import { RouterConfig } from 'core/utils/constants'
import { RouteComponentProps } from 'react-router'
import history from 'core/history/history'
import { FormMode, IMatch, IMatchParams } from 'shared/interfaces'
import { ISigningProvider } from '../AccountSigningClient.types'
import { translate } from 'shared/helpers/utils'
import styles from '../AccountSigningClient.module.scss'

interface IProps extends RouteComponentProps {
  platformProviders?:Array<ISigningProvider[]>
  clientName?: string
  initialAccountId?: string
  onSubmit: (client_id: string, client_account_id: string) => void
  match: IMatch<IMatchParams>
  mode: string
}

const AccountSigningClientForm = (props: IProps) => {
  const selectText = translate('common.form.select') as string
  const [clientId, setClientId] = useState(selectText)
  const [clientAccountId, setClientAccountId] = useState('')
  const [nameMaxLengthError, setNameMaxLengthError] = useState(false)

  useEffect(() => {
    if (props.initialAccountId) {
      setClientAccountId(props.initialAccountId)
    }
  }, [props.initialAccountId])

  const onClientChange = (id): void => {
    setClientId(id)
  }

  const onClientAccountIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setClientAccountId(event.target.value)
    if (event.target.value.length > 255) {
      setNameMaxLengthError(true)
    } else {
      setNameMaxLengthError(false)
    }
  }

  const validateName = useCallback(() => {
    if (nameMaxLengthError) {
      return translate('notifications.errors.maxLengthError') as string
    }
  }, [nameMaxLengthError])

  const validateClientId = () =>
    !clientId ? (translate('common.form.validation.required') as string) : ''

  const onCancelClick = (): void => {
    const { match } = props
    history.push(RouterConfig.accounts.details(match.params.id))
  }

  const onSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    props.onSubmit(clientId, clientAccountId)
  }

  let selectOptions = [{
    value: '',
    label: ''
  }]
  
  if (props.platformProviders && props.platformProviders[0].length > 0) {
    selectOptions = props.platformProviders[0].filter(item => item.status !== 'DISABLED').map((item) => {
      return { value: item.id, label: item.name }
    })
  }

  return (
    <Form>
      <h1 className={styles.title}>
        {props.mode === FormMode.create ? (
          <Translate id='common.form.buttons.addSigningClient' />
        ) : (
          <Translate id='common.form.buttons.editSigningClient' />
        )}
      </h1>
      <h4 className={styles.subTitle}>
        <Translate id='signingService.providerText' />
      </h4>

      {props.mode === 'create' && (selectOptions.length > 0) && (
        <>
        <FormGroup>
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            id='signing_client_client_id'
            size={FormFieldSize.M}
            allowClear
            defaultValue={clientId}
            value={clientId}
            label={translate('signingService.selector') as string}
            placeholder={translate('signingService.selectPlaceHolder') as string}
            onChange={onClientChange}
            options={selectOptions}
            validate={validateClientId}
            optionFilterProp="label"
            showSearch
          />
        </FormGroup>
        </>
      )}

      {props.mode === 'edit' && (
        <div>
          <span className={styles.clientNameLabel}>
            <Translate id='signingService.selector' />
          </span>
          <div className={styles.clientName}>{props.clientName}</div>
        </div>
      )}

      <FormGroup>
        <Input
          label={translate('signingService.id')}
          help={translate('signingService.idInstructionText')}
          placeholder='ID'
          required
          value={clientAccountId}
          size={FormFieldSize.M}
          onChange={onClientAccountIdChange}
          errors={{
            valueMissing: translate('common.form.validation.required')
          }}
          id='signing_service_id'
          validate={validateName}
        />
      </FormGroup>

      <div className={styles.buttonWrap}>
        <span className={styles.cancelButton}>
          <Button
            id='cancel_btn'
            buttonType={ButtonType.SECONDARY}
            onClick={onCancelClick}
          >
            <Translate id='common.form.buttons.cancelBtn' />
          </Button>
        </span>

        <SubmitButton
          id='save_or_update_organization_btn'
          buttonType={ButtonType.PRIMARY}
          onClick={onSubmitForm}
          disabled={clientId === selectText && clientAccountId === props.initialAccountId}
        >
          <span>
            {props.mode === 'create'
              ? translate('signingService.addButton')
              : translate('signingService.updateButton')}
          </span>
        </SubmitButton>
      </div>
    </Form>
  )
}

export default AccountSigningClientForm
