import { reduce, set, get, isArray } from 'lodash-es'
import { isValidEmail } from '../../shared/helpers/utils'

export default function validate (values, reduxForm) {
  const required: string = 'common.form.validation.required'
  const registeredFields = { ...reduxForm.registeredFields } || {}
  return reduce(registeredFields, (result, value, key) => {
    const currentValue = get(values, key)
    if (key === 'email' && (!currentValue || !isValidEmail(currentValue))) {
      set(result, key, 'common.form.validation.invalidEmail')
    }
    if (!currentValue || (isArray(currentValue) && !currentValue.length)) {
      set(result, key, required)
    }
    return result
  }, {})
}
