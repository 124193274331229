import { ThunkDispatch } from 'redux-thunk'
import HTTPClient from 'core/services/HTTPClient'
import { showNotification } from 'core/actions/Notifications.actions'
import { actionCoreApi } from 'core/utils/api'
import { ActionType } from 'core/utils/interfaces'
import ActionsList from 'core/actions/Actions.list'
import { IAppState } from 'core/store/store'
import { actionErrorNotifier } from 'core/utils/helpers'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import { generateRandomId } from 'shared/helpers/utils'
import { IAction } from '../Tenants.types'
import { NotificationTypes } from 'reducers/Reducers.interfaces'
import { IAccountOauthConfigRequest } from '../tenants-form/TenantsForm.types'

export const fetchAccountOauthClientsById = (id: string, oauthClientId: string, configId: string): ActionType =>
actionErrorNotifier(actionCoreApi(
  ActionsList.FetchAccountOauthClient,
  [HTTPUrlHelper.getUpdateAccountOauthConfig(id, oauthClientId,configId)],
  'GET',
  {},
))

export const deleteAccountOauthClientsById = (id: string,  oauthClientId: string, configId: string): ActionType =>
actionErrorNotifier(actionCoreApi(
  ActionsList.DeleteAccountOauthClient,
  [HTTPUrlHelper.getUpdateAccountOauthConfig(id, oauthClientId,configId)],
  'DELETE',
  {},
),true,
{
  title: '',
  message: 'notifications.accounts.deleteAccountOauthClientSuccess'
})

export function addAccountOauthConfig (formData: IAccountOauthConfigRequest, id: string, oauthClientId: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
    return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
      const SUCCESS_STATUS = 201
      const url = HTTPUrlHelper.getAddAccountOauthConfigAPI(id, oauthClientId)
      const { data, status } = await HTTPClient.POST(url, formData)
      if (status === SUCCESS_STATUS) {
        dispatch(showNotification({
          id: generateRandomId(),
          title: '',
          message: 'notifications.accounts.addAccountOauthClientSuccess',
          type: NotificationTypes.success
        }))
      }
      else {
        dispatch(showNotification({
          id: generateRandomId(),
          title: '',
          message: `notifications.exceptions.${data.error.status}`,
          type: NotificationTypes.error
        }))
      }
    }
  }

  export function updateAccountOauthConfig (formData: IAccountOauthConfigRequest, accountId: string, oauthClientId: string, configId: string, stage: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
    return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
      const SUCCESS_STATUS = 204
      const url = HTTPUrlHelper.getUpdateAccountOauthConfig(accountId, oauthClientId, configId)
      const { data, status } = await HTTPClient.PUT(url, formData)
      const message = stage === 'edit' ? 'notifications.accounts.updateAccountOauthClientSuccess' : stage === 'enabled' ? 'notifications.accounts.enableAccountOauthClientSuccess' : 'notifications.accounts.disbleAccountOauthClientSuccess'
      if (status === SUCCESS_STATUS) {
        dispatch(showNotification({
          id: generateRandomId(),
          title: '',
          message: message,
          type: NotificationTypes.success
        }))
      }
      else {
        dispatch(showNotification({
          id: generateRandomId(),
          title: '',
          message: `notifications.exceptions.${data.error.status}`,
          type: NotificationTypes.error
        }))
      }
    }
  }

  export const fetchAllOauthClients = (account?:string): ActionType =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.FetchOauthClients,
    [HTTPUrlHelper.getAllAccountOuthClientsAPI(account)],
    'GET',
    {},
  ))