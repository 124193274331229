import React, { ReactElement, useEffect, useState } from 'react'
import { PageLevelBanner } from '@digicert/dcone-common-ui'

import { Token } from 'core/services/dta/DTAClient'
import { DTAFriendlyName, DTAHardwareTokenOptionsType } from 'core/services/dta/dta.constants'
import { KeyStoreID } from 'core/services/ddc/ddc.constants'

import Space from 'shared/layouts/space'
import { translate } from 'shared/helpers/Translation.helpers'
import { getTokensBasedOnProfileSettings } from 'shared/helpers/dta.helpers'
import { BaseToken, HardwareTokenList } from 'shared/components/hardware-token-list/HardwareTokenList'

export interface DTAHWTokenSelectorProps {
  onChange: (ddcProfile: Token | null) => void
  onEmptyTokens?: () => void
  onKeySizeError?: (error: string) => void
}

export type RequiredToken = Token & Required<BaseToken>

export const DTAHWTokenSelector = (props: DTAHWTokenSelectorProps): ReactElement => {
  const { onChange, onEmptyTokens = () => {}, onKeySizeError = error => {} } = props

  const [availableHardwareTokens, setAvailableHardwareTokens] = useState<Token[]>([] as Token[])
  const [selectedHardwareToken, setSelectedHardwareToken] = useState<Token | null>(null)

  const [firstAttempt, setFirstAttempt] = useState(true)

  const allowedTokenTypes =  [DTAHardwareTokenOptionsType.ANY]
  const availableTokenVendorNames =
    !allowedTokenTypes.includes(DTAHardwareTokenOptionsType.ANY) && allowedTokenTypes.map(name => DTAFriendlyName[name]).join(', ')

  const certificateDeliveryFormat = 'X509'
  const showYubikeyWarning = (certDeliveryFormat: string, selectedToken: Token | null) =>
    certDeliveryFormat == CERT_DELIVERY_FORMAT.pkcs7 && selectedToken && selectedToken.keyStoreId?.includes(KeyStoreID.YUBIKEY)

  const validateTokenKeySizeBoundaries = (selectedHardwareToken: Token | null) => {
    if (!selectedHardwareToken) return ''
    const keyType = 'RSASSA-PKCS1-v1_5'

    const tokenKeySizeBoundaries = selectedHardwareToken.getAvailableKeyType('RSASSA-PKCS1-v1_5')
    let keySizeFormatted = 2048

    if (!tokenKeySizeBoundaries) {
      return translate('dta.error.invalidKeyType.description', { keyType })
    }

    if (keySizeFormatted < tokenKeySizeBoundaries.minKeySize) {
      return translate(`dta.error.belowMinKeySizeFor${keyType}`, { tokenMin: tokenKeySizeBoundaries.minKeySize, keySizeFormatted})
    }

    if (keySizeFormatted > tokenKeySizeBoundaries.maxKeySize) {
      return translate(`dta.error.aboveMaxKeySizeFor${keyType}`, { tokenMax: tokenKeySizeBoundaries.maxKeySize, keySizeFormatted })
    }

    return ''
  }

  useEffect(() => {
    onChange(selectedHardwareToken)
    const keyError = validateTokenKeySizeBoundaries(selectedHardwareToken)
    onKeySizeError(keyError)
  }, [selectedHardwareToken])

  useEffect(() => {
    (async () => {
      const tokens = await getTokensBasedOnProfileSettings()

      setFirstAttempt(false)

      if (tokens.length) {
        setAvailableHardwareTokens(tokens)
        setSelectedHardwareToken(tokens[0])
      } else {
        const intervalID = setInterval(async () => {
          const tokens = await getTokensBasedOnProfileSettings()
          if (tokens.length) {
            setAvailableHardwareTokens(tokens)
            setSelectedHardwareToken(tokens[0])
            clearInterval(intervalID)
          }

          return
        }, 5000)

        onEmptyTokens()
      }
    })()
  }, [])

  return (
    <>
      {firstAttempt && !availableHardwareTokens.length && (
        <PageLevelBanner
          bannerType='info'
          description={translate('publicEnrollment.loadingTokensDescription')}
          title={translate('publicEnrollment.loadingTokensTitle')}
        />
      )}
      {!firstAttempt && !availableHardwareTokens.length && (
        <PageLevelBanner
          bannerType='warning'
          description={translate('publicEnrollment.noHardwareTokenDetectedDescription', {
            tokenNames: availableTokenVendorNames ? ` (${availableTokenVendorNames})` : ''
          })}
          title={translate('publicEnrollment.noHardwareTokenDetectedTitle')}
        />
      )}
      {!!availableHardwareTokens.length && (
        <Space direction='v' size='m'>
          <div>{translate('pkiClient.tokenListTitle')}</div>
          <HardwareTokenList<Token>
            tokensList={availableHardwareTokens}
            selectedTokenId={selectedHardwareToken?.id}
            selectToken={setSelectedHardwareToken}
            resolveTokenLogoName={(token: Token) => token?.keyStorePath?.toUpperCase() || ''}
            resolveTokenName={(token: Token) => token?.tokenInfo?.label || token?.friendlyName || ''}
          />
        </Space>
      )}
    </>
  )
}
