import { isEqual } from 'lodash-es'
import { IAction } from 'core/actions/Actions.interfaces'
import { IAdminProfile } from './Reducers.interfaces'
import { buildActionType } from 'core/utils/api'
import { PATTERN_SEPARATOR, SUCCESS } from 'core/utils/constants'
import ActionsList from 'core/actions/Actions.list'

const initialState: IAdminProfile = {
  isFetching: false,
  data: undefined,
  permissions: {}
}

export default function profile (state: IAdminProfile = initialState, action: IAction) {
  switch (action.type) {
    case buildActionType(ActionsList.FetchProfile, 'request'):
      return {
        ...state,
        isFetching: true
      }

    case buildActionType(ActionsList.FetchProfile, 'success'): {
      const newPermissions = !state.data
        ? action.payload.permissions
        : isEqual(action.payload.permissions, state.data.permissions) ? state.data.permissions : action.payload.permissions
      return {
        ...state,
        isFetching: false,
        data: {
          ...action.payload,
          permissions: newPermissions
        }
      }
    }

    case buildActionType(ActionsList.FetchProfile, 'failure'):
      return {
        ...state,
        isFetching: false
      }

    case `${ActionsList.FetchPermissions}${PATTERN_SEPARATOR}${SUCCESS}`:
      return {
        ...state,
        permissions: action.payload
      }

    case ActionsList.AuthLogout:
      return initialState

    default:
      return state
  }
}
