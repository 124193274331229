import React, { Component, ReactElement } from 'react'
import { connect } from 'react-redux'
import { IAppState } from 'core/store/store'
import history from 'core/history/history'
import { fetchTenantById } from '../../Tenants.actions'
import { updateAccountOauthConfig } from '../AccountSigningClient.actions'
import {
  AccountSigningClientEditStateProps,
  AccountSigningClientOwnProps,
  TAccountSigningClientEditProps
} from '../AccountSigningClient.types'
import { RouterConfig } from 'core/utils/constants'
import AccountSigningClientForm from '../form/AccountSigningClientForm'
import { FormMode } from 'shared/interfaces'
import { Spin } from 'antd'
import { Spinner } from '@digicert/dcone-common-ui'

const dcOneSpinner = <Spinner/>

class AccountSigningClientEdit extends Component<
  TAccountSigningClientEditProps,
  {}
> {
  state = {
    tenant: this.props.tenant
  }
  public componentDidMount(): void {
    this.props.fetchTenantById(this.props.match.params.id).then((response) => {
      this.setState({ tenant: response })
    })
  }
  public render(): ReactElement {
    const { tenant } = this.state
    if (tenant && tenant.oauth_clients) {
      const account_oauth_name = tenant.oauth_clients.find(
        (client) => client.id === this.props.match.params.configId
      )?.name
      return (
        <AccountSigningClientForm
          mode={FormMode.edit}
          clientName={account_oauth_name}
          onSubmit={this.editAccountSigningClient}
          match={this.props.match}
          history={this.props.history}
          location={this.props.location}
          initialAccountId={this.getInitialValues()}
        />
      )
    } else {
      return (
        <div className='spin-wrap'>
          <Spin indicator={dcOneSpinner}/>
        </div>
      )
    }
  }

  private getInitialValues = (): string => {
    const { tenant } = this.state
    if (tenant !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const account_oauth_id = tenant.oauth_clients.find((client) => client.id === this.props.match.params.configId)!.oauthClientAccountId
      return account_oauth_id
    }
    return ''
  }

  private editAccountSigningClient = (
    client_id: string,
    client_account_id: string
  ): void => {
    const { id, configId } = this.props.match.params
    const payload = {
      oauth_client_account_id: client_account_id,
      enabled: true
    }
    const oauthClient = this.state.tenant?.oauth_clients.find(
      (item) => item.id === this.props.match.params.configId
    )
    if (oauthClient) {
      this.props.updateAccountOauthConfig(
        payload,
        id,
        oauthClient.oauthClientId,
        configId,
        'edit'
      )
    }
    history.push(RouterConfig.accounts.details(this.props.match.params.id))
  }
}

export default connect(
  (
    state: IAppState,
    props: AccountSigningClientOwnProps
  ): AccountSigningClientEditStateProps => {
    return {
      tenant: state.tenants.items.find(
        (item) => item.id === props.match.params.id
      )
    }
  },
  { fetchTenantById, updateAccountOauthConfig }
)(AccountSigningClientEdit)
