import React, { ReactElement, useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { DDC } from 'core/services/ddc/ddc'
import { HardwareTokenType, KeyStoreID } from 'core/services/ddc/ddc.constants'
import styles from 'public-pages/certificate/DdcInstallCertificate.module.scss'
import {
  Button,
  FormFieldSize,
  IncontextBanner,
  PageLevelBanner,
  PageLevelBannerType,
  Select
} from '@digicert/dcone-common-ui'
import { translate } from 'shared/helpers/utils'

export interface DdcHWTokenSelectorProps {
  onChange: Function
  onEmptyTokens?: Function
  handleSubmit: Function

}

export interface HardwareTokenOption {
  id: string
  name: string
  profileName: string
  keyStoreId: KeyStoreID
}

export const DdcHWTokenSelector = (props: DdcHWTokenSelectorProps): ReactElement => {
  const { onChange, onEmptyTokens = () => { }, handleSubmit } = props
  const [availableHardwareTokens, setAvailableHardwareTokens] = useState<any>(null)
  const [selectedHardwareOption, setSelectedHardwareOption] = useState<HardwareTokenOption>()
  const hardwareTokenTypes = [HardwareTokenType.ALL]

  useEffect(() => {
    (async () => {
      const availableHardwareProfiles = await DDC.getAvailableHardwareProfilesBasedOnProfileData(
        hardwareTokenTypes
      )

      const hardwareTokenOptions: HardwareTokenOption[] = availableHardwareProfiles.map(
        profile => ({
          id: profile.keyStoreId + profile.name,
          keyStoreId: profile.keyStoreId,
          name: `${profile.keyStoreFriendlyName} ${profile.friendlyName}`,
          profileName: profile.name
        })
      )

      setAvailableHardwareTokens(hardwareTokenOptions)

      if (hardwareTokenOptions.length) {
        setSelectedHardwareOption(hardwareTokenOptions[0])
        onChange(hardwareTokenOptions[0])
      } else {
        onEmptyTokens()
      }
    })()
  }, [])

  return (
    <>
      <h1><Translate id='publicEnrollment.ddc' /></h1>


      {availableHardwareTokens && !availableHardwareTokens.length && (
        <div className={styles.error}>
          <PageLevelBanner
            bannerType={PageLevelBannerType.ERROR}
            description={<Translate id='publicEnrollment.noHardwareTokenDetected' />}
          />
        </div>

      )}
      {availableHardwareTokens && !!availableHardwareTokens.length && selectedHardwareOption && (
        <>

          <div className={styles.notification}>
            <IncontextBanner
              bannerType="note"
              description={<Translate id='publicEnrollment.hardwareTokenNotification' />}
            />
          </div>
          <div className={styles.wrapper}>

            <Select
              getPopupContainer={triggerNode => triggerNode.parentElement}
              onChange={item => {
                if (item) {
                  const option = availableHardwareTokens.find(option => option.id === item)
                  setSelectedHardwareOption(option)
                  onChange(option)
                }
              }}
              options={availableHardwareTokens}
              value={selectedHardwareOption.name}
              id={selectedHardwareOption.id}
              label={translate('publicEnrollment.hardwareTokenLabel')}
              size={FormFieldSize.M}
            />

          </div>
          <div className={styles.submitBtnWrap} >
            <Button onClick={handleSubmit()} >
              <Translate id={'publicEnrollment.hardwareTokenButton'} />
            </Button>

          </div>
        </>
      )}
    </>
  )
}
