import { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { refreshToken } from 'core/actions/Auth.actions'
import { fetchProfile, fetchUserProfile, fetchUserApplications } from 'core/actions/Profile.actions'
import { IActionCreatorAsync } from 'core/actions/Actions.interfaces'
import { IAppState } from 'core/store/store'
import Cookies from 'js-cookie'
import { DCONE_REFRESH_TOKEN_COOKIE_NAME } from 'core/utils/constants'
import { isOauthFlow } from 'shared/helpers/utils'

interface IProps {
  refreshToken: IActionCreatorAsync
  fetchProfile: IActionCreatorAsync
  fetchUserProfile: IActionCreatorAsync
  fetchUserApplications: IActionCreatorAsync
  expiresAt: string
}

class RefreshToken extends PureComponent<IProps> {
  public async componentDidMount (): Promise<void> {
    if (Cookies.get(DCONE_REFRESH_TOKEN_COOKIE_NAME)) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const stateVal = urlSearchParams.get('state')
        if (!isOauthFlow(stateVal)) {
            await this.props.refreshToken()
             if (!window.location.pathname.includes('/account/agreement')) {
                 this.props.fetchProfile()
                 this.props.fetchUserProfile(true)
                 this.props.fetchUserApplications()
             }
        }
    }
  }

  public componentDidUpdate (prevProps: Readonly<IProps>): void {
    const { expiresAt } = this.props

    if (expiresAt && expiresAt !== prevProps.expiresAt) {
      const timeout: number = moment.utc(expiresAt).diff(moment.utc())
      window.setTimeout(() => this.props.refreshToken(), timeout)
    }
  }

  public render = (): null => null
}

export default connect(
  ({ auth }: IAppState) => ({ expiresAt: auth.expiresAt }),
  { refreshToken, fetchProfile, fetchUserProfile, fetchUserApplications }
)(RefreshToken)
