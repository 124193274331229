import { translate } from './Translation.helpers'

const tryToGetDTAErrorMessage = (what: string | Record<string, any>): string => {
  if (what && typeof what === 'string') {
    return what
  }

  if (typeof what === 'object') {
    if (what.method === 'C_Login') {
      if (what.message === 'CKR_PIN_INCORRECT' || what.message === 'CKR_ARGUMENTS_BAD') {
        return translate('dta.error.invalidPIN.description')
      }

      if (what.message === 'CKR_USER_PIN_NOT_INITIALIZED') {
        return translate('dta.error.notInitializedPIN.description')
      }
    }

    return JSON.stringify(what)
  }

  return translate('dta.error.generic.description')
}

export const getErrorMessage = (error: any): string => {
  if (!error) return ''

  const parsedError = (error.what != undefined && tryToGetDTAErrorMessage(error.what)) || error.message || error

  if (typeof parsedError === 'object') {
    return JSON.stringify(parsedError)
  }

  return parsedError
}
