import HTTPUrlHelper from "shared/helpers/HTTPUrlHelper";
import { actionCoreApi } from "core/utils/api";
import ActionsList from "core/actions/Actions.list";
import { ActionType } from "core/utils/interfaces";
import { getValueFromState } from '../../shared/helpers/utils'

export const verify  = (userName: string, state: string, locale: string,  key: string) : void => {
  fetch(HTTPUrlHelper.verifyUserNameAPI(userName.includes('+') ? userName.replace('+', encodeURIComponent('+')) : userName, state, locale, key), {
    method: 'get' 
}).then(response => {
             const contentType = response.headers.get("content-type");
             if (contentType && contentType.indexOf("application/json") !== -1) {
               return response.json().then(data => {
                 window.location.href = data
               });
             } else {
               const stateDecoded = atob(state)
               const redirectUri = getValueFromState("returnPath=", stateDecoded)
                window.location.href = redirectUri
             }
           });
}

export const fetchCustomLoginSettings = (): ActionType =>
actionCoreApi(
  ActionsList.UserNameExists,
  HTTPUrlHelper.fetchCustomLoginSettings(),
  'GET'
)
