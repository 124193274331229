import React, { ReactElement } from 'react'
import cn from 'classnames'
import { Icon } from '@digicert/dcone-common-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCardFront, faUsbDrive } from '@fortawesome/pro-light-svg-icons'
import styles from '../../../public-pages/certificate/DdcInstallCertificate.module.scss'

export interface BaseToken {
  id?: string
  keyStoreId?: string
  name?: string
  friendlyName?: string
}

export interface HardwareTokenListProps<T extends BaseToken> {
  tokensList: T[]
  selectedTokenId: string | undefined
  selectToken: (token: T) => void
  resolveTokenLogoName: (token: T) => string
  resolveTokenName: (token: T) => string
}

export const HardwareTokenList = <T extends BaseToken>(props: HardwareTokenListProps<T>): ReactElement => {
  const { tokensList, selectedTokenId, selectToken, resolveTokenName } = props
  return (
    <div className={styles.hardwareOptionWrapper}>
      {tokensList.map(token => {
        return (
          <div
            key={token.id}
            className={cn(styles.hardwareOption, {
              [styles.selected]: selectedTokenId === token.id
            })}
            onClick={() => selectToken(token)}
          >
            <div>
              <FontAwesomeIcon icon={faUsbDrive} className={styles.fallbackIcon} />
              <FontAwesomeIcon icon={faCreditCardFront} className={styles.fallbackIcon} />
            </div>

            <div>{resolveTokenName(token)}</div>
          </div>
        )
      })}
    </div>
  )
}
