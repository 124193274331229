export type FileDescription = {
  name: string
  folder: string
  label: string
  sha1: string
  sha256: string
}

export type ClientTool = {
  id: number
  arch: string
  name: string
  link: string
  os: string
  short_description: string
  versions: string[]
  files: { [key: string]: FileDescription[] }
  hideVersion?: boolean
  isSensorCard?: boolean
  isAgentCard?: boolean
}

export enum ClientToolViewTypes {
  DdcWindows = 'ddc-windows',
  DdcMac = 'ddc-mac',
  AesWindows = 'aes-windows',
  SensorDocker = 'sensor-docker',
  SensorLinux = 'sensor-linux',
  SensorWindows = 'sensor-windows',
  DtaWindows = 'dta-windows',
  DtaMac = 'dta-mac',
  AgentWindows = 'agent-windows',
  AgentLinux = 'agent-linux',
  Mcars = 'mcars'
}