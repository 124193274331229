import React from 'react'
import styles from './OauthIntegrations.module.scss'
import { Translate } from 'react-localize-redux'
import { Button, ButtonType } from '@digicert/dcone-common-ui'
import { RouterConfig } from '../../../../core/utils/constants'
import history from 'core/history/history'
import { OauthIntegrationProps } from './OauthIntegration.types'
import OauthCard from '../../../settings/oauth-integration/oauth-card/OauthCard'

const OAuthIntegrations = (
  {
    accountId,
    canManageAccount
  }: OauthIntegrationProps) => {

  const navigateToOauthIntegration = () => {
    history.push(RouterConfig.accounts.addOauth(accountId))
  }

  return (
    <div className={styles.oAuthIntegrations}>
      <p>
        <Translate id={'oauthIntegration.integrationDescription'}/>
      </p>
      {canManageAccount && (
        <Button buttonType={ButtonType.SECONDARY} onClick={navigateToOauthIntegration}>
          <Translate id='oauthIntegration.integrationButtonLabel'/>
        </Button>
      )}
      <div className={styles.content}>
        <OauthCard 
          accountId={accountId}
          canManagePermission={canManageAccount}/>
      </div>
    </div>
  )
}

export default OAuthIntegrations