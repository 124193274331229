export interface IMatch<P> {
  params: P
  isExact: boolean
  path: string
  url: string
}

export interface IMatchParams {
  id: string
  configId?: string
  tokenId?:string
  enrollmentId?: string
  enrollmentCode?: string
}

export enum FormMode {
  create = 'create',
  edit = 'edit',
  enroll = 'enroll'
}
