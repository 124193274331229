import { IActionGeneric } from 'core/actions/Actions.interfaces'
import { IAuditLogsEventState, } from './AuditLogs.types'
import { AuditLogEventActions } from './AuditLogs.constants'

const initialState: IAuditLogsEventState = {
  items: [],
  total: 0
}

export default function events (state: IAuditLogsEventState = initialState, action: IActionGeneric<AuditLogEventActions>) {
  switch (action.type) {
    case AuditLogEventActions.FetchAuditEventsSuccess:
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total
      }
    case AuditLogEventActions.FetchAuditEventsFailure:
      return {
        ...state,
        items: [],
        total: 0
      }
    default:
      return state
  }
}
