import { Component, ReactNode } from 'react'

interface IProps {
  location: Location
}

export default class Redirecting extends Component<IProps> {
  constructor (props: Readonly<IProps>) {
    super(props)
    this.state = { ...props }
  }

  componentDidMount () {
    window.location.href = this.props.location.pathname + this.props.location.search
  }

  public render (): ReactNode {
    return null
  }
}
