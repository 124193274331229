import React, { memo } from 'react'
import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'
import { Translate } from 'react-localize-redux'

import { RouterConfig } from 'core/utils/constants'

const NoPermissionsPage = () => (
  <Result
    status='403'
    title='403'
    subTitle={<Translate id='common.pageMessages.403' />}
    extra={
      <Button className='submit-btn' size='large'>
        <Link to={RouterConfig.urlMap.accounts}><Translate id='common.backHome' /></Link>
      </Button>
    }
  />
)

NoPermissionsPage.displayName = 'NoPermissionsPage'

export default memo(NoPermissionsPage)
