import { formValueSelector } from 'redux-form'

export enum EnrollmentFormFields {
  csr = 'csr',
  enrollmentCode = 'enrollment_code',
  commonName = 'common_name',
  organizationName = 'organization_name',
  organizationUnits = 'organization_units',
  streetAddress = 'street_address',
  postalCode = 'postal_code',
  locality = 'locality',
  state = 'state',
  country = 'country',
  dnsNames = 'dns_names',
  expireDate = 'Expire date',
  validity = 'validity',
  emails = 'emails',
  email = 'email',
  userPrincipalNames = 'user_principal_names'
}

export class EnrollmentFormAdapter {
  static readonly NAME = 'EnrollmentForm'

  static get fields () {
    return EnrollmentFormFields
  }

  static get formSelector () {
    return formValueSelector(EnrollmentFormAdapter.NAME)
  }
}
