import gte from 'semver/functions/gte'
import valid from 'semver/functions/valid'

import { outdatedVersionError, invalidSemverError } from './DdcInitializer.constants'

export const checkMinimalVersion = (currentVersion: string, minimalVersion: string): any => {
  const isValidSemver = valid(currentVersion)

  if (!isValidSemver) {
    throw invalidSemverError
  }

  const isCurrentVersionOk = gte(currentVersion, minimalVersion)

  if (!isCurrentVersionOk) {
    throw outdatedVersionError
  }
}
