import React, { createRef, useEffect, useState } from 'react'
import { licenseFormProps } from './LicenseForm.types'
import { Button, ButtonType, Select } from '@digicert/dcone-common-ui'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons'
import { Translate } from 'react-localize-redux'
import cn from 'classnames'
import { Tooltip } from 'antd'
import styles from './LicenseForm.module.scss'
import { translate } from 'shared/helpers/utils'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import { IlicenseCard } from '../Tenant.types'

declare global {
  interface Window {
    licenseData: any
  }
}

export const LicenseForm = (props: licenseFormProps) => {
  const { dataSource, onCancelClick, updateLicenseByApp, createLicenseAllocationUpdateEvent, context, id, license, app_code } = props
  const accountRemainingArray = new Array<string>(dataSource.length).fill('-')
  const licenseRemainingArray = new Array<string>(dataSource.length).fill('-')
  const updateLimitArray = new Array<string>(dataSource.length)
  const actions = [
    {
      label: translate('common.noChange') as string,
      value: 'no-change'
    },
    {
      label: translate('common.add') as string,
      value: 'add'
    },
    {
      label: translate('common.subtract') as string,
      value: 'subtract'
    }
  ]
  const defaultErrorMessages = new Array<string>(dataSource.length).fill('')
  const errorRefs = new Array<any>(dataSource.length)

  for (const i in dataSource) {
    errorRefs[i] = createRef<HTMLParagraphElement>()
  }

  const [errorMessages, setErrorMessages] = useState(defaultErrorMessages)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [licenseData, setLicenseData] = useState(dataSource)
  const [accountRemaining, setAccountRemaining] = useState(accountRemainingArray)
  const [licenseRemaining, setLicenseRemining] = useState(licenseRemainingArray)
  const [updateLimit, setUpdateLimit] = useState(updateLimitArray)
  const [action, setAction] = useState(new Array<string>(dataSource.length).fill('no-change'))
  const [inputValues, setInputValues] = useState(new Array<string>(dataSource.length).fill(''))
  let inputRefs: Array<HTMLInputElement> = [];

  useEffect(() => {
    if(context) {
      fetch(HTTPUrlHelper.getAMLicenseByApp(context)).then(async (response: any) => {
        if(response.ok) {
          const licenseFeature = await response.json()
          const data = licenseData.map((data, index) => {
            updateLimitArray[index] = data.totalAllocated.toString()
            if (licenseFeature) {
              const feature = licenseFeature.features.find(feat => feat.name.toLowerCase() === data.featureId.toLowerCase())
              if (feature) {
                data.licenseAllocation = feature.attributes.remaining === -1 ? translate('common.form.unlimited') : feature.attributes.remaining
              } else {
                data.licenseAllocation = '-'
              }
            }
            return data
          })
          setUpdateLimit(updateLimitArray)
          setLicenseData(data)
        }
      })
    }
  },[context])

  let licenseFeatures
  if(license) {
    license.applications.map(app => {
      if( app.app_code === app_code ) {
        licenseFeatures = app.features
      }
    })
  }

  const checkValidation = (input: string, item: IlicenseCard, action: string) => {
    const licenseFeatureLimit = licenseFeatures.find(licenseFeature => licenseFeature.name === item.featureId).attributes.limit
    let validation = ''

    if (
      !/^\d*$/.test(input) ||
      /^0[0-9].*$/.test(input) ||
      /^\d*\.\d*$/.test(input) ||
      /^([a-zA-Z])\w+/.test(input)
    ) {
      validation = translate('dashboardLicense.validation.positiveNumber') as string
    }

    if (/^\d*$/.test(input)) {
      if (
        action === 'add' && licenseFeatureLimit !== -1 && parseInt(item.used, 10) < 0 && parseInt(input, 10) < parseInt(item.used, 10)
      ) {
        validation = translate('dashboardLicense.validation.range') + ` ${parseInt(item.used, 10)}` + ' ' + translate('common.to') + ' ' +` ${parseInt(item.licenseAllocation, 10)}.`
      } else if (action === 'add' && item.licenseAllocation !== '-' && item.licenseAllocation != '0' && parseInt(input, 10) > parseInt(item.licenseAllocation, 10)) {
        validation = translate('dashboardLicense.validation.range') + ' 1 ' + translate('common.to') + ' ' +` ${parseInt(item.licenseAllocation, 10)}.`
      }
      else if (action === 'subtract' &&  item.licenseAllocation !== '-' && item.licenseAllocation != '0' && parseInt(input, 10) > item.totalAllocated) {
        validation = translate('dashboardLicense.validation.range') + ' 1 ' + translate('common.to') + ' ' +` ${item.totalAllocated}.`
      }
    }
    if (action === 'subtract' && parseInt(input, 10) > (item.totalAllocated - parseInt(item.used, 10))) {
      validation = translate('dashboardLicense.validation.cannotExceedNumber') + ` ${item.totalAllocated - parseInt(item.used, 10)}.`
    }

    return validation
  }

  const changeTotal = (
    value: string,
    index: number,
    action: string) => {
    const licenseTemp = [...licenseRemaining]
    const accountTemp = [...accountRemaining]
    const updateLimitTemp = [...updateLimit]
    if (action === 'add' && typeof parseInt(value, 10) === 'number') {
      licenseTemp[index] =  licenseData[index].licenseAllocation !== 'Unlimited' ? (parseInt(licenseData[index].licenseAllocation, 10) - parseInt(value, 10)).toString() : licenseData[index].licenseAllocation
      accountTemp[index] = (licenseData[index].remaining + parseInt(value, 10)).toString()
      updateLimitTemp[index] = (licenseData[index].totalAllocated + parseInt(value, 10)).toString()
    }
    if (action === 'subtract' && typeof parseInt(value, 10) === 'number') {
      licenseTemp[index] = licenseData[index].licenseAllocation !== 'Unlimited' ? (parseInt(licenseData[index].licenseAllocation, 10) + parseInt(value, 10)).toString() : licenseData[index].licenseAllocation
      accountTemp[index] = (licenseData[index].remaining - parseInt(value, 10)).toString()
      updateLimitTemp[index] = (licenseData[index].totalAllocated - parseInt(value, 10)).toString()
    }
    if (!value) {
      accountTemp[index] = '-'
      licenseTemp[index] = '-'
      updateLimitTemp[index] = licenseData[index].totalAllocated.toString()
    }
    setLicenseRemining(licenseTemp)
    setAccountRemaining(accountTemp)
    setUpdateLimit(updateLimitTemp)
  }

  const changeLicense = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
    item: IlicenseCard
  ) => {
    const inputValueArray = [...inputValues]
    inputValueArray[index] = event.target.value
    setInputValues(inputValueArray)
    changeTotal(event.target.value, index, action[index])
    const ref = errorRefs[index].current
    if (ref) {
      ref.style.display = 'none'

      const validation = checkValidation(
        event.target.value,
        item, action[index]
      )

      if (validation) {
        setSubmitDisabled(true)
        ref.style.display = 'block'
      }

      const tempErrorMessage = [...errorMessages]
      tempErrorMessage[index] = validation
      setErrorMessages(tempErrorMessage)

      setSubmitDisabled(false)
      for (const item of tempErrorMessage) {
        if (item !== '') {
          setSubmitDisabled(true)
        }
      }
      if (action.every(a => a === 'no-change') && tempErrorMessage.every((error) => error !== '')) {
        setSubmitDisabled(true)
      }

      if (action.every(a => a !== 'no-change') && tempErrorMessage.every((error) => error === '')) {
        setSubmitDisabled(false)
      }
    }
  }

  const onCancel = () => {
    onCancelClick(null, {})
  }

  const onSubmit = () => {
    let index = 0
    const payload = {features: licenseData.map(feature => {
        index ++
        return {
          name: feature.featureId,
          attributes: {
            limit: parseInt(updateLimit[index-1], 10)
          }
        }
      })
    }
    index = 0
    const licenseEventPayload ={features: licenseData.map(feature => {
        index ++
        if (inputValues[index-1] != '') {
          return {
            name: feature.featureId,
            quantity: action[index-1] === 'add' ? parseInt(inputValues[index-1], 10) : - parseInt(inputValues[index-1], 10),
            updated_limit: accountRemaining[index-1] !== '-' ? parseInt(accountRemaining[index-1], 10) : feature.remaining
          }
        }
      }).filter(feat => feat != undefined)}
    updateLicenseByApp(payload, context, id, licenseEventPayload, app_code)
    .then((data) => {
      setTimeout(() => {
        onCancelClick(null, { app_code: app_code,
          data: window.licenseData})
      }, 1000)
    })
  }

  function onChangeAction(e, index) {
    const actionArr = [...action]
    actionArr[index] = e
    setAction(actionArr)
    const inputArray = [...inputValues]
    if (e === 'no-change') {
      inputArray[index] = ''
      setInputValues(inputArray)
      changeTotal('', index, e)
    } else {
      changeTotal(inputValues[index], index, e)
    }
    const isTrue = (currentValue) => currentValue === 'no-change';
    if(actionArr.every(isTrue)) {
      setSubmitDisabled(true)
    }
  }

  const onBtnMouseEnter = () => {
    if (submitDisabled && errorMessages.every((item) => item === '')) {
      setTooltipVisible(true)
    }
  }

  const onBtnMouseLeave = () => {
    setTooltipVisible(false)
  }

  const onSelect = (index) => {
    setTimeout(()=> {
      inputRefs[index].focus()
    }, 100)
  }

  const saveInputRef = (el) => {
    if(el){
      inputRefs.push(el)
    }
  }

  return (
    <>
      <section className={styles.licenseCardForm}>
        <ul className={styles.licenseCardFormHeader}>
          <li><strong><Translate id='common.form.licenseType' /></strong></li>
          <li><Translate id='common.form.updateNumberOfLicenses' /></li>
          <li><Translate id='common.form.remainingAccount' /></li>
          <li><Translate id='common.form.remainingPlatform' /></li>
        </ul>
        {licenseData.map((item, index) => {
          let options = [...actions]
          if (parseInt(item.licenseAllocation) === 0 && item.totalAllocated == 0 && item.remaining == 0) {
            options.splice(1,2)
          }
          else if (item.totalAllocated == parseInt(item.used) && parseInt(item.used) > 0 && parseInt(item.licenseAllocation) == 0) {
            options.splice(1,2)
          }
          else if (parseInt(item.licenseAllocation) === 0) {
            options.splice(1,1)
          }
          else if (item.remaining <= 0) {
            options.splice(2,1)
          }
          else if (typeof item.licenseAllocation == 'undefined' && item.remaining.toString() == '-') {
            options.splice(1,2)
          }
          return (
            <div key={index} className={styles.licenseCardFormRowContainer}>
              <div className={styles.licenseCardFormRow}>
                <div>{item.licenseType}</div>
              </div>
              <div className={styles.licenseCardFormRow}>
                <div className={styles.licenseCardFormRowItem}>
                  <div className={styles.licenseCardFormRowItemActions}>
                    <Select
                      getPopupContainer={triggerNode => triggerNode.parentElement}
                      defaultValue="no-change"
                      label=""
                      onChange={(event): void => {onChangeAction(event, index)}}
                      options={options}
                      size="s"
                      value={action[index]}
                      onSelect={() => onSelect(index)}
                    />
                    <input
                      ref={saveInputRef}
                      type='text'
                      className={cn(styles.licenseInput, {
                        [styles.invalid]: errorMessages[index] !== ''
                      })}
                      value={inputValues[index]}
                      disabled={action[index] === 'no-change'}
                      onChange={(event): void => {
                        changeLicense(index, event, item)
                      }}
                    />
                  </div>
                  <div className={styles.errors} ref={errorRefs[index]}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    <p className={styles.errorMessage}>{errorMessages[index]}</p>
                  </div>
                </div>
                <div className={styles.licenseCardFormRowItem}>
                  <p><Translate id='common.form.totalAfterUpdate'/></p>
                </div>
              </div>
              <div className={styles.licenseCardFormRow}>
                <div className={styles.licenseCardFormRowItem}>
                  <p>{item.remaining}</p>
                </div>
                <div className={styles.licenseCardFormRowItem}>
                  <p className={action[index] === 'add' ? styles.increased : styles.decreased}>{accountRemaining[index]}</p>
                </div>
              </div>
              <div className={styles.licenseCardFormRow}>
                <div className={styles.licenseCardFormRowItem}>
                  <p>{item.licenseAllocation}</p>
                </div>
                <div className={styles.licenseCardFormRowItem}>
                  <p className={action[index] === 'add' ? styles.decreased : styles.increased}>{licenseRemaining[index]}</p>
                </div>
              </div>
            </div>
          )
        })
        }
      </section>
      <div className={styles.buttonWrap}>
        <div className={styles.cancelButton}>
          <Button buttonType={ButtonType.SECONDARY} onClick={onCancel}>
            <Translate id='profile.cancelBtn' />
          </Button>
        </div>
        <div
          onMouseEnter={onBtnMouseEnter}
          onMouseLeave={onBtnMouseLeave}
          style={{ display: 'inline' }}
        >
          <Tooltip
            title={<Translate id='dashboardLicense.tooltip' />}
            placement='top'
            visible={tooltipVisible}
          >
            <button
              className={cn(styles.submitButton, {
                [styles.btnDisabled]: submitDisabled
              })}
              onClick={onSubmit}
              disabled={submitDisabled}
            >
              <Translate id='common.form.buttons.updateLicense' />
            </button>
          </Tooltip>
        </div>
      </div>
    </>
  )
}
