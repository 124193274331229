import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'

import Store from '../store/store'
import History from '../history/history'
import { logout } from '../actions/Auth.actions'
import { imauthLoginUrl } from 'configs/Path.configs'

export default class HTTPClient {
  private static axiosClient (clientConfig?: AxiosRequestConfig): AxiosInstance {
    let config: AxiosRequestConfig = {
      headers: { 'content-type': 'application/json' }
    }

    if (clientConfig) {
      const { headers: clientHeaders, ...rest } = clientConfig

      config = {
        headers: {
          ...config.headers,
          ...clientHeaders
        },
        ...rest
      }
    }

    return axios.create(config)
  }

  public static GET (URL: string, clientConfig?: AxiosRequestConfig): AxiosPromise<any> {
    return HTTPClient.axiosClient(clientConfig)
      .get<AxiosPromise<any>>(URL)
      .catch(this.onError)
  }

  public static POST (URL: string, data: any, clientConfig?: AxiosRequestConfig): AxiosPromise<any> {
    return HTTPClient.axiosClient(clientConfig)
      .post(URL, data)
      .catch(this.onError)
  }

  public static PUT (URL: string, data: any, clientConfig?: AxiosRequestConfig): AxiosPromise<any> {
    return HTTPClient.axiosClient(clientConfig)
      .put(URL, data)
      .catch(this.onError)
  }

  public static DELETE (URL: string, payload?: any, clientConfig?: AxiosRequestConfig): AxiosPromise<any> {
    return HTTPClient.axiosClient(clientConfig)
      .delete(URL, { params: payload })
      .catch(this.onError)
  }

  private static onError (error): AxiosResponse<any> {
    if (error.response && error.response.status === 401) {
      Store.dispatch(logout())
      History.push(`${imauthLoginUrl}?returnPath=` + window.location.pathname)
    } else {
      console.error('Error Message:', error.message)
    }

    return error.response || error.message
  }
}
