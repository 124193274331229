import ActionsList from 'core/actions/Actions.list'
import { IAction } from 'core/actions/Actions.interfaces'
import { ITemplates } from './Reducers.interfaces'
import { buildActionType } from 'core/utils/api'

const initialState: ITemplates = {
  items: [],
  total: 0
}

export default function templates (state: ITemplates = initialState, action: IAction) {
  switch (action.type) {
    case buildActionType(ActionsList.FetchTemplates, 'success'):
      return {
        ...state,
        isFetching: false,
        items: action.payload.items,
        total: action.payload.total
      }
    case buildActionType(ActionsList.FetchTemplates, 'failure'):
      return {
        ...state,
        isFetching: false
      }
    case ActionsList.AuthLogout:
      return initialState

    default:
      return state
  }
}
