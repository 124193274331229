import ActionsList from 'core/actions/Actions.list'
import { updateObjectInArray } from 'reducers/Reducers.helpers'
import { IOrganizations } from 'reducers/Reducers.interfaces'
import { IAction } from './Organizations.types'
import OrganizationsActions from './Organizations.constants'
import { buildActionType } from 'core/utils/api'

const initialState: IOrganizations = {
  items: [],
  total: 0,
  isFetching: false,
  isCreating: false,
  isEditing: false
}

export default function organizations (state: IOrganizations = initialState, action: IAction) {
  switch (action.type) {
    // fetch Organizations
    case buildActionType(OrganizationsActions.FetchOrganizations, 'request'):
      return {
        ...state,
        isFetching: true
      }
    case buildActionType(OrganizationsActions.FetchOrganizations, 'success'):
      return {
        ...state,
        isFetching: false,
        items: action.payload.items,
        total: action.payload.total
      }
    case buildActionType(OrganizationsActions.FetchOrganizations, 'failure'):
      return {
        ...state,
        isFetching: false
      }

    // create new Organization
    case buildActionType(OrganizationsActions.CreateOrganization, 'request'):
      return { ...state, isCreating: true }
    case buildActionType(OrganizationsActions.CreateOrganization, 'success'):
    case buildActionType(OrganizationsActions.CreateOrganization, 'failure'):
      return { ...state, isCreating: false }

    // fetch Organization by id
    case buildActionType(OrganizationsActions.FetchOrganization, 'request'):
      return {
        ...state,
        isFetching: true
      }
    case buildActionType(OrganizationsActions.FetchOrganization, 'success'):
      return {
        ...state,
        isFetching: false,
        items: updateObjectInArray(state.items, action.payload)
      }
    case buildActionType(OrganizationsActions.FetchOrganization, 'failure'):
      return {
        ...state,
        isFetching: false
      }

    // edit Organization
    case buildActionType(OrganizationsActions.EditOrganization, 'request'):
      return {
        ...state,
        isEditing: true
      }
    case buildActionType(OrganizationsActions.EditOrganization, 'success'):
    case buildActionType(OrganizationsActions.EditOrganization, 'failure'):
      return {
        ...state,
        isEditing: false
      }

    case ActionsList.AuthLogout:
      return initialState

    default:
      return state
  }
}
