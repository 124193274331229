import { buildActionType } from 'core/utils/api'
import { IAction } from './ApiTokens.types'
import { IApiTokens } from 'reducers/Reducers.interfaces'
import { ApiTokensActions } from './ApiTokens.constants'
import ActionsList from 'core/actions/Actions.list'
import { updateObjectInArray } from 'reducers/Reducers.helpers'

const initialState: IApiTokens = {
  items: [],
  total: 0,
  apiToken: undefined
}

export default function apiTokens (state: IApiTokens = initialState, action: IAction) {
  switch (action.type) {
    // fetch API tokens
    case buildActionType(ApiTokensActions.FetchApiTokens, 'request'):
      return { ...state, apiToken: undefined }
    case buildActionType(ApiTokensActions.FetchApiTokens, 'success'):
      return { ...state, items: action.payload.items, total: action.payload.total }
    case buildActionType(ApiTokensActions.FetchApiTokens, 'failure'):
      return { ...state, items: [] }

    // create API token
    case buildActionType(ApiTokensActions.CreateApiToken, 'request'):
    case buildActionType(ApiTokensActions.CreateApiToken, 'failure'):
      return { ...state, apiToken: undefined }
    case buildActionType(ApiTokensActions.CreateApiToken, 'success'):
      return { ...state, apiToken: action.payload.token }
             
    // Fetch Api token by ID
    case buildActionType(ActionsList.FetchApiToken, 'request'):
    case buildActionType(ActionsList.FetchApiToken, 'failure'):
      return { ...state, apiToken: undefined }
    case buildActionType(ActionsList.FetchApiToken, 'success'):
      return { ...state, items: updateObjectInArray(state.items, action.payload) }

    default:
      return state
  }
}
