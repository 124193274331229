import { combineReducers } from 'redux'
import { localizeReducer as localize } from 'react-localize-redux'
import { reducer as form } from 'redux-form'
import auth from './AuthReducer'
import profile from './ProfileReducer'
import userProfile from './UserProfileReducer'
import license from './LicenseReducer'
import users from './UsersReducer'
import { TenantsReducer as tenants } from '../secure-pages/tenants'
import { AccountSignInReducer as federation } from '../secure-pages/tenants/'
import accounts from './AccountsReducer'
import organizations from '../secure-pages/organizations/Organizations.reducer'
import contacts from './ContactsReducer'
import locales from './LocalesReducer'
import enrollment from './EnrollmentReducers'
import tenantTemplates from '../shared/components/switchers-template/switchers-template.reducers'
import templates from './TemplatesReducer'
import apiTokens from '../secure-pages/api-tokens/ApiTokens.reducer'
import clientAuthCerts from '../secure-pages/client-auth/ClientAuth.reducer'
import auditEvents from 'secure-pages/reporting/audit-logs/AuditLogs.reducer'
import { AccountSigningClient as accountSigningClient } from 'secure-pages/tenants/'
import certificateObtaining from 'public-pages/certificate/CertificateObtaining.reducer'
import features from './FeatureReducer'
import userApplications from './UserApplicationReducer'
import { notificationsReducer } from '../shared/components/notifications/index'

export default combineReducers({
  auth,
  profile,
  userProfile,
  userApplications,
  users,
  tenants,
  federation,
  accounts,
  organizations,
  contacts,
  locales,
  enrollment,
  localize,
  license,
  features,
  form,
  tenantTemplates,
  templates,
  apiTokens,
  clientAuthCerts,
  auditEvents,
  accountSigningClient,
  certificateObtaining,
  notifications: notificationsReducer
})
