import { IAction } from 'core/actions/Actions.interfaces'
import { ILicenseData } from './Reducers.interfaces'
import { buildActionType } from 'core/utils/api'
import ActionsList from 'core/actions/Actions.list'

const initialState: ILicenseData = {
  data: undefined
}

export default function license (state: ILicenseData = initialState, action: IAction) {
  switch (action.type) {
    case buildActionType(ActionsList.GetLicense, 'request'):
      return {
        ...state
      }
    case buildActionType(ActionsList.GetLicense, 'success'):
      return {
        ...state,
        data: {
          ...action.payload,
        }
      }
    case buildActionType(ActionsList.GetLicense, 'failure'):
      return {
        ...state
      }

    default:
      return state
  }
}
