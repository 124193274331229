import { ICertificateObtaining } from './interfaces'

const initialState: ICertificateObtaining = {
  x509_cert: '',
  predefinedKeyStoreId: undefined,
predefinedKeyStoreProfileName: ''
}

export default function certificateObtaining(
  state: ICertificateObtaining = initialState,
): ICertificateObtaining {
      return state
}
