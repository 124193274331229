enum ActionsList {
  AuthToken = 'AUTH_TOKEN',
  AuthLogout = 'AUTH_LOGOUT',
  VerifyUser = 'VERIFY_USER',
  VerifyUserName = 'VERIFY_USER_NAME',
  FetchPermissions = 'FETCH_PERMISSIONS',
  FetchRole = 'FETCH_ROLE',
  UpdateLayoutProperties = 'UPDATE_LAYOUT_PROPERTIES',
  ShowNotification = 'SHOW_NOTIFICATION',
  HideNotification = 'HIDE_NOTIFICATION',
  FetchLicense = 'FETCH_LICENSE',
  SetupLicense = 'SETUP_LICENSE',
  Updateicense = 'UPDATE_LICENSE',
  UpdateLicenseRequest = 'UPDATE_LICENSE_REQUEST',
  UpdateLicenseSuccess = 'UPDATE_LICENSE_SUCCESS',
  UpdateLicenseFailure = 'UPDATE_LICENSE_FAILURE',
  GetLicense = 'GET_LICENSE',
  GetLicenseDetails = 'GET_LICENSE_DETAILS',
  SetupUser = 'SETUP_USER',
  FetchLanguage = 'FETCH_LANGUAGE',
  FetchLocales = 'FETCH_LOCALES',
  FetchLayout = 'FETCH_LAYOUT',
  SetHubVisible = 'SET_HUB_VISIBLE',
  GetFeatures = 'GET_FEATURES',

  FetchTranslationsRequest = 'FETCH_TRANSLATIONS_REQUEST',
  FetchTranslationsSuccess = 'FETCH_TRANSLATIONS_SUCCESS',
  FetchTranslationsFailure = 'FETCH_TRANSLATIONS_FAILURE',

  EditSettingsRequest = 'EDIT_SETTINGS_REQUEST',
  EditSettingsSuccess = 'EDIT_SETTINGS_SUCCESS',
  EditSettingsFailure = 'EDIT_SETTINGS_FAILURE',

  FetchUsersList = 'FETCH_USERS_LIST',
  FetchUsersListRequest = 'FETCH_USERS_LIST_REQUEST',
  FetchUsersListSuccess = 'FETCH_USERS_LIST_SUCCESS',
  FetchUsersListFailure = 'FETCH_USERS_LIST_FAILURE',

  FetchAccounts = 'FETCH_ACCOUNTS',

  CreateUserRequest = 'CREATE_USER_REQUEST',
  CreateUserSuccess = 'CREATE_USER_SUCCESS',
  CreateUserFailure = 'CREATE_USER_FAILURE',

  CreateUserRole = 'CREATE_USER_ROLE',
  UpdateUserRole = 'UPDATE_USER_ROLE',
  ArchiveUserRole = 'ARCHIVE_USER_ROLE',

  CreateAccount = 'CREATE_ACCOUNT',
  CreateAccountUpdating = 'CREATE_ACCOUNT_UPDATING',

  EditUserRequest = 'EDIT_USER_REQUEST',
  EditUserSuccess = 'EDIT_USER_SUCCESS',
  EditUserFailure = 'EDIT_USER_FAILURE',

  DeleteUser = 'DELETE_USER',
  ResendVerificationLink = 'RESEND_VERIFICATION_LINK',

  EditAccount = 'EDIT_ACCOUNT',

  EditAccountUpdate = 'EDIT_ACCOUNT_UPDATE',

  FetchUserRequest = 'FETCH_USER_REQUEST',
  FetchUserSuccess = 'FETCH_USER_SUCCESS',
  FetchUserFailure = 'FETCH_USER_FAILURE',

  SendLockedUnlockedUserEmail = 'SEND_USER_STATUS_EMAIL',

  UpdateUserAgreementRequest = 'UPDATE_USER_AGREEMENT_REQUEST',
  UpdateUserAgreementSuccess = 'UPDATE_USER_AGREEMENT_SUCCESS',
  UpdateUserAgreementFailure = 'UPDATE_USER_AGREEMENT_FAILURE',

  FetchAccount = 'FETCH_ACCOUNT',

  FetchProfile = 'FETCH_PROFILE',

  FetchUserProfile = 'FETCH_USER_PROFILE',

  FetchRoles = 'FETCH_ROLES',

  FetchUsersByRole = 'FETCH_USER_COUNT_BY_ROLE',

  DeleteAccount = 'DELETE_ACCOUNT',

  CreateCustomer = 'CREATE_CUSTOMER',

  SortUsersRequest = 'SORT_USERS_REQUEST',
  SortUsersSuccess = 'SORT_USERS_SUCCESS',
  SortUsersFailure = 'SORT_USERS_FAILURE',

  FetchContacts = 'FETCH_CONTACTS',

  DeleteContact = 'DELETE_CONTACT',

  CreateContact = 'CREATE_CONTACT',

  CreateSelfSignedUser = 'CREATE_SELF_SIGNED_USER',

  RedirectAutoLogin = 'REDIRECT_AUTO_LOGIN',

  RedirectOauthCallback = 'REDIRECT_OAUTH_CALLBACK',

  UserNameExists = 'USER_NAME_EXISTS',

  VerifyAuditLogIntegrity = 'AUDIT_LOG_INTEGRITY', 

  GetSelfServiceUser = 'GET_SELF_SERVICE_USER',

  ResetPasscode = 'RESET_PASSCODE',

  EditContactRequest = 'EDIT_CONTACT_REQUEST',
  EditContactSuccess = 'EDIT_CONTACT_SUCCESS',
  EditContactFailure = 'EDIT_CONTACT_FAILURE',

  FetchAccountSeatTypes = 'FETCH_ACCOUNT_SEAT_TYPES',

  FetchEnrollment = 'FETCH_ENROLLMENT',

  FetchEnrollmentsList = 'FETCH_ENROLLMENTS_LIST',

  UpdateEnrollment = 'UPDATE_ENROLLMENT',

  FetchAssignedTemplates = 'FETCH_ASSIGNED_TEMPLATES',

  FetchAccountTemplates = 'FETCH_ACCOUNT_TEMPLATES',

  FetchAccountProfiles = 'FETCH_ACCOUNT_PROFILES',

  FetchTemplates = 'FETCH_TEMPLATES',

  FetchApiToken = 'FETCH_API_TOKEN',

  FetchApiTokenSuccess = 'FETCH_API_TOKEN_SUCCESS',

  FetchApiTokenFailure = 'FETCH_API_TOKEN_FAILURE',

  CreateAccountSignIn = 'CREATE_ACCOUNT_SIGN_IN',

  CreateIdpMetadata = 'CREATE_IDP_METADATA',

  CreateStdUser = 'CREATE_STD_USER',

  ResendMfa = 'RESEND_MFA',

  DeleteAccountOauthClient = 'DELETE_ACCOUNT_OAUTH_CLIENT',

  FetchAccountOauthClients = 'FETCH_ACCOUNT_OAUTH_CLIENTS',

  FetchAccountOauthClient = 'FETCH_ACCOUNT_OAUTH_CLIENT',

  FetchOauthClients = 'FETCH_OAUTH_CLIENTS',

  FetchOauthClient = 'ETCH_OAUTH_CLIENT',

  DeleteOauthClient = 'DELETE_OAUTH_CLIENT',

  AddOauthClient = 'ADD_OAUTH_CLIENT',

  FetchUserApplications = 'FETCH_USER_APPLICATIONS',

  RegenerateClientSecret = 'REGENERATE_CLIENT_SECRET',

  FetchAccountLicenseByApp = 'FETCH_ACCOUNT_LICNESE_BY_APP',

  FetchLicenseByApp = 'FETCH_LICENSE_BY_APP',

  EnableDisableServiceUser = 'ENABLE_DISABLE_SERVICE_USER',

  CreateLicenseAllocationUpdateEvent = "CREATE_LICENSE_ALLOCATION_UPDATE_EVENT",

  GetLicenseLimitEvents = 'GET_LICENSE_LIMIT_EVENTS',

  GetEnrollmentMethods = 'GET_ENROLLMENT_METHODS',

  GetKeyStoreTypes = 'GET_KEYSTORE_TYPES',

  EnableClientAuthCertLogin = 'ENABLE_CLIENT_AUTH_CERT_LOGIN',

  GetEnrollmentById = 'GET_ENROLLMENT_BY_ID',

  RedeemEnrollment = 'REDEEM_ENROLLMENT',

  GenerateClientAuthCertForEnrollment = 'GENERATE_CLIENT_AUTH_CERT_FOR_ENROLLMENT',

  GetclientAuthUrl = 'GET_CLIENT_AUTH_HOST',

  ValidateCsv = 'VALIDATE_CSV',

  FetchCertAuthorities =  'FETCH_CERT_AUTHORITIES',

  FetchFeatureByAccount = 'FETCH_FEATURE_BY_ACCOUNT',

  EnableFeatureForAccount = 'ENABLE_FEATURE_FOR_ACCOUNT',

  FetchConfiguredDomains = 'FETCH_CONFIGURED_DOMAINS',

  ValidatePasscode = 'VALIDATE_PASSCODE',

  FetchFeatureFlag = 'FETCH_FEATURE_FLAG',

  FetchCustomLoginSettings = 'FETCH_CUSTOM_LOGIN_SETTINGS',

  GetSPMetadata = 'GET_SP_METADATA'


}

export default ActionsList
