import React, { Component } from 'react'
import { LocalizeContextProps } from 'react-localize-redux'
import { connect } from 'react-redux'
import history from 'core/history/history'
import { checkLicense } from 'core/actions/Common'
import { LicenseStatuses, RouterConfig } from 'core/utils/constants'
import { IAppState } from 'core/store/store'
import { IActionCreatorAsync } from 'core/actions/Actions.interfaces'
import 'styles/index.scss'
import { IPermission } from 'reducers/Reducers.interfaces'

interface IProps extends LocalizeContextProps {
  isAuth: boolean
  licenseStatus: string
  checkLicense: IActionCreatorAsync
  redirectUrl: string
}

export default function Hoc (HocComponent) {
  class withSetupLicense extends Component<IProps, any> {

    componentDidUpdate (prevProps: Readonly<IProps>): void {
      if (prevProps.isAuth !== this.props.isAuth) {
        if (this.props.isAuth) {
          this.checkLicense()
        }
      }

      if ((prevProps.licenseStatus !== this.props.licenseStatus)) {
          if (this.props.licenseStatus === LicenseStatuses.expired) {
                 history.push(this.props.redirectUrl)
              }
              if (this.props.licenseStatus === LicenseStatuses.notFound) {
                this.setupLicense()
              }

          }
    }


    async checkLicense (): Promise<void> {
        try {
           await this.props.checkLicense()
         } catch (e) {
            //  ESLINT Error empty block statement  no-empty
            console.warn(e)
         }
      }

    private setupLicense (): void {
      history.push(RouterConfig.urlMap.setupLicense)
    }

    render () {
      return (
        <HocComponent { ...this.props } />
      )
    }
  }
  return connect(
    (state: IAppState): { isAuth: boolean, licenseStatus: string, profilePermissions: IPermission[], redirectUrl: string} => {
      return {
        isAuth: state.auth.isAuth,
        licenseStatus: state.auth.licenseStatus,
        redirectUrl: state.auth.redirectUrl,
        profilePermissions: state.profile.data ? state.profile.data.permissions : []
      }
    },
    {checkLicense}
  )(withSetupLicense)
}
