import ActionsList from 'core/actions/Actions.list'
import { updateObjectInArray } from 'reducers/Reducers.helpers'
import { ITenants } from 'reducers/Reducers.interfaces'
import TenantActions from './Tenants.constants'
import { IAction } from './Tenants.types'

const initialState: ITenants = {
  items: [],
  total: 0,
  isLoading: false,
  isCreating: false,
  isEditing: false
}

export default function tenants (state: ITenants = initialState, action: IAction): ITenants {
  switch (action.type) {
    // fetch ant sort tenants
    case TenantActions.FetchTenantsRequest:
      return { ...state, isLoading: true }
    case TenantActions.FetchTenantsSuccess:
      return {
        ...state,
        isLoading: false,
        items: action.payload.items,
        total: action.payload.total
      }
    case TenantActions.FetchTenantsFailure:
      return { ...state, isLoading: false }

    // edit single tenant
    case TenantActions.EditTenantRequest:
      return { ...state, isEditing: true }
    case TenantActions.EditTenantSuccess:
    case TenantActions.EditTenantFailure:
      return { ...state, isEditing: false }

    // edit standard signin
    case TenantActions.EditStdSigninRequest:
      return { ...state, isEditing: true }
    case TenantActions.EditStdSigninSuccess:
    case TenantActions.EditStdSigninFailure:
      return { ...state, isEditing: false }
      
    // create new tenant
    case TenantActions.CreateTenantRequest:
      return { ...state, isCreating: true }
    case TenantActions.CreateTenantSuccess:
    case TenantActions.CreateTenantFailure:
      return { ...state, isCreating: false }

    // fetch tenant by id
    case TenantActions.FetchTenantRequest:
      return { ...state, isLoading: true }
    case TenantActions.FetchTenantSuccess:
      return {
        ...state,
        isLoading: false,
        items: updateObjectInArray(state.items, action.payload)
      }
    case TenantActions.FetchTenantFailure:
      return { ...state, isLoading: false }

    case ActionsList.AuthLogout:
      return initialState

    default:
      return state
  }
}
