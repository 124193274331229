import React, { ReactElement, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { connect, useDispatch } from 'react-redux'
import { KeyStoreID } from 'core/services/ddc/ddc.constants'
import { DDC } from 'core/services/ddc/ddc'
import { showNotification } from 'core/actions/Notifications.actions'
import { DdcInitializerComponent } from 'shared/components/ddc-initializer/DdcInitializer'
import { generateRandomId } from 'shared/helpers/utils'
import { NotificationTypes } from 'reducers/Reducers.interfaces'
import styles from './DdcInstallCertificate.module.scss'
import { Button, PageLevelBanner, PageLevelBannerType } from '@digicert/dcone-common-ui'
import ActionsList from 'core/actions/Actions.list'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import { actionCoreApi } from 'core/utils/api'
import { ActionType } from 'core/utils/interfaces'
import { actionErrorNotifier } from 'core/utils/helpers'
import { IActionCreatorAsync } from 'core/actions/Actions.interfaces'

export const getClientAuthUrl = (): ActionType =>
actionErrorNotifier(actionCoreApi(
    ActionsList.GetclientAuthUrl,
    HTTPUrlHelper.getClientAuthUrl()
))


interface InstallCertificateProps {
  certBody?: string
  enrollmentId?: string
  enrollmentLinkSecret?: string
  serial_number?: string
  has_parent?: boolean
  predefinedKeyStoreId?: KeyStoreID
  predefinedKeyStoreProfileName?: string
  predefinedSecurityPolicy?: any
  onCertificateInstalled?: Function
  getClientAuthUrl: IActionCreatorAsync
}

const DdcInstallCertificate = (props: InstallCertificateProps): ReactElement => {
  const {
    certBody,
    serial_number,
    predefinedKeyStoreId,
    predefinedKeyStoreProfileName,
    predefinedSecurityPolicy,
    //TODO Unexpected empty arrow function lint error
    onCertificateInstalled = () => {console.log('onCertificateInstalled')}
  } = props

  const [isCertificateInstalled, setIsCertificateInstalled] = useState(false)
  // TODO isLoading, keyStoreId, showCertificateNotFoundError, setCurrentKeyStoreName are defined but never used causing lint warnings
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [keyStoreId, setKeyStoreId] = useState<KeyStoreID>()
  const [certificateNotFoundError, showCertificateNotFoundError] = useState(false)
  const [currentKeyStoreName, setCurrentKeyStoreName] = useState('')
  const dispatch = useDispatch()
  const { getClientAuthUrl } = props

  const signIn = async () => {
   getClientAuthUrl().then((response: any) => {
        window.location.href = response
      })
  }

  const installCertificate = async () => {

    setErrorMessage('')

    try {
      const certificateBody = certBody
      setLoading(true)

      const keyStoreId = predefinedKeyStoreId
      const securityPolicy = predefinedSecurityPolicy
      const profileName = predefinedKeyStoreProfileName


      setKeyStoreId(keyStoreId)

      await DDC.installPKCS7(
        keyStoreId as KeyStoreID,
        certificateBody as string,
        securityPolicy,
        profileName
      )
      .then(() => {
        setIsCertificateInstalled(true)
        dispatch(
          showNotification({
            id: generateRandomId(),
            message: 'publicEnrollment.certificateInstalledSuccessfully',
            type: NotificationTypes.success
          })
        )
        onCertificateInstalled()
      })
      .catch(error => {
        dispatch(
          showNotification({
            id: generateRandomId(),
            title: 'enrollment.certificateInstallationFailed',
            message: error.message,
            type: NotificationTypes.error
          })
        )

        throw error
      })
    } catch (error) {
      setErrorMessage(error.message || error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <h1><Translate id='publicEnrollment.ddc'/></h1>

      <DdcInitializerComponent >
        <>
          {isCertificateInstalled && (
            <div className={styles.isCertificateInstalled}>
              <PageLevelBanner
                bannerType={PageLevelBannerType.SUCCESS}
                title={<Translate id={'ddc.title.certificateInstalled'}/>}
                description={
                  <Button onClick={signIn}>
                    <Translate id={'ddc.signInButtonLabel'}/>
                  </Button>
                }
              />
            </div>
          )}

          {errorMessage && (
            <div className={styles.error}>
              <PageLevelBanner
                bannerType={PageLevelBannerType.ERROR}
                title={<Translate id='ddc.error.certificateInstallationFailed'/>}
                description={errorMessage}
              />
            </div>
          )}

          {certificateNotFoundError && (
            <div className={styles.error}>
              <PageLevelBanner
                bannerType={PageLevelBannerType.ERROR}
                title={<Translate id='ddc.error.certificateInstallationFailed'/>}
                description={<Translate id='ddc.error.noCertificateWithSerialNumberError' data={{ serialNumber: serial_number, keyStoreName: currentKeyStoreName }}/>}
              />
            </div>
          )}
                    {!isCertificateInstalled && (
                      <div className={styles.certificateNotInstalled}>
                        <Button onClick={installCertificate} disabled={!predefinedKeyStoreId}>
                          <Translate id='publicEnrollment.installCertificate' />
                        </Button>
                      </div>
                    )}

        </>
      </DdcInitializerComponent>
    </>
  )
}

export default connect(null, {
getClientAuthUrl
})(DdcInstallCertificate)
