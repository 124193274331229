import { Dispatch } from 'react'
import { set, isNumber, isArray, every, filter } from 'lodash-es'
import { HTTP_SUCCESS_STATUSES, RouterConfig } from 'core/utils/constants'
import { ActionType, IErrorData } from 'core/utils/interfaces'
import { IAction } from 'core/actions/Actions.interfaces'
import HTTPClient from 'core/services/HTTPClient'
import { logout } from '../actions/Auth.actions'
import History from '../history/history'
import { REQUEST, FAILURE, SUCCESS, END, PATTERN_SEPARATOR } from './constants'

export type HTTP_METHODS = 'GET' | 'PUT' | 'POST' | 'DELETE'

export type actionTypes = 'success' | 'failure' | 'end' | 'request'

export const apiError = (label: string, error: any): any => ({
  type: `${label}${PATTERN_SEPARATOR}${FAILURE}`,
  payload: error
})

export function buildActionType (label: string, type: actionTypes = 'request', pattern = PATTERN_SEPARATOR): string {
  let action
  switch (type) {
    case 'success':
      action = SUCCESS
      break
    case 'request':
      action = REQUEST
      break
    case 'failure':
      action = FAILURE
      break
    case 'end':
      action = END
      break
    default:
      action = REQUEST
  }
  return `${label}${pattern}${action}`
}

export const apiStart = (label: string): any => ({
  type: buildActionType(label),
  payload: label
})

export const apiEnd = (label: string): any => ({
  type: buildActionType(label, 'end'),
  payload: label
})

export const apiSuccess = (label: string, data: any): any => ({
  type: buildActionType(label, 'success'),
  payload: data
})

export function isSuccessResponse (status, method): boolean {
  const methodStatuses = HTTP_SUCCESS_STATUSES[method]
  return (isNumber(methodStatuses) && status === methodStatuses) ||
    (isArray(methodStatuses) && methodStatuses.includes(status))
}

export function actionCoreApi<T = IAction, G = any> (
  label: string = '',
  url: string | string[] = '',
  method: HTTP_METHODS = 'GET',
  dataParams: any = {},
  mapDataForReducerFn: (response: any) => any = (response): any => response
): ActionType<T, G> {
  return async function (dispatch: Dispatch<T>): Promise<G | G[] | undefined> {
    dispatch(apiStart(label))
    let data, status, error
    if (isArray(url)) {
      const responses = await Promise.all(url.map((uri: string, index: number): Promise<any> =>
        HTTPClient[method](uri, isArray(dataParams) ? dataParams[index] : dataParams)))
      if (every(responses, (response): any => isSuccessResponse(response.status, method))) {
        const data = responses.map((response): any => response.data)
        dispatch(apiSuccess(label, mapDataForReducerFn(data)))
        return data
      } else {
        error = filter(responses, (res): boolean => res.status !== HTTP_SUCCESS_STATUSES[method])
        dispatch(apiError(label, error))
        const newError = new Error(JSON.stringify(error.map((res): IErrorData => res.data)))
        set(newError, 'status', error.map((res): number => res.status))
        throw newError
      }
    } else {
      const response = await HTTPClient[method](url, { ...dataParams })
      data = response.data
      status = response.status
      if (isSuccessResponse(status, method)) {
        dispatch(apiSuccess(label, mapDataForReducerFn(data)))
        return data
      }
      if (status === 401) {
        // @ts-ignore
        dispatch(logout())
        History.push(RouterConfig.urlMap.login)
      }
      if (data.error) {
        dispatch(apiError(label, data.error))
        error = new Error(data.error.message)
        set(error, 'status', data.error.status)
        throw error
      }
    }
  }
}
