export const marginBottom = value => ({
  marginBottom: value
})

export const marginTop = value => ({
  marginTop: value
})

export const marginRight = value => ({
  marginRight: value
})

export const marginLeft = value => ({
  marginLeft: value
})

export const margin = value => ({
  margin: value
})

export const width = value => ({
  width: `${value}%`
})
