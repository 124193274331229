import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import history from 'core/history/history'
import { RouterConfig } from 'core/utils/constants'
import AccountSigningClientForm from './form/AccountSigningClientForm'
import { FormMode } from 'shared/interfaces'
import { ISigningProvider, TAccountSigningClientProps } from './AccountSigningClient.types'
import { addAccountOauthConfig, fetchAllOauthClients } from './AccountSigningClient.actions'
import { Spinner } from '@digicert/dcone-common-ui'

const AccountSigningClientCreate = (props: TAccountSigningClientProps) => {
  const [platformOauthClients, setPlatformOauthClients] = useState<Array<ISigningProvider[]>>()
  useEffect(()=>{
    props.fetchAllOauthClients().then((res:Array<ISigningProvider[]>)=>{
      setPlatformOauthClients(res)
    })
  },[])

  const createAccountSigningClient = async (
    client_id: string,
    client_account_id: string
  ): Promise<void> => {
    const formData = {
      oauth_client_account_id: client_account_id,
      enabled: true
    }

    await props.addAccountOauthConfig(
      formData,
      props.match.params.id,
      client_id
    )
    history.push(RouterConfig.accounts.details(props.match.params.id))
  }

    if ((platformOauthClients && platformOauthClients.length > 0)) {
      return (
        <AccountSigningClientForm
          mode={FormMode.create}
          platformProviders={platformOauthClients}
          onSubmit={createAccountSigningClient}
          match={props.match}
          history={props.history}
          location={props.location}
        />
      )
    } else {
      return (
        <div className='spin-wrap'>
          <Spinner/>
        </div>
      )
    }
}
export default connect(
  null,
  {fetchAllOauthClients, addAccountOauthConfig}
)(AccountSigningClientCreate)
