import { DTACreateTimeout } from './dta.constants'
import { APIClient } from './DTAClient'
import { fetchDtaLicense } from './DTAClient.helpers'
export class PKIClientInstance {
  private static instance: APIClient | null = null

  public static async get(): Promise<APIClient | null> {
    let instance

    if (this.instance) {
      const isCurrentInstanceRunning = await this.instance.isRunning()

      if (isCurrentInstanceRunning) {
        return Promise.resolve(this.instance)
      }
    }

    instance = await APIClient.create(await fetchDtaLicense(), DTACreateTimeout)

    this.instance = instance

    return this.instance
  }
}
