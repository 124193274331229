import { dispatcherActionType } from 'core/utils/interfaces'
import { actionErrorNotifier } from 'core/utils/helpers'
import { actionCoreApi } from 'core/utils/api'
import ActionsList from 'core/actions/Actions.list'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import { GenerateCertRequestPayload } from './PublicEnrollment.types'
import { ThunkDispatch } from 'redux-thunk'
import { IAppState } from 'core/store/store'
import { IAction } from 'core/actions/Actions.interfaces'
import HTTPClient from 'core/services/HTTPClient'
import history from 'core/history/history'
import { BasePathConst } from 'configs/Path.configs'


export function fetchEnrollmentById (id: string, code: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const URL: string = HTTPUrlHelper.getEnrollmentByIdAPI(id, code)
    const { data, status } = await HTTPClient.GET(URL)
    if (data && status != 200) {
       history.push(`${BasePathConst}/clientAuthLoginVerificationError/${data.error.status.toLowerCase()}`)
      }
    else { return data}  
  }
}

export const redeemEnrollmentById = (id: string): dispatcherActionType => actionErrorNotifier(
    actionCoreApi(
      ActionsList.RedeemEnrollment,
      HTTPUrlHelper.getRedeemEnrollmentByIdAPI(id),
      'PUT',
      null
    ), false
  )


export const generateCertificateForEnrollment = (
  payload: GenerateCertRequestPayload,
    id: string,
  ): dispatcherActionType =>
  actionErrorNotifier(
    actionCoreApi(
      ActionsList.GenerateClientAuthCertForEnrollment,
      HTTPUrlHelper.generateClientAuthCertForEnrollmentAPI(id),
      'POST',
      payload
    ),
    true,
    {
      title: '',
      message: 'notifications.clientAuth.generateSuccess'
    }
  )
