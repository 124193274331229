import moment, { Moment } from 'moment'

export function disabledDateFromYesterday (current) {
  // Can not select days before today
  return current && current < moment().startOf('day')
}

export function disabledDateFromToday (current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day')
}

export function disabledDateFromTo (from: string): (currentArg: Moment) => boolean {
  return (current) => {
    return current && (current.valueOf() < moment(from).endOf('day').valueOf())
  }
}
