import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { ICertificateObtainingProps } from './interfaces'
import { DTAInstallCertificate } from './DTAInstallCertificate'
import DdcInstallCertificate from './DdcInstallCertificate'

// TODO: create another component for P12 certificate obtaining
export const CertificateObtaining = (props: ICertificateObtainingProps): ReactElement => {
  const { has_parent, serial_number, x509_cert, predefinedKeyStoreId, predefinedKeyStoreProfileName, ddc } = props
  const { enrollmentId, enrollmentLinkSecret } = get(props, 'match.params', [null, null])
  const predefinedSecurityPolicy = get(props, 'location.state.predefinedSecurityPolicy')

  const getChildByFlow = () => {
    return (
    !ddc ? 
      <DTAInstallCertificate
        enrollmentId={enrollmentId}
        enrollmentLinkSecret={enrollmentLinkSecret}
        certBody={x509_cert}
        has_parent={has_parent}
        serial_number={serial_number}
        predefinedKeyStoreId={predefinedKeyStoreId} 
        samlResponseId={''}                  
        //predefinedDTAToken={predefinedDTAToken}
      /> :
        <DdcInstallCertificate
          enrollmentId={enrollmentId}
          enrollmentLinkSecret={enrollmentLinkSecret}
          certBody={x509_cert}
          has_parent={has_parent}
          serial_number={serial_number}
          predefinedKeyStoreId={predefinedKeyStoreId}
          predefinedKeyStoreProfileName={predefinedKeyStoreProfileName}
          predefinedSecurityPolicy={predefinedSecurityPolicy}
      />
    )
  }

  return (
        <>
          {getChildByFlow()}
        </>
  )
}


export default connect(
  null,
  {  }
)(CertificateObtaining)

