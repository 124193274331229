import { IAction } from 'core/actions/Actions.interfaces'
import { IUserApplication } from './Reducers.interfaces'
import { buildActionType } from 'core/utils/api'
import ActionsList from 'core/actions/Actions.list'

const initialState: IUserApplication = {
  data: undefined
}

export default function userApplications (state: IUserApplication = initialState, action: IAction) {
  switch (action.type) {
    case buildActionType(ActionsList.FetchUserApplications, 'request'):
      return {
        ...state
      }
    case buildActionType(ActionsList.FetchUserApplications, 'success'):
      return {
        ...state,
        data: [...action.payload],
      }
    case buildActionType(ActionsList.FetchUserApplications, 'failure'):
      return {
        ...state
      }

    default:
      return state
  }
}
