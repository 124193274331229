import { find, keyBy } from 'lodash'

export function removeItemInArrayById (arr, id) {
  return arr.filter(item => item.id !== id)
}

export function updateObjectInArray (oldArray, newObject) {
  if (!oldArray.length) return [newObject]

  if (!find(oldArray, item => item.id === newObject.id)) {
    return [...oldArray, { ...newObject }]
  } else {
    return oldArray.map(item => item.id === newObject.id ? newObject : item)
  }
}

export function addPropsToObjectInArray (arr, payload, propName) {
  const { id, data } = payload

  return arr.map(item => {
    if (item.id === id) {
      return {
        ...item,
        [propName]: data
      }
    }

    return item
  })
}

export function mapAvailableAccountSeats (response) {
  return response.accounts.reduce((result, tenant) => {
    const accounts = tenant.accounts.reduce((result, account) => {
      return {
        ...result,
        [account.id]: {
          tenantId: tenant.id,
          accountId: account.id,
          accountName: account.name,
          seatTypes: keyBy(account.seat_types, 'type')
        }
      }
    }, {})

    return {
      ...result,
      ...accounts
    }
  }, {})
}

export function mapAvailableTenantSeats (response) {
  return response.accounts.reduce((result, tenant) => {
    return {
      ...result,
      [tenant.id]: {
        tenantId: tenant.id,
        tenantName: tenant.name,
        seatTypes: keyBy(tenant.seat_types, 'type')
      }
    }
  }, {})
}
