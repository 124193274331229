import React, { useEffect, ReactElement, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { detect } from 'detect-browser'
import { DDC } from 'core/services/ddc/ddc'
import { InitializationErrorMessage } from 'core/services/ddc/ddc.constants'
import { checkMinimalVersion } from './DdcInitializer.helpers'
import {
  initTimeout,
  minimalVersion,
  outdatedVersionError,
  invalidSemverError,
  notSupportedOSError,
  ddcInternalInstallLink
} from './DdcInitializer.constants'
import { isSupportedPlatform } from '../../helpers/utils'
import {PageLevelBanner, PageLevelBannerType} from '@digicert/dcone-common-ui'

export interface DdcInitializerProps {
  onInitialize?: (initialized: boolean) => void
  setIsLoading?: (loading: boolean) => void
  children?: ReactElement | ReactElement[]
  needToInitializeDDC?: boolean
}

export const DdcInitializerComponent = (props: DdcInitializerProps): ReactElement => {
  const { onInitialize = () => {},  setIsLoading = () => {}, children, needToInitializeDDC = true } = props
  const [error, setError] = useState('')
  const [isInitialized, setIsInitialized] = useState(false)
  const [isFirstInitAttempt, setIsFirstInitAttempt] = useState(true)
  const [userAgentOS, setUserAgentOS] = useState('')

  const downloadLinkData =
    needToInitializeDDC
      ? {
        link: (
          <a
            href={
              ddcInternalInstallLink(userAgentOS.includes('Mac') ? 'pkg' : 'exe')
            }
            target='_blank'
            rel='noreferrer'
          >
            <Translate id='common.download' />
          </a>
        )
      }
      : undefined

  const tryInitialize = async () => {
    // TODO add flag to not cache instance of alison in case first initialization
    await Promise.resolve()
    .then(() => {
      if (!isSupportedPlatform()) {
        throw notSupportedOSError
      }
    })
    .then(() => DDC.initialize())
    .then(ddcInstance => {
      checkMinimalVersion(ddcInstance.version, minimalVersion)
    })
    .then(() => {
      onInitialize(true)
      setError('')
      setIsFirstInitAttempt(false)
      setIsInitialized(true)
      setIsLoading(false)
    })
    .catch(errorMessage => {
      setError(errorMessage)
      setIsFirstInitAttempt(false)
      setTimeout(tryInitialize, initTimeout)

      throw errorMessage
    })
  }

  useEffect(() => {
    if (needToInitializeDDC) {
      (async () => {
        setIsLoading(true)
        await tryInitialize()
      })()
    }

    const browser = detect()
    if (browser && browser.os) setUserAgentOS(browser.os)
  }, [needToInitializeDDC])

  const getErrorMessage = (error: string): ReactElement | undefined => {
    if (error === InitializationErrorMessage.disabled) return <Translate id='ddc.error.disabled' />
    if (error === InitializationErrorMessage.genericError)
      return <Translate id='ddc.error.genericError' />
    if (error === invalidSemverError) return <Translate id='ddc.error.invalidSemverError' />

    if (error === InitializationErrorMessage.notRunning) {
      return <Translate id='ddc.error.notRunning' data={downloadLinkData} />
    }
    if (error === notSupportedOSError)
      return <Translate id='ddc.error.notSupportedOS' data={{ os: userAgentOS }} />

    if (error === outdatedVersionError) {
      return <Translate id='ddc.error.outdatedVersion' data={downloadLinkData} />
    }
  }

  if (!needToInitializeDDC) {
    return <>{children}</>
  }

  return (
    <>
      {!isInitialized ? (
        <>
          {isFirstInitAttempt && (
            <>
              <h1><Translate id='ddc.title.ddc' /></h1>
              <p><Translate id='ddc.initializing' /></p>
            </>

          )}
          {error && (
            <PageLevelBanner
              bannerType={PageLevelBannerType.ERROR}
              title={<Translate id='ddc.title.error' />}
              description={getErrorMessage(error)}
            />
          )}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  )
}