enum OrganizationsActions {
  FetchOrganizations = 'FETCH_ORGANIZATIONS',
  CreateOrganization = 'CREATE_ORGANIZATION',
  FetchOrganization = 'FETCH_ORGANIZATION',
  EditOrganization = 'EDIT_ORGANIZATION',
  DeleteOrganization = 'DELETE_ORGANIZATION'
}

export default OrganizationsActions

export const OrganizationFormFields = {
  name: 'name',
  account: 'account',
  address: 'address',
  address2: 'address2',
  zipCode: 'zip_code',
  city: 'city',
  state: 'state',
  country: 'country',
  phone: 'phone',
  organization: 'organization',
  organizations: 'organizations'
}