import { getTranslate } from 'react-localize-redux'
import { pickBy, keys } from 'lodash-es'
import moment from 'moment'
import store from 'core/store/store'
import { ymdFormat } from 'core/utils/constants'
import { ISelectOption, IOption } from '../components/fields/Fields.interfaces'
import { ILocationStateFilter } from '../components/filters/Filters.interfaces'
import { ICountrySelectOption } from 'secure-pages/organizations/organizations-edit/OrganizationEdit.types'
import { detect } from 'detect-browser'
import React, {ReactElement} from 'react'
import {Tooltip} from '@digicert/dcone-common-ui'
import {ReactComponent as AmIcon} from '../../assets/images/manager-icons/amIcon.svg'
import {ReactComponent as CaIcon} from '../../assets/images/manager-icons/caIcon.svg'
import {ReactComponent as IotIcon} from '../../assets/images/manager-icons/iotIcon.svg'
import {ReactComponent as StIcon} from '../../assets/images/manager-icons/stIcon.svg'
import {ReactComponent as PkiIcon} from '../../assets/images/manager-icons/pkiIcon.svg'
import {ReactComponent as DtIcon} from '../../assets/images/manager-icons/dtIcon.svg'
import {ReactComponent as DamIcon} from '../../assets/images/manager-icons/damIcon.svg'
import {ReactComponent as OvIcon} from '../../assets/images/manager-icons/ovIcon.svg'
import {ReactComponent as IvIcon} from '../../assets/images/manager-icons/ivIcon.svg'
import {ReactComponent as DseIcon} from '../../assets/images/manager-icons/dseIcon.svg'
import {ReactComponent as GenericIcon} from '../../assets/images/manager-icons/genericIcon.svg'
import {ReactComponent as TlmIcon} from '../../assets/images/manager-icons/tlIcon.svg'
import {ReactComponent as KlIcon} from '../../assets/images/manager-icons/klIcon.svg'
import {ReactComponent as DeviceTmIcon} from '../../assets/images/manager-icons/devicetmIcon.svg'

import { IPermission } from 'reducers/Reducers.interfaces'

export function generateRandomId (): string {
  return String(Math.round(Date.now() * Math.random()))
}

export function mapUserPermission (data: Record<string, boolean>): Record<string, string>[] {
  return keys(pickBy(data, p => p)).map(id => ({ id }))
}

export function mapUserPermissionString(data: Record<string, boolean>, allPermissions: IPermission[] | undefined): string[] | any {
  return Object.keys(data).map(permID => {
    if (keys(data).find((key) => key === permID && data[key])) {
      return allPermissions?.find(perm => perm.id === permID)?.name
    }
  }).filter(val => val !== undefined)
}

export function getEntityNames (entity: { name: string }[]): string {
  return entity.map(account => account.name).join(', ')
}

export function getEntityStatus (isActive: boolean): string {
  return isActive ? 'active' : 'inactive'
}

export function mapOptions (options: ISelectOption[]): IOption[] {
  return options.map(option => ({
    key: option.id,
    label: option.name
  }))
}

export function mapOptionsCommonUi (options?: ISelectOption[]): any[] {
  if (options) {
    return options.map(option => ({
      value: option.id,
      label: option.name
    }))
  }
  return []
}

export function formatDate (date: string, format?: string ) : string {
  return moment.utc(date).format(format ? format : 'DD-MMM-YYYY').replace('--','-')
}

export function formatDateWithTimestamp (date: string, format: string ) : string {
  return moment(date).format(format + ' LT ')
}

export function formatDateWithTimestampAndTimezone (date: string) : string {
  let index = date.indexOf('T')
  let hh = parseInt(date.substring( index+1 , index+3))
  let hour = ((hh + 11) % 12 + 1)
  let ampm = hh >= 12 ? 'PM' : 'AM'
  let timestamp = (hour).toString() + ':' + moment(date).format('mm ') + ampm + ' ' + moment.tz(moment.tz.guess()).format('zz')
  return timestamp
}

export function mapCountryOptions (options: ICountrySelectOption[]): IOption[] {
  return options.filter(option => option.value !== 'CU').filter(option => option.value !== 'IR').filter(option => option.value !== 'SY').filter(option => option.value !== 'KP').map(option => ({
    key: option.value,
    label: option.label
  }))
}

export function mapCountryOptionsCommonUi (options: ICountrySelectOption[]): any[] {
  return options.filter(option => option.value !== 'CU').filter(option => option.value !== 'IR').filter(option => option.value !== 'SY').filter(option => option.value !== 'KP').map(option => ({
    value: option.value,
    label: option.label
  }))
}

export function isValidEmail (email: string): boolean {
  // eslint-disable-next-line no-useless-escape
  return Boolean(email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
}

export function isValidPassword (password: string): boolean {
  return Boolean(password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).+$/))
}

export const flatObject = (obj, concatenator = '.') => (
  Object.keys(obj).reduce(
    (acc, key) => {
      if (Object.prototype.toString.call(obj[key]) !== '[object Object]') {
        return {
          ...acc,
          [key]: obj[key]
        }
      }

      const flattenedChild = flatObject(obj[key], concatenator)

      return {
        ...acc,
        ...Object.keys(flattenedChild).reduce((childAcc, childKey) => ({
          ...childAcc,
          [`${key}${concatenator}${childKey}`]: flattenedChild[childKey]
        }), {})
      }
    },
    {}
  )
)

// this is function to get the translated string
export function translate (id: string) {
  return store ? getTranslate(store.getState().localize)(id) : id
}

export function getValueByKeyChain (dataSource: Object, indexList: string[]): {} | string {
  return indexList.reduce((accumulator, currentValue) => accumulator[currentValue], dataSource)
}

export function filterByColumn (value, record, dataIndex, parseFunc?) {
  let source: any = dataIndex instanceof Array
    ? getValueByKeyChain(record, dataIndex)
    : record[dataIndex]

  if (source === undefined) return false

  if (source instanceof Array) {
    source = parseFunc ? parseFunc(source) : getEntityNames(source)
  } else if (parseFunc) {
    source = parseFunc(source)
  }

  return source
    .toString()
    .toLowerCase()
    .includes(value.toLowerCase())
}

export function parseStatusToText (status: string) {
  return translate(`common.statuses.${status}`)
}

export function parseDateToFormat (date: string, format = ymdFormat): string {
  return moment(date).format(format)
}

export function getFilterFromState (state?: { filter: ILocationStateFilter }) {
  if (state && state.filter) {
    const { columnId, value } = state.filter
    return {
      [columnId]: typeof value === 'string' ? value : value.key
    }
  }

  return undefined
}

export function downloadItem (response: any, extension: string = 'txt', itemName: string = 'Item'): void {
  /* eslint no-undef: off */
  const data = new Blob([response], { type: 'application/json' })
  const csvURL = window.URL.createObjectURL(data)
  const tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', `${itemName}-${moment().format(ymdFormat)}.${extension}`)
  tempLink.click()
}

const SUPPORTED_PLATFORMS = ['Mac', 'Windows']
export function isSupportedPlatform() {
  const browser = detect()
  return SUPPORTED_PLATFORMS.some(platform => {
    // OS can be like Android OS | Mac OS | Linux | Windows Mobile | Windows 3.11 | Windows 95...
    if (browser && browser.os) {
      return browser.os.includes('Mobile') ? false : browser.os.includes(platform)
    }
  })
}

export function isOauthFlow(state: string|null) {
    if (state != null) {
        if (getDecodedState(state).includes('oauthFlow=true')) {
            return true
        }
    }
    return false
}

export function showCreateOneLink(state: string|null) {
    if (state != null) {
        if (getDecodedState(state).includes('showCreateOneLink=true')) {
            return true
        }
    }
    return false
}

export function isSelfEnrollFlow(state: string|null) {
    if (state != null) {
        if (getDecodedState(state).includes('selfEnrollFlow=true')) {
            return true
        }
    }
    return false
}

export function isValidAccountIdPresent(state: string|null) {
    if (state != null) {
        if (atob(state).includes('accountId=')) {
            return true
        }
    }
    return false
}

export function getDecodedState(state: string) {
        return atob(state).split('&')
}

export const renderManagerIcon = (iconName, managerName): ReactElement | undefined => {
  switch (iconName) {
    case 'amIcon':
      return <Tooltip title={managerName}><AmIcon /></Tooltip>;

    case 'caIcon':
      return <Tooltip title={managerName}><CaIcon /></Tooltip>;

    case 'iotIcon':
      return <Tooltip title={managerName}><IotIcon /></Tooltip>;

    case 'stIcon':
      return <Tooltip title={managerName}><StIcon /></Tooltip>;

    case 'pkiIcon':
      return <Tooltip title={managerName}><PkiIcon /></Tooltip>;

    case 'dtIcon':
      return <Tooltip title={managerName}><DtIcon /></Tooltip>;

    case 'damIcon':
      return <Tooltip title={managerName}><DamIcon /></Tooltip>;

    case 'ovIcon':
      return <Tooltip title={managerName}><OvIcon /></Tooltip>;

    case 'ivIcon':
      return <Tooltip title={managerName}><IvIcon /></Tooltip>;

    case 'dseIcon':
      return <Tooltip title={managerName}><DseIcon /></Tooltip>;

    case 'tlIcon':
      return <Tooltip title={managerName}><TlmIcon /></Tooltip>;

    case 'klIcon':
      return <Tooltip title={managerName}><KlIcon /></Tooltip>;

    case 'devicetmIcon':
      return <Tooltip title={managerName}><DeviceTmIcon /></Tooltip>;

    default:
      return <Tooltip title={managerName}><GenericIcon /></Tooltip>;
  }
}

export  const getManagerName = (id: any, keyLockerEnabled: boolean) : string => {
  if (keyLockerEnabled && id === 'secure_software_manager') {
    return translate('dashboardLicense.applications.keylocker') as string
  } else {
    return translate('dashboardLicense.applications.'+id) as string
  }
}

export  const getManagerPermissionGroupName = (id: any, keyLockerEnabled: boolean) : string => {
  if (keyLockerEnabled && id === 'SECURE_SOFTWARE_MANAGER') {
    return translate('permissionGroups.KEYLOCKER') as string
  } else {
    return translate('permissionGroups.'+id) as string
  }
}

export function getValueFromState(searchString: string, stateDecoded: string) {
        const indexOfString = stateDecoded.indexOf(searchString)
        const ch = '&'
        let indexOfEndOfString = stateDecoded.indexOf(ch, indexOfString);
        if (indexOfString != -1 && indexOfEndOfString === -1) {
            indexOfEndOfString = stateDecoded.length
        }
        const value = indexOfString != -1 && indexOfEndOfString != -1 ? stateDecoded.substring(indexOfString, indexOfEndOfString) : ""
        if(value !== "" ) {
          return value.split('=')[1]
        }
        return ""
}

export const getOSName = (): string => {
  const browser = detect()
  return browser?.os || ''
}