import { IEnrollment } from './Reducers.interfaces'
import { ISeatProfileResponse } from 'public-pages/enrollment/Enrollment.interfaces'
import { IAction } from 'core/actions/Actions.interfaces'
import ActionsList from 'core/actions/Actions.list'
import { buildActionType } from 'core/utils/api'

const initialState: IEnrollment = {
  fields: {},
  profile: {},
  isFetching: false
}

export default function enrollment (state: IEnrollment = initialState, action: IAction): IEnrollment {
  switch (action.type) {
    case buildActionType(ActionsList.FetchEnrollment):
      return {
        ...initialState
      }
    case buildActionType(ActionsList.FetchEnrollment, 'success'): {
      // ESLINT error unexpected lexical declaration in case block  no-case-declarations
      const {attributes, profile} = action.payload as ISeatProfileResponse
      return {
        fields: {enrollment_code: null, csr: null, ...attributes},
        profile: {...profile},
        isFetching: false
      }
    }
    case buildActionType(ActionsList.FetchEnrollment, 'failure'):
      return {
        fields: { ...action.payload },
        isFetching: false,
        profile: {}
      }
    default:
      return state
  }
}
