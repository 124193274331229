import ActionsList from 'core/actions/Actions.list'
import { buildActionType } from 'core/utils/api'
import { ITemplate } from 'reducers/Reducers.interfaces'
import { IAction } from 'core/actions/Actions.interfaces'

import { ISwitchersTemplateState } from './switchers-template.interface'

const initialState: ISwitchersTemplateState = {
  list: []
}

export default function tenantTemplates (state: ISwitchersTemplateState = initialState, action: IAction): ISwitchersTemplateState {
  switch (action.type) {
    case buildActionType(ActionsList.FetchAssignedTemplates, 'success'):
      return {
        list: action.payload
      }
    case buildActionType(ActionsList.FetchAssignedTemplates, 'failure'):
      return {
        list: []
      }
    case buildActionType(ActionsList.FetchTemplates, 'success'):
      return {
        ...state,
        list: action.payload.items.filter((template: ITemplate): boolean => template.cert_type === 'end_entity')
      }
    case buildActionType(ActionsList.FetchTemplates, 'failure'):
      return {
        ...state,
        list: []
      }
    default:
      return state
  }
}
