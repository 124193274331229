import React, { ReactElement } from 'react'
import { Menu } from 'antd'
import { capitalize } from 'lodash-es'
import { cardsProps, client } from './SigningClientCards.types'
import { Translate } from 'react-localize-redux'
import { translate } from 'shared/helpers/utils'
import { Card, CardContainer, Tag, TagType } from '@digicert/dcone-common-ui'
import styles from './SigningClientCards.module.scss'

export const SigningClientCards = (props: cardsProps) => {
  const { clients, enableActions } = props

  const onEditClient = (id: string): void => {
    props.onClientEdit(id)
  }

  const onDisableClient = (id: string): void => {
    props.onEnableDisable(id, false)
  }

  const onEnableClient = (id: string): void => {
    props.onEnableDisable(id, true)
  }

  const onDeleteClient = (id: string): void => {
    props.openDeleteModal()
    props.setDeletedClientId(id)
  }

  const renderActions = (client: client): ReactElement => {
    const actions = [
      {
        key: 'edit',
        title: 'common.form.buttons.updateBtn',
        isHidden: client.status.toLocaleLowerCase() === 'disabled',
        onClick: onEditClient,
        isDisabled: false
      },
      {
        key: 'disable',
        title: 'clientAuth.disable',
        isHidden: client.status.toLocaleLowerCase() === 'disabled',
        onClick: onDisableClient,
        isDisabled: false
      },
      {
        key: 'enable',
        title: 'clientAuth.enable',
        isHidden: client.status.toLocaleLowerCase() === 'enabled',
        onClick: onEnableClient,
        isDisabled: false
      },
      {
        key: 'delete',
        title: 'common.form.buttons.deleteBtn',
        isDisabled: false,
        isHidden: false,
        onClick: onDeleteClient
      }
    ]

    const id = client.id

    return (
      <Menu>
        {actions.map((action) => {
          if (!action.isHidden) {
            return (
              <Menu.Item
                key={action.key}
                className={styles.actionItem}
                disabled={action.isDisabled}
                onClick={() => action.onClick(id)}
              >
                <Translate id={action.title} />
              </Menu.Item>
            )
          }
          return null
        })}
      </Menu>
    )
  }

  return (
    <CardContainer>
      {clients &&
        clients.map((client) => {
          return (
            <Card
              headline={client.name}
              key={client.id}
              showStatusContent={true}
              statusLabel={translate('common.form.status') as string}
              status={
                <Tag
                  type={
                    client.status.toLocaleLowerCase() === 'enabled'
                      ? TagType.ACTIVE
                      : TagType.DANGER
                  }
                >
                  {capitalize(client.status.toLocaleLowerCase())}
                </Tag>
              }
              options={enableActions ? renderActions(client) : undefined}
            />
          )
        })}
    </CardContainer>
  )
}
