export const resizeTableBody = (el) => {
  if (el) {
    const tableHeader = el.childNodes[0] as HTMLElement;
    const tableBody = el.childNodes[1] as HTMLElement;
    const tableFooter = el.childNodes[2] as HTMLElement;
    const combinedHeight = (window.innerHeight - el.getBoundingClientRect().top)

    if(combinedHeight > el.clientHeight) {
      tableBody.style.height = 'auto'
    }
    if(combinedHeight < el.clientHeight) {
      tableBody.style.height = combinedHeight - tableHeader.clientHeight - tableFooter?.clientHeight + 'px'
    }
  }
}