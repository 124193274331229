import { connect } from 'react-redux'
import { hideNotification } from '../../../core/actions/Notifications.actions'
import { translate } from '../../helpers/utils'
import { IToastNotification, ToasterContainer, ToasterContainerProps } from '@digicert/dcone-common-ui'
import { NotificationProps } from './Notification.types'

export default connect(
  ({ notifications }: any): ToasterContainerProps => ({
    hideNotification: (id: string | number) => {
      hideNotification(id)
    },
    notifications: notifications.items.map(
      (notification: NotificationProps): IToastNotification => {
        const { title, message } = notification
        const translateMessage = 'translateMessage' in notification ? notification.translateMessage : true
        return {
          ...notification,
          title: title ? title : undefined,
          description: message ? translateMessage && typeof message === 'string' ? translate(message) : message : undefined
        }
      }
    )
  })
)(ToasterContainer)
