import { ActionType } from 'core/utils/interfaces'
import { actionCoreApi } from 'core/utils/api'
import { actionErrorNotifier } from 'core/utils/helpers'
import HTTPUrlHelper from '../../shared/helpers/HTTPUrlHelper'
import ActionsList from './Actions.list'

export const fetchProfile = () =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.FetchProfile,
    HTTPUrlHelper.getProfileApi()
  ))

  export const fetchUserProfile = (hideUnavailableApplications:boolean) =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.FetchUserProfile,
    HTTPUrlHelper.getUserProfileApi(hideUnavailableApplications)
  ))

  export const fetchUserApplications = (accountId?: string) => 
  actionErrorNotifier(actionCoreApi(
    ActionsList.FetchUserApplications,
    accountId ? HTTPUrlHelper.getUserApplicationsWithAccountAPI(accountId) : HTTPUrlHelper.getUserApplicationsAPI()
  ))

export const fetchPermissions = (accessScope?: string, accountId?: string): ActionType => actionErrorNotifier(actionCoreApi(
  ActionsList.FetchPermissions,
  HTTPUrlHelper.getPermissionsAPI(accessScope, accountId)
))


