import { ThunkDispatch } from 'redux-thunk'
import HTTPClient from 'core/services/HTTPClient'
import { showNotification } from 'core/actions/Notifications.actions'
import { actionCoreApi } from 'core/utils/api'
import { ActionType } from 'core/utils/interfaces'
import ActionsList from 'core/actions/Actions.list'
import { IAppState } from 'core/store/store'
import { actionErrorNotifier } from 'core/utils/helpers'
import HTTPUrlHelper from '../../shared/helpers/HTTPUrlHelper'
import { generateRandomId } from 'shared/helpers/utils'
import { IAction } from '../tenants/Tenants.types'
import { NotificationTypes } from 'reducers/Reducers.interfaces'
import { IOauthClientRequest } from '../tenants/tenants-form/TenantsForm.types'

export const addOauthClient = (): ActionType =>
actionErrorNotifier(actionCoreApi(
  ActionsList.AddOauthClient,
  [HTTPUrlHelper.getAccountOuthClientsAPI()],
  'POST',
  {},
))

export const getOauthClientById = (oauthClientId: string): ActionType =>
actionErrorNotifier(actionCoreApi(
  ActionsList.FetchOauthClient,
  [HTTPUrlHelper.getOauthCLientApi(oauthClientId)],
  'GET',
  {},
))

export function deleteOauthClient (oauthClientId: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const SUCCESS_STATUS = 204
    const url = HTTPUrlHelper.getOauthCLientApi(oauthClientId)
    const { data, status } = await HTTPClient.DELETE(url)
    if (status === SUCCESS_STATUS) {
      dispatch(showNotification({
        id: generateRandomId(),
        title: '',
        message: 'deleteOauthIntegration.notificationMessage',
        type: NotificationTypes.success
      }))
    }
    else {
      dispatch(showNotification({
        id: generateRandomId(),
        title: '',
        message: `notifications.exceptions.${data.error.status}`,
        type: NotificationTypes.error
      }))
    }
  }
}

export function updateOauthClient (formData: IOauthClientRequest, oauthClientId: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const SUCCESS_STATUS = 204
    const url = HTTPUrlHelper.getOauthCLientApi(oauthClientId)
    const { data, status } = await HTTPClient.PUT(url, formData)
    if (status === SUCCESS_STATUS) {
      dispatch(showNotification({
        id: generateRandomId(),
        message: 'addOauthIntegration.notificationUpdateSuccessMessage',
        type: NotificationTypes.success
      }))
    }
    else {
      dispatch(showNotification({
        id: generateRandomId(),
        title: `notifications.exceptions.${data.error.status}`,
        message: `notifications.exceptions.${data.error.message}`,
        type: NotificationTypes.error
      }))
    }
  }
}

export function addIntegration (formData: IOauthClientRequest, oauthClientId: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const SUCCESS_STATUS = 204
    const url = HTTPUrlHelper.getOauthCLientApi(oauthClientId)
    const { data, status } = await HTTPClient.PUT(url, formData)
    if (status === SUCCESS_STATUS) {
      dispatch(showNotification({
        id: generateRandomId(),
        title: '',
        message: 'addOauthIntegration.notificationSuccessMessage',
        type: NotificationTypes.success
      }))
    }
    else {
      dispatch(showNotification({
        id: generateRandomId(),
        title: `notifications.exceptions.${data.error.status}`,
        message: `notifications.exceptions.${data.error.message}`,
        type: NotificationTypes.error
      }))
    }
  }
}

export function enableDisableOauthClient (formData: IOauthClientRequest, oauthClientId: string): (dispatch: ThunkDispatch<IAppState, {}, IAction>) => Promise<void> {
  return async (dispatch: ThunkDispatch<IAppState, {}, IAction>): Promise<void> => {
    const SUCCESS_STATUS = 204
    const url = HTTPUrlHelper.getOauthCLientStatusApi(oauthClientId)
    const { data, status } = await HTTPClient.PUT(url, formData)
    let message = 'notifications.accounts.enableOauthclientSuccess'
    if (!formData.enabled) {
      message = 'notifications.accounts.disbaleOauthClientSuccess'
    }
    if (status === SUCCESS_STATUS) {
      dispatch(showNotification({
        id: generateRandomId(),
        title: '',
        message: message,
        type: NotificationTypes.success
      }))
    }
    else {
      dispatch(showNotification({
        id: generateRandomId(),
        title: `notifications.exceptions.${data.error.status}`,
        message: `notifications.exceptions.${data.error.message}`,
        type: NotificationTypes.error
      }))
    }
  }
}

export const regenerateOauthClientSecret = (oauthClientId: string): ActionType =>
actionErrorNotifier(actionCoreApi(
  ActionsList.RegenerateClientSecret,
  [HTTPUrlHelper.getRegenerateOauthClientSecret(oauthClientId)],
  'PUT',
  {},
))