export const BasePathConst: string = '/account'
export const BasePathConstUi: string = '/account/ui-api'

export const accountUrl = `${BasePathConst}/accounts`
export const imauthLoginUrl: string = `/imauth/api/v1/login`
export const imauthLogoutUrl: string = `/imauth/api/v1/logout`
export const certAuthorityUrl: string = `/certificate-authority`
export const enterpriseManagerUrl: string = `/mpki/dashboard`
export const deviceManagerUrl: string = `/iot/dashboard`
export const signingManagerUrl: string = `/signingmanager/dashboard`
export const documentManager: string = `/documentmanager`
