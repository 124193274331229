import React, { ReactElement, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { enableMfa, fetchTenantById } from 'secure-pages/tenants/Tenants.actions'
import { EditMfaProps, EditMfaStateProps } from './EditSignIn.types'
import { IAppState } from 'core/store/store'
import { Translate } from 'react-localize-redux'
import styles from '../AccountSignIn.module.scss'
import layoutOverrides from 'styles/layoutOverrides.module.scss'
import { Button, ButtonType, Form, FormGroup, IncontextBanner, SubmitButton } from '@digicert/dcone-common-ui'
import history from 'core/history/history'

const EditMfa = (props: EditMfaProps) : ReactElement => {
    const [tenant, setTenant] = useState(props.tenant)
    const [tfaStatus, setTfaStatus] = useState('disabled')
    useEffect(() => {
        props.fetchTenantById(props.match.params.id).then((tenant: any) => {
          if (tenant) {
            setTfaStatus(tenant.mfa_enabled ? 'enabled' : 'disabled')
            setTenant(tenant)
          }
        })
      }, [fetchTenantById])

    const handleTwoFactorAuthenticationChange = (e): void => {
        setTfaStatus(e.target.value)
      }

    const onCancel = (e): void => {
        e.preventDefault()
        history.goBack()
    }

    const onSubmit = async (e): Promise<void> => {
        e.preventDefault()
        const { match } = props
        const payload = {
          enabled: tfaStatus === 'enabled'
        }
        await props.enableMfa(match.params.id, payload, `notifications.mfa.${tfaStatus}`)
        history.goBack()
      }

    return (
        <>
        <div className={layoutOverrides.secureLayoutWidthAdjustment}>
            <Form style={{ margin: 0, paddingLeft: '12px' }}>
            <section className={styles.authSection}>
            <header className={styles.header}>
            <h1>
                <Translate id={'editStandardSignIn.TFAHeadline'} />
            </h1>
            </header>  
            <IncontextBanner
                bannerType="note"
                description={<Translate id='singleSignOn.mfaNote'
                />
            }
            />
             <div>
                <p>
                    <Translate id={'editStandardSignIn.TFADescription'} />
                 </p>
            </div> 
              


            <div className={styles.actions}>
              <input type="radio" id="enable-tfa" value='enabled' onChange={(e) => handleTwoFactorAuthenticationChange(e)} checked={tfaStatus === 'enabled'} />
              <label htmlFor="enable-tfa">
                <Translate id={'editStandardSignIn.enableTFALabel'} />
              </label>
            </div>

            <div className={styles.actions}>
              <input type="radio" id="disable-tfa" value='disabled' onChange={(e) => handleTwoFactorAuthenticationChange(e)} checked={tfaStatus === 'disabled'} />
              <label htmlFor="disable-tfa">
                <Translate id={'editStandardSignIn.disableTFALabel'} />
              </label>
            </div>
        </section>
        <div className={styles.formActions}>
            <Button buttonType={ButtonType.SECONDARY} onClick={onCancel}>
                <Translate id='common.form.buttons.cancelBtn' />
            </Button>
            <SubmitButton
                buttonType={ButtonType.PRIMARY}
                onClick={onSubmit}
            >
                <Translate id='common.form.buttons.updateMfa' />
            </SubmitButton>
          </div>
        </Form>
        </div>
        </>)
}
export default connect(
    (state: IAppState): EditMfaStateProps => {
      return {
        tenant: state.tenants.items[0]
      }
    },
    { fetchTenantById, enableMfa }
  )(EditMfa)