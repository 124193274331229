import React, { ReactElement } from 'react'
import { Card, CardContainer } from '@digicert/dcone-common-ui'
import { IOrganization } from '../../../../reducers/Reducers.interfaces'
import { IStateProps, PropsType } from './Organizations.types'
import { connect } from 'react-redux'
import { IAppState } from '../../../../core/store/store'
import { translate } from '../../../../shared/helpers/utils'
import { BasePathConst } from '../../../../configs/Path.configs'
import { Translate } from 'react-localize-redux'

const OrganizationsNew = (
  {
    organizations,
    canManageOrganization
  }: PropsType
):ReactElement<IOrganization> => {
  const renderAddress = (org) => {
    if(org) {
      return (
        <>
          {org.address}{org.address2 ? ', ' + org.address2 : null} <br/>
          {org.city}, {org.state} {org.zip_code} {org.country}
        </>
      )
    }
    else {
      return <></>
    }
  }

  return (
    <CardContainer>
      {organizations.map((org, index) => {
        return (
          <Card
            link={canManageOrganization ? `${BasePathConst}/ca/organizations/${org.id}/edit` : undefined}
            key={index}
            headline={org.name}
            descriptionLabel={translate('common.form.address') as string}
            description={renderAddress(org)}
            additionalInfoLabel={translate('common.form.phone') as string}
            additionalInfo={org.phone}
          />
        )
      })}
    </CardContainer>
  )
}

export default connect(
  (state: IAppState): IStateProps => ({
    organizations: state.organizations.items,
    isFetching: state.organizations.isFetching
  })
)(OrganizationsNew)