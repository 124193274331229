import React, { useEffect, useState } from 'react'
import styles from './OptionalSignInSettings.module.scss'
import { Button, ButtonType, MultiSelect } from '@digicert/dcone-common-ui'
import { faPencil } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OptionalSignInSettingsType } from './OptionalSignInSettings.types'
import { Translate } from 'react-localize-redux'
import { translate } from '../../../shared/helpers/utils'
import cn from 'classnames'
import { connect } from 'react-redux'
import {
  addDomainsToAccount,
  fetchConfiguredDomains,
  updateDomains,
  validateDomains
} from '../../tenants/Tenants.actions'
import { IAccessScope } from 'reducers/Reducers.interfaces'

const OptionalSignInSettings = ( props: OptionalSignInSettingsType ) => {
  const [editing, setEditing] = useState(props.editMode)
  const [emailDomains, setEmailDomains] = useState<any>([]);
  const [existingDomainsPresent, setExistingDomainsPresent] = useState(false)
  const [existingDomains, setExistingDomains] = useState<any>([]);

  const [errorMessage, setErrorMessage] = useState('')

  const editEmailDomains = () => {
    setEditing(!editing)
    if(props.onEdit) {
      props.onEdit(!editing)
    }
  }

  const cancelEditingEmailDomains = () => {
    setEditing(false)
    setErrorMessage('')
    setEmailDomains(existingDomains)
    if(props.onEdit) {
      props.onEdit(false)
    }
  }

  const fetchDomains = () => {
    props.fetchConfiguredDomains(props.tenant?.id).then((response: any) => {
        setEmailDomains(response)
        setExistingDomains(response)
        if (response.length > 0) {
          setExistingDomainsPresent(true)
        } else {
          setExistingDomainsPresent(false)
       }
     })
  }

  const updateEmailDomains = () => {
    setEditing(false)
    if(props.onEdit) {
      props.onEdit(false)
    }

    if(!existingDomainsPresent) {
     props.addDomainsToAccount(props.tenant?.id, emailDomains).then((response: any) => {
        if(response) {
            return translate(response)
        }
        fetchDomains()

     })
    } else {
     props.updateDomains(props.tenant?.id, emailDomains).then((response: any) => {
        if(response) {
            return translate(response)
        }
        fetchDomains()


     })
    }


  }

  const validateEmailDomains = (): any => {
     if (errorMessage) {
        return translate(errorMessage)
     }
  }

  const emailDomainChange = (value: any): any => {
     setEmailDomains(value)
     setErrorMessage('')
     if(!existingDomainsPresent && value.length === 0) {
        setErrorMessage('optionalSignInSettings.errors.domainsRequired')
     }

     if(value && value.length > 0) {
         props.validateDomains(props.tenant?.id, value?.toString()).then((response: any) => {
            setErrorMessage(response)
         })
     }

  }



  useEffect(() => {
    fetchDomains()
  }, [])

  useEffect(() => {
    setEditing(props.editMode)
  }, [props.editMode])

  return (
    <div className={styles.optionalSignInSettings}>
      <h3 className={styles.heading}>
        <Translate id='optionalSignInSettings.headline'/>
      </h3>
      { (props.profile && props.profile.access_scope === IAccessScope.system) && (
            <button className={cn(styles.editIcon, editing && styles.disabled)} onClick={editEmailDomains} >
              <FontAwesomeIcon size='lg' icon={faPencil}/>
            </button>
         )
      }

      <p className={styles.description}>
        <Translate id='optionalSignInSettings.description'/>
      </p>

      <div className={styles.multiSelectContainer}>
        <MultiSelect
          allowClear
          label={translate('common.form.emailDomains') as string}
          mode="tags"
          onChange={emailDomainChange}
          size="xxl"
          value={emailDomains}
          defaultValue={emailDomains}
          disabled={!editing}
          validate={validateEmailDomains}
        />
      </div>

      {editing && (
        <div className={styles.actions}>
          <Button buttonType={ButtonType.SECONDARY} onClick={cancelEditingEmailDomains}>
            <Translate id='common.form.buttons.cancelBtn'/>
          </Button>
          <Button buttonType={ButtonType.PRIMARY} onClick={updateEmailDomains}  disabled = {((!existingDomainsPresent && emailDomains.length === 0) || (errorMessage !== undefined && errorMessage !== ""))} >
            <Translate id='common.form.buttons.updateEmailDomainBtn'/>
          </Button>
        </div>
      )}
    </div>
  )
}

export default connect(
   null,
   { fetchConfiguredDomains,  addDomainsToAccount, updateDomains, validateDomains}
 )(OptionalSignInSettings)