import { detect } from 'detect-browser'
import gte from 'semver/functions/gte'
import lt from 'semver/functions/lt'
import valid from 'semver/functions/valid'

import { invalidSemverError, outdatedVersionError, notSupportedOSError } from '../DesktopClient.constants'

export const checkMinimalVersion = (currentVersion: string = '', minimalVersion: string): any => {
  const preparedCurrentVersion = currentVersion.trim().split(' ')[0]
  const isValidSemver = valid(preparedCurrentVersion)

  if (!isValidSemver) {
    throw invalidSemverError
  }

  const isCurrentVersionOk = gte(preparedCurrentVersion, minimalVersion)

  if (!isCurrentVersionOk) {
    throw outdatedVersionError
  }
}

export const isLessThanRecommended = (currentVersion: string = '', recommendedVersion: string): boolean => {
  const preparedCurrentVersion = currentVersion.trim().split(' ')[0]

  return lt(preparedCurrentVersion, recommendedVersion)
}

const SUPPORTED_PLATFORMS = ['Mac', 'Windows']

export const isSupportedPlatform = () => {
  const browser = detect()
  return SUPPORTED_PLATFORMS.some(platform => {
    // OS can be like Android OS | Mac OS | Linux | Windows Mobile | Windows 3.11 | Windows 95...
    if (browser && browser.os) {
      return browser.os.includes('Mobile') ? false : browser.os.includes(platform)
    }
  })
}

export const throwIfNotSupportedPlatform = () => {
  if (!isSupportedPlatform()) {
    throw notSupportedOSError
  }
}
