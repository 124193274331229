import { get } from 'lodash-es'
import gte from 'semver/functions/gte'

import HTTPClient from '../HTTPClient'
import HTTPUrlHelper from 'shared/helpers/HTTPUrlHelper'
import { DTAVerisons, prodDefaultLicense, Environment, devDefaultlicense } from './dta.constants'
import { APIClient } from './DTAClient'

export const getDefaultLicenseByEnv = () => {
    return process.env.NODE_ENV === Environment.Production ? prodDefaultLicense : devDefaultlicense
  }

export const fetchDtaLicense = async () => {
  let dtaLicense
  try {
    const response = await HTTPClient.GET(HTTPUrlHelper.getDtaLicenseAPI())
    const DTAVersion = await APIClient.GetClientVersion()
    if (DTAVersion && gte(DTAVersion, DTAVerisons.supportsDynamicLicense) && response.data) {
      dtaLicense = get(response, 'data', getDefaultLicenseByEnv())
    } else {
      dtaLicense = prodDefaultLicense // hardcoded license
    }
  } catch (e) {
    dtaLicense = prodDefaultLicense
  }

  return dtaLicense
}