import { buildActionType } from 'core/utils/api'
import { IAction } from './ClientAuth.types'
import { IClientAuthCerts } from 'reducers/Reducers.interfaces'
import { ClientAuthActions } from './ClientAuth.constants'

const initialState: IClientAuthCerts = {
  items: [],
  total: 0,
  clientAuthCert: undefined
}

export default function clientAuthCerts(state: IClientAuthCerts = initialState, action: IAction) {
  switch (action.type) {
    // fetch API tokens
    case buildActionType(ClientAuthActions.FetchClientAuthCerts, 'request'):
      return { ...state }
    case buildActionType(ClientAuthActions.FetchClientAuthCerts, 'success'):
      return { ...state, items: action.payload.items, total: action.payload.total }
    case buildActionType(ClientAuthActions.FetchClientAuthCerts, 'failure'):
      return { ...state, items: [] }
    // Generate CLient Auth cert
    case buildActionType(ClientAuthActions.GenerateCertToken, 'request'):
      return { ...state }
    case buildActionType(ClientAuthActions.GenerateCertToken, 'success'):
      return { ...state, clientAuthCert: action.payload }
    case buildActionType(ClientAuthActions.GenerateCertToken, 'failure'):
      return { ...state, items: [], clientAuthCert: undefined }
    // Enable Client auth cert
    case buildActionType(ClientAuthActions.EnableClientAuthCert, 'request'):
      return { ...state }
    case buildActionType(ClientAuthActions.EnableClientAuthCert, 'success'):
      return { ...state, clientAuthCert: undefined }
    case buildActionType(ClientAuthActions.EnableClientAuthCert, 'failure'):
      return { ...state, items: [], clientAuthCert: undefined }
    default:
      return state
  }
}
