import { omitBy } from 'lodash-es'
import { FormAdapter } from 'core/utils/adapters'

export const SwitchersTemplateAdapter = new FormAdapter({}, 'switchersForm')

export function mapSwitchersValuesToId<T extends any>(
  switchers: Record<string, boolean>
  //@ts-ignore
): Pick<T, 'id'>[] {
  return Object.keys(
    omitBy(switchers, (value: boolean): boolean => !value)
    //@ts-ignore
  ).map((id: string): Pick<T, 'id'> => ({ id }))
}

export function mapIdsToSwitchers<T extends any>(
  entities: T[]
): Record<string, boolean> {
  return entities.reduce(
    (result: Record<string, boolean>, current: T): Record<string, boolean> => ({
      ...result,
      //@ts-ignore
      [current.id]: true,
    }),
    {}
  )
}
