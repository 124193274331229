import ActionsList from '../../../core/actions/Actions.list'
import { IFederation } from '../../../reducers/Reducers.interfaces'
import AccountSignInConstants from './AccountSignIn.constants'
import { IAction } from './AccountSignIn.types'

const initialState: IFederation = {
  id: '',
  name: '',
  status: '',
  type: '',
  standard_login: false,
  acs_url: '',
  service_provider_config: undefined,
  isLoading: false,
  account_id:'',
  identity_provider_config: undefined
}

export default function accountSignIn (state: IFederation = initialState, action: IAction): IFederation {
  switch (action.type) {
    // fetch ant sort tenants
    case AccountSignInConstants.CreateAccountSignInRequest:
      return { ...initialState, isLoading: true }
    case AccountSignInConstants.CreateAccountSignInSuccess:
      return {
        ...state,
        isLoading: false,
        id: action.payload.id,
        name: action.payload.name,
         status: action.payload.status,
         type: action.payload.type,
         standard_login: action.payload.standard_login,
         acs_url: action.payload.service_provider_config.acsUrl,
         service_provider_config: action.payload.service_provider_config,
         account_id:action.payload.account_id,
         identity_provider_config: action.payload.identity_provider_config
 
      }
    case AccountSignInConstants.CreateAccountSignInFailure:
      return { ...state, isLoading: false }

    case ActionsList.AuthLogout:
      return initialState

    default:
      return state
  }
}
