import React, { Component, ReactElement } from 'react'
import { Translate } from 'react-localize-redux'
import { InputNumber } from 'antd'
import { isString } from 'lodash-es'

import { IInputFieldProps } from './Fields.interfaces'
import styles from './Fields.module.scss'

export default class InputNumberField extends Component<IInputFieldProps, {}> {
  public render (): ReactElement {
    const {
      label,
      labelPosition,
      input,
      required,
      placeholder,
      meta: { touched, error },
      ...rest
    } = this.props
    const isInlineDirection: boolean = labelPosition === 'inline'
    const handleBlur = () => {
      input.onBlur(input.value)
    }

    return (
      <div className={styles.inputWrap} style={{ display: this.props.type === 'hidden' ? 'none' : 'block' }}>
        {label &&
          <label className={isInlineDirection ? styles.inlineLabel : styles.inputLabel}>
            {isString(label)
              ? <Translate id={label}/>
              : label.map(lb => <span key={lb}><Translate id={lb} />&nbsp;</span>)
            }{required ? <sup className={styles.required}>*</sup> : null}
          </label>
        }

        <Translate>
          {({ translate }) => (
            <InputNumber
              {...rest}
              {...input}
              value={input.value}
              placeholder={placeholder ? translate(placeholder) as string : ''}
              type='number'
              pattern='[0-9]*'
              onBlur={handleBlur}
              className={isInlineDirection ? styles.inlineInput : styles.input}
            />
          )}
        </Translate>

        {touched && (error &&
          <label className={isInlineDirection ? styles.inlineErrorLabel : styles.errorLabel}><Translate id={error} /></label>
        )}
      </div>
    )
  }
}
