export const InitializationErrorCode = 20404
export enum KeyStoreLocation {
  Browser = 'browser',
  HardwareToken = 'hardware_token'
}


export const keystoreLocationList = [
{
    key: KeyStoreLocation.Browser,
label: 'profiles.keystore.browser'
},
{
key: KeyStoreLocation.HardwareToken,
label: 'profiles.keystore.hardwareToken'
}
]

export enum HardwareTokenType {
ETOKEN = 'safe_net_e_token',
YUBIKEY = 'yubikey_nfc_5',
ALL = 'all'
}

export enum InitializationErrorMessage {
  genericError = 'INITIALIZATION ERROR',
  notRunning = 'NOT RUNNING',
  disabled = 'DISABLED'
}

export enum KeyStoreID {
  FF_NSS = 'FF-NSS',
  PFX = 'PFX',
  ATHEIDP = 'ATHEIDP',
  SAFESIGN = 'SAFESIGN',
  YUBIKEY = 'YUBIKEY',
  ETOKEN = 'ETOKEN',
  MACOS = 'MACOS',
  CSK = 'CSK',
  EPASS = 'EPASS',
  WIN_ENH = 'WIN-ENH'
}

export const notHardwareKeyStoreIds = [
  KeyStoreID.FF_NSS,
  KeyStoreID.PFX,
  KeyStoreID.MACOS,
  KeyStoreID.CSK,
  KeyStoreID.WIN_ENH
]

export const KeyStoreFriendlyNameMap = {
  [KeyStoreID.WIN_ENH]: 'Windows keystore',
  [KeyStoreID.MACOS]: 'Keychain Access',
  [KeyStoreID.FF_NSS]: 'Firefox keystore'
}

export const NoExportNoPassword = {
  capiSecPolicy: {
    mask: '0x00000000'
  }
}

export const ExportableNoPassword = {
  capiSecPolicy: {
    mask: '0x00000001'
  }
}
export const keyStoreIdToHardwareTokenType = {
[KeyStoreID.ETOKEN]: 'safe_net_e_token',
[KeyStoreID.YUBIKEY]: 'yubikey_nfc_5',
[KeyStoreID.EPASS]: 'epass'

}
