import React, { ReactElement, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { connect, useDispatch } from 'react-redux'
import { get } from 'lodash-es'
import { Button, PageLevelBanner } from '@digicert/dcone-common-ui'
import { Spin } from 'antd'

import { KeyStoreFriendlyNameMap, KeyStoreID } from 'core/services/ddc/ddc.constants'
import { showNotification } from 'core/actions/Notifications.actions'
import { Token } from 'core/services/dta/DTAClient'
import Space from 'shared/layouts/space'
import { generateRandomId } from 'shared/helpers/utils'
import { DTAHWTokenSelector } from 'shared/components/dta/dta-hw-token-selector/DTAHWTokenSelector'
import { DTAInitializerComponent } from 'shared/components/dta/dta-initializer/DTAInitializer'
import {
  importCert
} from 'shared/helpers/dta.helpers'
import { getErrorMessage } from 'shared/helpers/ErrorMessage.helpers'
import { NotificationTypes } from 'reducers/Reducers.interfaces'

interface InstallCertificateProps {
  enrollmentLinkSecret?: string
  samlResponseId: string
  certBody?: string
  enrollmentId?: string
  serial_number?: string
  has_parent?: boolean
  predefinedKeyStoreId?: KeyStoreID
}

const successHandler = dispatch => {
  dispatch(
    showNotification({
      id: generateRandomId(),
      message: 'publicEnrollment.certificateInstalled',
      translateMessage: true,
      type: NotificationTypes.success
    })
  )
}

const DTAInstallCertificateComponent = (props: InstallCertificateProps): ReactElement => {
  const {
    certBody, // PEM format base64 encoded string
    predefinedKeyStoreId
  } = props

  const [isCertificateInstalled, setIsCertificateInstalled] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [keyStoreId, setKeyStoreId] = useState<KeyStoreID>()
  const [selectedToken, setSelectedToken] = useState<Token | null>()
  const [DTATokenKeyError, setDTATokenKeyError] = useState('')

  const keyStoreLocation = predefinedKeyStoreId
  const isHardwareToken = false
  //const isEscrow = get(profile, 'key_escrow_policy.key_escrow_enabled')
  const dispatch = useDispatch()

  const genericKeystoreName =  ''
  const isDCKS = keyStoreLocation?.toString().includes('DCSWKS')


  const installCertificate = async () => {
    setErrorMessage('')

    try {
      const certificateBody = certBody
      //let p12Password = password

      setLoading(true)

      const finalKeyStoreId = predefinedKeyStoreId

      setKeyStoreId(finalKeyStoreId)

      await importCert(finalKeyStoreId, certificateBody!)
      successHandler(dispatch)
      setIsCertificateInstalled(true)
    } catch (error: any) {
      setErrorMessage(getErrorMessage(error))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <h1 className='public-form-title'>
        {isCertificateInstalled ? <Translate id='publicEnrollment.certificateInstalled' /> : <Translate id='publicEnrollment.installCertificate' />}
      </h1>
      <DTAInitializerComponent needToInitialize={!predefinedKeyStoreId} keyStoreLocation={''}>
        <>
          {isCertificateInstalled && (
            <>
              <PageLevelBanner
                bannerType='success'
                title={<Translate id='publicEnrollment.certificateInstalledSuccessfully' />}
                description={
                  <Translate
                    id='publicEnrollment.keystoreCertificateInstalled'
                    data={{
                      keyStoreName:
                        KeyStoreFriendlyNameMap[keyStoreId?.split('-')[0] as KeyStoreID] || get(selectedToken, 'name', genericKeystoreName)
                    }}
                  />
                }
              />
            </>
          )}

          {isCertificateInstalled && isDCKS && (
            <div className='mt1'>
              <PageLevelBanner
                bannerType='info'
                description={
                  <Translate
                    id='dta.howToUseDSKS.steps'
                    data={{
                      content1: (
                        <a
                          href='https://docs.digicert.com/en/digicert-one/trust-lifecycle-manager/digicert-trust-assistant/digicert-trust-assistant-user-guide/digicert-software-keystore.html'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          User Guide
                        </a>
                      )
                    }}
                  />
                }
              />
            </div>
          )}

          <Space size='l' direction='v'>
            {errorMessage && (
              <PageLevelBanner
                bannerType='error'
                title={<Translate id='publicEnrollment.certificateInstallationFailed' />}
                description={errorMessage}
              />
            )}

            {isHardwareToken && !isCertificateInstalled && !predefinedKeyStoreId && (
              <Space key='dta-hw-token-section' size='l' direction='v' spaceLast={true}>
                {DTATokenKeyError && <PageLevelBanner description={DTATokenKeyError} bannerType='error' />}

                <DTAHWTokenSelector
                  key='hw-token-selector'
                  onChange={setSelectedToken}
                  onKeySizeError={setDTATokenKeyError}
                />
              </Space>
            )}
          </Space>

          {!isHardwareToken && !isCertificateInstalled && (
            <p className='mb1'>
              <Translate id={isDCKS ? 'dta.info.installHelpTextForDSKS' : 'dta.info.installHelpText'} />
            </p>
          )}

          {!isCertificateInstalled && (
            <Spin spinning={isLoading}>
              <Button
                onClick={installCertificate}
                disabled={isHardwareToken && !predefinedKeyStoreId && (!selectedToken || !!DTATokenKeyError)}
              >
                <Translate id='publicEnrollment.installCertificate' />
              </Button>
            </Spin>
          )}
        </>
      </DTAInitializerComponent>
    </>
  )
  }

export const DTAInstallCertificate = connect(null, {
})(DTAInstallCertificateComponent)
