import { addTranslationForLanguage, initialize } from 'react-localize-redux'
import { renderToStaticMarkup } from 'react-dom/server'
import ActionsList from './Actions.list'
import LanguageHelper from '../services/LanguageHelper'
import HTTPUrlHelper from '../../shared/helpers/HTTPUrlHelper'
import HTTPClient from '../services/HTTPClient'
import { onMissingTranslation } from 'core/utils/helpers'

export function initLocalization (locale: string) {
  return async (dispatch): Promise<void> => {
    dispatch({ type: ActionsList.FetchTranslationsRequest })

    const LocalizationAPI: string = HTTPUrlHelper.getLocalizationAPI(locale)
    const { data, status } = await HTTPClient.GET(LocalizationAPI)
    if (data && status === 200) {
      const defaultLanguage = LanguageHelper.getBrowserDefaultLanguage(data)
      let translationFile = LanguageHelper.extendUserLangByEnglish(data[1], data)
      if(data.length < 2 || data[0].code !== 'en' ) {
        translationFile = LanguageHelper.extendUserLangByEnglish(data[0], data)
      }
      const languages = data.map(lang => ({ code: lang.code.split('_')[0], name: lang.name }))
      dispatch({ type: ActionsList.FetchTranslationsSuccess })
      dispatch(initialize({
        languages,
        translation: translationFile.translations,
        options: {
          defaultLanguage: defaultLanguage.code,
          renderToStaticMarkup,
          onMissingTranslation
        }
      }))

      languages.forEach(lang => {
        dispatch(addTranslationForLanguage(
          data.find(dataLang => dataLang.name === lang.name).translations,
          lang.code
        ))
      })
    } else {
      dispatch({ type: ActionsList.FetchTranslationsFailure })
    }
  }
}
