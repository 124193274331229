import React, { ReactElement } from 'react'
import { Button, PageLevelBanner, PageLevelBannerType } from '@digicert/dcone-common-ui'
import { Translate } from 'react-localize-redux'

import { DTAClientErrors } from 'core/services/dta/DTAClientErrors'

import { getOSName } from '../../../helpers/utils'
import { marginTop } from '../../../helpers/styleUtils'
import { translate } from 'shared/helpers/Translation.helpers'
import { getDCKeyStoreToken } from '../../../helpers/dta.helpers'
import { notSupportedOSError, outdatedVersionError } from '../../../DesktopClient.constants'
import { ClientTool, FileDescription } from '../../../../secure-pages/resources/client-tools/ClientTools.types'
import {
  DTAToolMacName,
  DTAToolWindowsName,
  getClientToolsConfigs
} from '../../../../secure-pages/resources/client-tools/ClientTools.constants'
import { BasePathConst } from '../../../../configs/Path.configs'
import { DCKSNotAvailableError, DCKSUninitializedError } from './DTAInitializer.constants'

export const getDTAInternalInstallLink = () => {
  let DTAClientToolConfig: ClientTool
  const OSName = getOSName()

  if (OSName.includes('Mac')) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    DTAClientToolConfig = getClientToolsConfigs().find(config => config.name === DTAToolMacName)!
  } else {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    DTAClientToolConfig = getClientToolsConfigs().find(config => config.name === DTAToolWindowsName)!
  }

  const latestVersion = DTAClientToolConfig.versions[0]
  const latestVersionFileDescription: FileDescription = DTAClientToolConfig.files[latestVersion][0]

  const link = `${BasePathConst}/management-files/${latestVersionFileDescription.folder}/${latestVersionFileDescription.name}`

  return (
    <Button buttonType='primary'>
      <a href={link} className='buttonLink_' target='_blank' rel='noreferrer'>
        <Translate id='common.download' />
      </a>
    </Button>
  )
}

export const checkDCKSPrerequisites = async (keyStoreLocation: String) => {
  if (keyStoreLocation != 'DIGICERT_SOFTWARE') {
    return
  }

  const DCKS = await getDCKeyStoreToken()

  if (!DCKS || !DCKS.enable || !DCKS.available) {
    throw DCKSNotAvailableError
  }

  if (DCKS.status === 'UNINITIALIZED') {
    throw DCKSUninitializedError
  }
}

export const getDTAErrorBanner = (error: string): ReactElement => {
  let title
  let description
  let bannerType = PageLevelBannerType.WARNING

  if (error === DTAClientErrors.GENERIC_INITIALIZE_FAIL) {
    title = translate('dta.error.genericInitialize.title')
    description = translate('dta.error.genericInitialize.description')
  }

  if (error === DTAClientErrors.FAILED_TO_VERIFY_HOSTNAME) {
    title = translate('dta.error.verifyHostname.title')
    description = translate('dta.error.verifyHostname.description')
  }

  if (error === DTAClientErrors.NOT_RUNNING) {
    title = translate('dta.error.notRunning.title')
    description = translate('dta.error.notRunning.description')
  }

  if (error === DTAClientErrors.NOT_INSTALLED) {
    bannerType = PageLevelBannerType.INFO
    title = <b>{translate('dta.error.notInstalled.title')}</b>
    description = (
      <div>
        <span className='pre-line'>
          <br />
          {translate('dta.error.notInstalled.description')}
        </span>
        <div style={marginTop(8)}>{getDTAInternalInstallLink()}</div>
      </div>
    )
  }

  if (error === outdatedVersionError) {
    bannerType = PageLevelBannerType.INFO
    title = <b>{translate('dta.error.upgradeRequired.title')}</b>
    description = (
      <div>
        <span className='pre-line'>
          <br />
          {translate('dta.error.upgradeRequired.description')}
        </span>
        <div style={marginTop(8)}>{getDTAInternalInstallLink()}</div>
      </div>
    )
  }

  if (error === DCKSNotAvailableError) {
    const isWindows = getOSName().includes('Windows')

    title = translate('dta.error.DCKSNotAvailableError.title')
    description = translate(
      isWindows ? 'dta.error.DCKSNotAvailableError.windowsDescription' : 'dta.error.DCKSNotAvailableError.description'
    )
    description = (
      <>
        {description} {isWindows ? <b>{`Tokens > DigiCert Software KeyStore > Quick Actions > Register Provider`}</b> : ''}
      </>
    )
  }

  if (error === notSupportedOSError) {
    title = translate('dta.error.notSupportedOS.title', { os: getOSName() })
    description = translate('dta.error.notSupportedOS.description')
  }

  if (!title && !description) {
    title = translate('dta.error.genericError.title')
    description = `${translate('dta.error.genericError.description')}: ${error}`
  }

  return <PageLevelBanner bannerType={bannerType} title={title} description={description} />
}