import React, { Component, ReactElement } from 'react'
import { Translate } from 'react-localize-redux'
import { Input } from 'antd'
import { isString } from 'lodash-es'
import { IInputFieldProps } from './Fields.interfaces'
import styles from './Fields.module.scss'

const { TextArea } = Input

export default class InputField extends Component<IInputFieldProps, null> {
  render (): ReactElement {
    const {
      label,
      labelPosition,
      input,
      placeholder,
      required,
      meta: { touched, error },
      instruction,
      size,
      ...rest
    } = this.props
    const isInlineDirection: boolean = labelPosition === 'inline'

    return (
      <div className={styles.inputWrap} style={{ display: this.props.type === 'hidden' ? 'none' : 'block' }}>
        {label &&
          <label className={isInlineDirection ? styles.inlineLabel : styles.inputLabel}>
            {isString(label)
              ? <Translate id={label} />
              : label.map(lb => <span key={lb}><Translate id={lb} />&nbsp;</span>)
            }{required ? <sup className={styles.required}>*</sup> : null}
          </label>
        }

        { instruction && 
          <div className={styles.instruction}>
            <Translate id={instruction} />
          </div>
        }

        <Translate>
          {({ translate }) => (
            this.props.type === 'textarea'
              ? (
                <TextArea
                  {...input}
                  {...rest}
                  placeholder={placeholder ? translate(placeholder) as string : ''}
                  className={isInlineDirection ? styles.inlineInput : ''}
                />
              )
              : (
                <Input
                  {...input}
                  {...rest}
                  size='large'
                  placeholder={placeholder ? translate(placeholder) as string : ''}
                  className={isInlineDirection ? styles.inlineInput : ''}
                />
              )
          )}
        </Translate>

        {touched && ((error &&
          <label className={isInlineDirection ? styles.inlineErrorLabel : styles.errorLabel}>
            <Translate id={error} />
          </label>
        ))}
      </div>
    )
  }
}
