import { ActionCore } from '../../../core/utils/interfaces'
import { removeItemInArrayById } from '../../../reducers/Reducers.helpers'
import { NotificationsState } from './Notification.types'
import Constants from './Notifications.constants'

const initialState: NotificationsState = {
  items: []
}

export default function notifications(
  state: NotificationsState = initialState,
  action: ActionCore
): NotificationsState {
  switch (action.type) {
    case Constants.ShowNotification:
      return {
        ...state,
        items: [{ ...action.payload }, ...state.items]
      }

    case Constants.HideNotification:
      return {
        ...state,
        items: removeItemInArrayById(state.items, action.payload)
      }

    default:
      return state
  }
}
