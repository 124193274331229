import React, { ReactElement } from 'react'
import { last } from 'lodash-es'
import cn from 'classnames'

import { SpaceProps } from './Space.types'
import styles from './Space.module.scss'

export const Space = ({
  children,
  size = 'm',
  direction = 'h',
  spaceLast = false,
  inline = false,
  className,
  wrapperId,
  onClick
}: SpaceProps): ReactElement => {
  const list = (Array.isArray(children) ? children : [children]).filter(child => !!child)

  return (
    <div onClick={onClick} className={cn([className, styles[direction], inline && styles.inline])} id={wrapperId}>
      {list.map((child, i) => (
        <div
          key={(child && child.key) || `item-${i}`}
          {...(child !== last(list) || spaceLast ? { className: styles[`${direction}-${size}`] } : {})}
        >
          {child}
        </div>
      ))}
    </div>
  )
}
