import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faApple, faWindows, faLinux, faDocker } from '@fortawesome/free-brands-svg-icons'
import { ClientTool, ClientToolViewTypes } from './ClientTools.types'

export const DTAToolWindowsName = 'clientTools.cards.dta.nameWindows'
export const DTAToolMacName = 'clientTools.cards.dta.nameMac'

export const getClientToolsConfigs = (): ClientTool[] => {
  const sensorVersion = '3.8.58'
  const agentVersion = '3.0.6'

  return [
    {
      id: 1,
      arch: 'x64',
      name: 'clientTools.cards.ddc.nameWindows',
      os: 'windows',
      link: ClientToolViewTypes.DdcWindows,
      short_description: 'clientTools.cards.ddc.description',
      versions: ['3.3.1', '3.3.0'],
      files: {
        '3.3.1': [
          {
            name: 'DigiCert-Desktop-Installer-3.3.1.exe',
            folder: 'client-tools',
            label: 'DDC-3.3.1.exe',
            sha1: '40e788a3106d92be27e395f6e9268870439a4014',
            sha256: 'c855ce5d403e89801dd4b64af5bc828846276c1873b3bb93a1db7724a951d4ac'
          },
          {
            name: 'DigiCert-Desktop-Installer-3.3.1.msi',
            folder: 'client-tools',
            label: 'DDC-3.3.1.msi',
            sha1: '840029a7dc70425d55fab8069a6097e3bb59cb1b',
            sha256: '07c045aa0b3548b8b330f7e6d1c7a94ab7b8bc1fb0716596b832e785404a25d7'
          }
        ],
        '3.3.0': [
          {
            name: 'DigiCert-Desktop-Installer-3.3.0.exe',
            folder: 'client-tools',
            label: 'DDC-3.3.0.exe',
            sha1: 'b82386319cc2491b18f6401c6ee9e395f6f65d9a',
            sha256: '9f13e1288505efaf77a2dd8cffa9c934a6f8cf306892addba503878d5f06c717'
          }
        ]
      }
    },
    {
      id: 2,
      arch: 'x64',
      name: 'clientTools.cards.ddc.nameMac',
      os: 'mac',
      link: ClientToolViewTypes.DdcMac,
      short_description: 'clientTools.cards.ddc.description',
      versions: ['3.3.1', '3.3.0'],
      files: {
        '3.3.1': [
          {
            name: 'DigiCert-Desktop-Installer-3.3.1.pkg',
            folder: 'client-tools',
            label: 'DDC-3.3.1.pkg',
            sha1: '20cdaec7f3dbeb5855e98fef6a1f144ab2c7ef06',
            sha256: '6a8d1b02994699ab1f9b9e2d7c5924f203916c96c9fec87deac01f1fffcc1a3b'
          }
        ],
        '3.3.0': [
          {
            name: 'DigiCert-Desktop-Installer-3.3.0.pkg',
            folder: 'client-tools',
            label: 'DDC-3.3.0.pkg',
            sha1: 'a89fd2d781f070d4faf6d806b3e73215cc92ab5b',
            sha256: '646d49d6ecde734435bbb1980a1758d2cdec06e176fb8c9fd1009d893bcdb344'
          }
        ]
      }
    },
    {
      id: 3,
      arch: 'x64',
      name: 'clientTools.cards.aes.name',
      os: 'windows',
      link: ClientToolViewTypes.AesWindows,
      short_description: 'clientTools.cards.aes.description',
      versions: ['2.23.1.0'],
      hideVersion: true,
      files: {
        '2.23.1.0': [
          {
            name: 'AESetup-2.23.1.0.exe',
            folder: 'aes',
            label: 'AESetup-2.23.1.0.exe',
            sha1: 'c6551687e39fd369d8999b86b54453e51015b88e',
            sha256: 'b2b8ada9999b5a4f8f545dfd6ef31fda2a82cea4c0ad9b1b9dbbe413c9489917'
          }
        ]
      }
    },
    // DTA Client
    {
      id: 4,
      arch: 'x64',
      name: DTAToolWindowsName,
      os: 'windows',
      link: ClientToolViewTypes.DtaWindows,
      short_description: 'clientTools.cards.dta.descriptionWindows',
      versions: ['1.1.4'],
      hideVersion: true,
      files: {
        '1.1.4': [
          {
            name: 'DigiCert-Trust-Assistant-1.1.4-win-x64.exe',
            folder: 'client-tools',
            label: 'DTA-1.1.4.exe',
            sha1: 'a51b02bf45cd24f718765c5fdc00851ebf4fb960',
            sha256: 'bb437a0f94b90b9c31bac71cc0b681cfdb26643ba9974c20f1e7ee0c00511c37'
          }
        ]
      }
    },
    {
      id: 5,
      arch: 'x64',
      name: DTAToolMacName,
      os: 'mac',
      link: ClientToolViewTypes.DtaMac,
      short_description: 'clientTools.cards.dta.description',
      versions: ['1.1.4'],
      hideVersion: true,
      files: {
        '1.1.4': [
          {
            name: 'DigiCert-Trust-Assistant-1.1.4-mac-universal.pkg',
            folder: 'client-tools',
            label: 'DTA-1.1.4.pkg',
            sha1: '389963eb35cfa1dd9483221bc8e728a4b21339d9',
            sha256: 'd2b091b453e3021229f982bc69c97bcc791ebfe8b75690680b40579a9ca4dc4a'
          }
        ]
      }
    },
    {
      id: 6,
      arch: 'x64',
      name: 'clientTools.cards.sensor.nameDocker',
      os: 'docker',
      link: ClientToolViewTypes.SensorDocker,
      short_description: 'clientTools.cards.sensor.description',
      versions: [sensorVersion],
      hideVersion: true,
      isSensorCard: true,
      files: {
        [sensorVersion]: [
          {
            name: 'AESetup-2.22.2.0.exe',
            folder: 'aes',
            label: 'AESetup-2.22.2.0.exe',
            sha1: '67cc573059e2ca31f1e8f5729f2afe3b3f0668a0',
            sha256: '4c773471880f6b7ba14b2c266d58eb1c50b639c45e9bbaeb36637859c91bf195'
          }
        ]
      }
    },
    {
      id: 7,
      arch: 'x64',
      name: 'clientTools.cards.sensor.nameLinux',
      os: 'linux',
      link: ClientToolViewTypes.SensorLinux,
      short_description: 'clientTools.cards.sensor.description',
      versions: [sensorVersion],
      hideVersion: true,
      isSensorCard: true,
      files: {
        [sensorVersion]: [
          {
            name: '',
            folder: '',
            label: '',
            sha1: '',
            sha256: ''
          }
        ]
      }
    },
    {
      id: 8,
      arch: 'x64',
      name: 'clientTools.cards.sensor.nameWindows',
      os: 'windows',
      link: ClientToolViewTypes.SensorWindows,
      short_description: 'clientTools.cards.sensor.description',
      versions: [sensorVersion],
      hideVersion: true,
      isSensorCard: true,
      files: {
        [sensorVersion]: [
          {
            name: '',
            folder: '',
            label: '',
            sha1: '',
            sha256: ''
          }
        ]
      }
    },
    {
      id: 9,
      arch: 'x64',
      name: 'clientTools.cards.agents.windows.name',
      os: 'windows',
      link: ClientToolViewTypes.AgentWindows,
      short_description: 'clientTools.cards.agents.windows.description',
      versions: [agentVersion],
      hideVersion: true,
      isAgentCard: true,
      files: {
        [agentVersion]: [
          {
            name: 'AESetup-2.22.2.0.exe',
            folder: 'aes',
            label: 'AESetup-2.22.2.0.exe',
            sha1: '67cc573059e2ca31f1e8f5729f2afe3b3f0668a0',
            sha256: '4c773471880f6b7ba14b2c266d58eb1c50b639c45e9bbaeb36637859c91bf195'
          }
        ]
      }
    },
    {
      id: 10,
      arch: 'x64',
      name: 'clientTools.cards.agents.linux.name',
      os: 'linux',
      link: ClientToolViewTypes.AgentLinux,
      short_description: 'clientTools.cards.agents.linux.description',
      versions: [agentVersion],
      hideVersion: true,
      isAgentCard: true,
      files: {
        [agentVersion]: [
          {
            name: 'AESetup-2.22.2.0.exe',
            folder: 'aes',
            label: 'AESetup-2.22.2.0.exe',
            sha1: '67cc573059e2ca31f1e8f5729f2afe3b3f0668a0',
            sha256: '4c773471880f6b7ba14b2c266d58eb1c50b639c45e9bbaeb36637859c91bf195'
          }
        ]
      }
    },
    {
      id: 11,
      arch: 'x64',
      name: 'clientTools.cards.mcars.name',
      os: 'windows',
      link: ClientToolViewTypes.Mcars,
      short_description: 'clientTools.cards.mcars.description',
      versions: ['10'],
      hideVersion: true,
      files: {
        '10': [
          {
            name: 'binaries',
            folder: 'mcars',
            label: 'binaries',
            sha1: '67cc573059e2ca31f1e8f5729f2afe3b3f0668a0',
            sha256: '4c773471880f6b7ba14b2c266d58eb1c50b639c45e9bbaeb36637859c91bf195'
          }
        ]
      }
    }
  ] as ClientTool[]
}

export enum SelectOsOptions {
  All = 'all',
  Windows = 'windows',
  Mac = 'mac',
  Linux = 'linux',
  Docker = 'docker'
}

export const operationSystems = [
  {
    key: SelectOsOptions.All,
    name: 'All'
  },
  {
    key: SelectOsOptions.Windows,
    name: 'Windows'
  },
  {
    key: SelectOsOptions.Mac,
    name: 'Mac'
  },
  {
    key: SelectOsOptions.Linux,
    name: 'Linux'
  },
  {
    key: SelectOsOptions.Docker,
    name: 'Docker'
  }
]

export const osNameDict: { [k: string]: string } = {
  mac: 'Mac',
  windows: 'Windows',
  docker: 'Docker',
  linux: 'Linux'
}
export const osIconDict: { [k: string]: IconProp } = {
  mac: faApple,
  windows: faWindows,
  linux: faLinux,
  docker: faDocker
}