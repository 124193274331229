import { IContacts } from './Reducers.interfaces'
import { IAction } from '../core/actions/Actions.interfaces'
import ActionsList from '../core/actions/Actions.list'
import { buildActionType } from '../core/utils/api'

const initialState: IContacts = {
  isFetching: false,
  isEditing: false,
  isCreating: false,
  data: {}
}

export default function contacts (state: IContacts = initialState, action: IAction) {
  switch (action.type) {
    // fetch organization contacts
    case buildActionType(ActionsList.FetchContacts):
      return { ...state, isFetching: true }
    case buildActionType(ActionsList.FetchContacts, 'success'):
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [action.payload.organizationId]: action.payload.data
        }
      }
    case buildActionType(ActionsList.FetchContacts, 'failure'):
      return { ...state, isFetching: false }

    // create new contact
    case buildActionType(ActionsList.CreateContact):
      return { ...state, isCreating: true }
    case buildActionType(ActionsList.CreateContact, 'success'):
    case buildActionType(ActionsList.CreateContact, 'failure'):
      return { ...state, isCreating: false }

    // edit single contact
    case ActionsList.EditContactRequest:
      return { ...state, isEditing: true }
    case ActionsList.EditContactSuccess:
    case ActionsList.EditContactFailure:
      return { ...state, isEditing: false }

    case ActionsList.AuthLogout:
      return initialState

    default:
      return state
  }
}
