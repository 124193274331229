import React from 'react'
import NoPermissionsPage from '../../shared/components/no-permissions-page'
import { IPermissions } from '../selectors/Profile.selectors'
import AdminProfile from 'secure-pages/profile'

export const withPermissionsHOC = (Component: any, rules: string[]): React.ComponentType =>
  class WithPermissionsHOC extends React.Component<any, any> {
    render () {
      const { permissions, ...rest } = this.props
      return this.checkPermissions(rules, permissions)
        // added permissions to Component for compatibility
        ? <Component {...rest} permissions={permissions} />
        : rules.find(rule =>  rule === 'canViewAccount') ? 
        //@ts-ignore
        <AdminProfile
        /> : <NoPermissionsPage />
    }

    private checkPermissions (rules: string[], permissions: IPermissions): boolean {
      return rules.reduce((result: boolean, permission: string) => (
        result && Object.prototype.hasOwnProperty.call(permissions, permission) && permissions[permission]
      ), true)
    }
  }
