import { observableDiff, diff, applyChange } from 'deep-diff'

interface SupportedLanguageModel {
  name: string
  code: string
  translations: any
}

const langCodeRegexp: RegExp = /[-_]/

const LanguageHelper = {
  get userLanguageList (): string[] {
    if (navigator['userLanguage']) {
      return [navigator['userLanguage']]
    }
    if (!navigator.languages || navigator.languages.length === 0) {
      return [navigator.language]
    } else {
      return navigator.languages as string[]
    }
  },

  getBrowserDefaultLanguage: (supportedLanguages: SupportedLanguageModel[]): SupportedLanguageModel => {
    const mainLanguages: SupportedLanguageModel[] = []
    const languagesWithSubLocales: SupportedLanguageModel[] = []

    supportedLanguages.forEach(language => {
      const langCodeParts = language.code.split(langCodeRegexp)
      const mainLocale = langCodeParts[0]

      // If no sub-locale
      if (langCodeParts.length === 1) {
        // Then it is a main language
        mainLanguages.push(language)
      } else {
        // Otherwise
        // If the code contains a sub-locale
        // But no other similar languages
        if (supportedLanguages.filter(l => l.code.startsWith(mainLocale)).length === 1) {
          // It should become a main language
          mainLanguages.push(language)
        } else {
          // If the array does contain similar languages
          // Let's not treat it specially
          languagesWithSubLocales.push(language)
        }
      }
    })

    // Let's find a match for every browser-defined language
    // And treat global and sub-localed languages accordingly
    const languageMatches = LanguageHelper.userLanguageList
      .map(userLanguage =>
        languagesWithSubLocales.find(l => l.code === userLanguage) ||
          mainLanguages.find(l => l.code.substr(0, 2) === userLanguage.substr(0, 2)))
      .filter(language => language != null)

    // If at least something matched
    if (languageMatches.length !== 0) {
      // Then we are good with the first match
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return languageMatches[0]!
    } else {
      // Otherwise let's just rely on English as default
      return LanguageHelper.getDefaultTranslation(mainLanguages)
    }
  },

  getDefaultTranslation: (supportedLanguages: SupportedLanguageModel[]): SupportedLanguageModel => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return supportedLanguages.find(lang => lang.code === 'en')!
  },

  extendUserLangByEnglish: (userLanguage: SupportedLanguageModel, supportedLanguages: SupportedLanguageModel[]) => {
    const defaultEnglishTranslation = LanguageHelper.getDefaultTranslation(supportedLanguages)
    if (defaultEnglishTranslation && userLanguage) {
      // We extend user translation by english in case when some translation key will be missing in translation file;
      const differnences = diff(defaultEnglishTranslation.translations, userLanguage.translations)
      observableDiff(defaultEnglishTranslation.translations, userLanguage.translations, function (d) {
        if(d.kind === 'D') {
          const diff = {
            rhs: d.lhs,
            path: d.path,
            kind: 'N'
          }
          applyChange(userLanguage.translations, differnences, diff)
        }
      })
    }

    return userLanguage
  }
}

export default LanguageHelper
