enum TenantConstants {
  FetchTenantsRequest = 'FETCH_TENANTS_REQUEST',
  FetchTenantsSuccess = 'FETCH_TENANTS_SUCCESS',
  FetchTenantsFailure = 'FETCH_TENANTS_FAILURE',
  FetchTenants = 'FETCH_TENANTS',
  fetchExpiringAccounts = 'FETCH_EXPIRING_ACCOUNTS',
  FetchTenantRequest = 'FETCH_TENANT_REQUEST',
  FetchTenantSuccess = 'FETCH_TENANT_SUCCESS',
  FetchTenantFailure = 'FETCH_TENANT_FAILURE',
  CreateTenantRequest = 'CREATE_TENANT_REQUEST',
  CreateTenantSuccess = 'CREATE_TENANT_SUCCESS',
  CreateTenantFailure = 'CREATE_TENANT_FAILURE',
  EditTenantRequest = 'EDIT_TENANT_REQUEST',
  EditTenantSuccess = 'EDIT_TENANT_SUCCESS',
  EditTenantFailure = 'EDIT_TENANT_FAILURE',
  EditStdSigninRequest = 'EDIT_STD_SIGNIN_REQUEST',
  EditStdSigninSuccess = 'EDIT_STD_SIGNIN_SUCCESS',
  EditStdSigninFailure = 'EDIT_STD_SIGNIN_FAILURE',
  DeleteTenant = 'DELETE_TENANTS',
  UpdateMfa = 'UPDATE_MFA'
}

export default TenantConstants
