import React, { Component, ReactElement, Suspense } from 'react'
import { LocalizeContextProps, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import RefreshToken from 'shared/components/refresh-token/RefreshToken'
import { initLocalization } from 'core/actions/Localization.actions'
import { IActionCreatorAsync } from 'core/actions/Actions.interfaces'
import withCheckingSetupLicense from 'core/hoc/withSetupLicense'
import AppRoutes from './AppRoutes'
import 'styles/index.scss'
import { DCONE_LOCALE_COOKIE_NAME } from 'core/utils/constants'
import Cookies from 'js-cookie'
import { Spinner } from '@digicert/dcone-common-ui'
import Notifications from './shared/components/notifications/Notifications'

interface IProps extends LocalizeContextProps {
  initLocalization: IActionCreatorAsync
}

class App extends Component<IProps, any> {
  public componentDidMount (): void {
    if (Cookies.get(DCONE_LOCALE_COOKIE_NAME)) {
      this.props.initLocalization(Cookies.get(DCONE_LOCALE_COOKIE_NAME))
    } else {
      this.props.initLocalization('en_US')
    }
  }

  public render (): ReactElement {
    const { languages } = this.props
    const Fallback: ReactElement = <div className='initial-spin-wrap'>
      <Spinner/>
    </div>

    if (!languages || !languages.length) return Fallback

    return (
      <Suspense fallback={Fallback}>
        <RefreshToken />
        <AppRoutes />
        <Notifications />
      </Suspense>
    )
  }
}

export default connect(
  null,
  { initLocalization }
)(withLocalize(withCheckingSetupLicense(App)))
