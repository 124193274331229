import { IAction } from "core/actions/Actions.interfaces";
import ActionsList from "core/actions/Actions.list";
import { showNotification } from "core/actions/Notifications.actions";
import HTTPClient from "core/services/HTTPClient";
import { actionCoreApi } from "core/utils/api";
import { actionErrorNotifier } from "core/utils/helpers";
import { ActionType } from "core/utils/interfaces";
import { NotificationTypes } from "reducers/Reducers.interfaces";
import { ThunkDispatch } from "redux-thunk";
import HTTPUrlHelper from "shared/helpers/HTTPUrlHelper";
import { generateRandomId } from "shared/helpers/utils";

export const getFeatureByAccount = (id: string): ActionType =>
  actionErrorNotifier(actionCoreApi(
    ActionsList.FetchFeatureByAccount,
    HTTPUrlHelper.getFeatureByAccountAPI(id)
  ))

  export function enableFeature (appId: string, formData: Array<any>, appCode: string, accountId: string) {
    return async (dispatch: ThunkDispatch<any, any, IAction>): Promise<void> => {
      dispatch({ type: ActionsList.EnableFeatureForAccount })
      const URL: string = HTTPUrlHelper.enableFeatureForAccountAPI(appId, accountId)
      const { data, status } = await HTTPClient.PUT(URL, formData)

      if (status === 200) {
        dispatch(showNotification({
          id: generateRandomId(),
          message: `features.success.${appCode}`,
          type: NotificationTypes.success
        }))
      }

      if (data.error) {
        dispatch(showNotification({
          id: generateRandomId(),
          message: data.message,
          type: NotificationTypes.error
        }))
      }
    }
  }