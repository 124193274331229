import { Component, ReactNode } from 'react'
import { RouteComponentProps } from 'react-router'
import { BasePathConst } from 'configs/Path.configs'

export default class Access extends Component<RouteComponentProps, {}> {
  public componentDidMount (): void {
    this.props.history.push(`${BasePathConst}/access/administrators`)
  }

  public render (): ReactNode {
    return null
  }
}
