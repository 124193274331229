import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { fetchProfile } from '../../core/actions/Profile.actions'
import { IActionCreatorAsync } from '../../core/actions/Actions.interfaces'
import { IUser } from '../../reducers/Reducers.interfaces'
import { IAppState } from '../../core/store/store'
import { RouterConfig } from 'core/utils/constants'
import history from 'core/history/history'
import { LocalizeContextProps, withLocalize } from 'react-localize-redux'

interface IProps extends RouteComponentProps, LocalizeContextProps {
  fetchProfile: IActionCreatorAsync
  profile?: IUser
}

const Profile = (props: IProps) => {
  const {fetchProfile, profile, setActiveLanguage} = props
useEffect(() => {
  fetchProfile().then((response: any) => {
    if(response) {
      setActiveLanguage(response.locale.split('_')[0])
      history.push(RouterConfig.administrators.details(response.id))
    }
  })
},[fetchProfile, setActiveLanguage])
  return (
    <>
    </>
  )
}

export default connect(
  (state: IAppState) => {
    return {
      profile: state.profile.data
    }
  },
  { fetchProfile }
)(withLocalize(Profile))
