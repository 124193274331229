import React, { ReactElement, useEffect, useState } from 'react'
import { IAppState } from 'core/store/store'
import { EditSignInProps, EditStandardSignInOwnProps, EditStandardSignInStateProps } from './EditSignIn.types'
import { connect } from 'react-redux'
import styles from '../AccountSignIn.module.scss'
import { Translate } from 'react-localize-redux'
import history from 'core/history/history'
import { RouterConfig } from 'core/utils/constants'
import { Spin } from 'antd'
import { editStandardSignIn, fetchTenantById } from 'secure-pages/tenants/Tenants.actions'
import layoutOverrides from 'styles/layoutOverrides.module.scss'
import { Button, ButtonType, Form, FormGroup, IncontextBanner, SubmitButton } from '@digicert/dcone-common-ui'
import { disableSigninMethod } from '../AccountSignIn.actions'

const EditStandardSignIn = (props: EditSignInProps): ReactElement => {
  const [status, setStatus] = useState('enabled')
  const [tfaStatus, setTfaStatus] = useState('disabled')
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tenant, setTenant] = useState(props.tenant)
  useEffect(() => {
    setLoading(true)
    props.fetchTenantById(props.match.params.id).then((tenant: any) => {
      if (tenant) {
        const standardSignInMethod = tenant.sign_in_methods.find(item => item.signInMethod === 'standard')
        const standardSignInstatus = standardSignInMethod ? standardSignInMethod.status : 'disabled'
        setShowError(disableSigninMethod(tenant, 'standard'))
        setStatus(standardSignInstatus)
        if (standardSignInMethod) {
          setTfaStatus(standardSignInMethod.mfaStatus === 'enabled' ? 'enabled' : 'disabled')
        }
        setTenant(tenant)
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [fetchTenantById])

  const onSubmit = async (e): Promise<void> => {
    e.preventDefault()
    const { match } = props
    if (tenant) {
      const standardSignInMethod = tenant.sign_in_methods.find(item => item.signInMethod === 'standard')

      if (standardSignInMethod) {
        standardSignInMethod.mfaStatus = tfaStatus
      }
    }
    const payload = {
      name: tenant ? tenant.name : '',
      'service_period': {
        from: tenant ? tenant.service_period.from : '',
        to: tenant ? tenant.service_period.to : ''
      },
      sign_in_methods: tenant ? tenant.sign_in_methods : null,
      standard_login: status === 'enabled'
    }
    await props.editStandardSignIn(match.params.id, payload)
    tenant?.system_account ? history.push(RouterConfig.platformSettings.settings) : history.push(RouterConfig.accounts.details(match.params.id))
  }

  const onClick = (e): void => {
    e.preventDefault()
    tenant?.system_account ? history.push(RouterConfig.platformSettings.settings) : history.push(RouterConfig.accounts.details(props.match.params.id))
  }

  const onSiteChanged = (): void => {
    if (status === 'enabled') {
      setStatus('disabled')
    } else {
      setStatus('enabled')
    }
  }
  return (
    <>
      {!loading &&
        <div className={layoutOverrides.secureLayoutWidthAdjustment}>
          <Form style={{ margin: 0, paddingLeft: '12px' }}>
          <header className={styles.editSignInHeader}>
            <h1>
                <Translate id={'editStandardSignIn.title'}/>
            </h1>
            </header> 
          <div className={styles.warningText}>
            <div id='error' className={showError ? styles.display : styles.hide}>
              <IncontextBanner
                bannerType="note"
                description={<Translate id='editStandardSignIn.warningMsg'
                />
            }
            />
            </div>
          </div>

          <section className={styles.authSection}>
            <div className={styles.info}>
              <h4>
                <Translate id={'editStandardSignIn.SSIHeadline'} />
              </h4>
            </div>

            <div className={styles.actions}>
              <input type="radio" id="enable-standard-sign-in" name='enabled' value='enabled' checked={status === 'enabled'} onChange={onSiteChanged} />
              <label htmlFor="enable-standard-sign-in">
                <Translate id={'editStandardSignIn.enable'} />
              </label>
            </div>

            <div className={styles.actions}>
              <input type="radio" id="disable-standard-sign-in" name='enabled' value='disabled' checked={status === 'disabled'} onChange={onSiteChanged} disabled={showError} />
              <label htmlFor="disable-standard-sign-in">
                <Translate id={'editStandardSignIn.disable'} />
              </label>
            </div>
          </section>
          <FormGroup>
            <Button buttonType={ButtonType.SECONDARY} onClick={onClick}>
                <Translate id='common.form.buttons.cancelBtn' />
            </Button>
            <SubmitButton
                buttonType={ButtonType.PRIMARY}
                onClick={onSubmit}
            >
                <Translate id='common.form.buttons.saveBtn' />
            </SubmitButton>
          </FormGroup>
          </Form>
        </div>
      }
      {loading &&
        <div className='spin-wrap'><Spin /></div>
      }
    </>
  )
}

export default connect(
  (state: IAppState, ownProps: EditStandardSignInOwnProps): EditStandardSignInStateProps => {
    return {
      tenant: state.tenants.items[0],
      accessScope: state.profile.data?.access_scope,
      currentUserId: state.profile.data !== undefined ?  state.profile.data.id : ''
    }
  },
  { fetchTenantById, editStandardSignIn }
)(EditStandardSignIn)
