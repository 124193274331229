import ActionsList from './Actions.list'
import { NotificationProps } from '../../shared/components/notifications/Notification.types'
import { IAction } from './Actions.interfaces'

export function showNotification (notification: NotificationProps) {
  return {
    type: ActionsList.ShowNotification,
    payload: notification
  }
}

export function hideNotification (id: string | number): IAction {
  return {
    type: ActionsList.HideNotification,
    payload: id
  }
}
