import React, { ReactNode } from 'react'
import { Translate } from 'react-localize-redux'
import { ITenant } from 'reducers/Reducers.interfaces'
import { BasePathConst } from 'configs/Path.configs'
import { Card, CardContainer, SizeTypes, Tag, TagType } from '@digicert/dcone-common-ui'
import { faPencil } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translate } from "../../../shared/helpers/utils"
import styles from './SignInSettings.module.scss'
import history from '../../../core/history/history'

export enum Sections {
  Organizations = 'organizations',
  License = 'license',
  AccountSignIn = 'account_sign_in',
  DocumentSigningClients = 'document_signing_clients'
}

export interface signInProps {
  tenant: ITenant,
  canEdit : boolean,
  accountDetailsPage: boolean,
  fromSettings?: boolean
}

const renderSignInCards = (tenant: ITenant, accountDetailsPage: boolean, fromPlatformSettings: boolean, canEdit: boolean): ReactNode => {
  const standardSignInMethod = tenant.sign_in_methods.find(item => item.signInMethod === 'standard')
  const ssoMethodSaml = tenant.sign_in_methods.find(item => item.signInMethod === 'singleSignOnSaml')
  const ssoMethodOidc = tenant.sign_in_methods.find(item => item.signInMethod === 'singleSignOnOidc')
  const clientAuthCertLoginEnabled = tenant && tenant.sign_in_methods[0].clientAuthCertLoginEnabled
  //mfa can be edited only for self server accounts and sso only accounts
  const canEditMfa = tenant.can_edit_mfa

  return (
    <>
      <Card
        headline={translate('common.form.standard') as string}
        link={canEdit ? `${BasePathConst}${fromPlatformSettings ? '/settings/' : '/accounts/'}${tenant.id}/editStandardSignIn` : undefined}
        description={translate('common.form.standardDesc') as string}
        showStatusContent
        status={<Tag type={standardSignInMethod?.status == 'enabled' ? TagType.ACTIVE : TagType.INACTIVE}><Translate id={`common.statuses.${standardSignInMethod?.status}`} /></Tag>}
        statusLabel={translate('common.status') as string}
        size={SizeTypes.STANDARD}
      />

      <Card
        headline={translate('singleSignOn.sso') as string}
        link={canEdit ?  `${BasePathConst}${fromPlatformSettings ? '/settings/' : '/accounts/'}${tenant.id}/createAccountSignIn` : undefined}
        description={translate('singleSignOn.ssoDesc') as string}
        showStatusContent
        status={<Tag type={ssoMethodSaml !== undefined && ssoMethodSaml?.status == 'enabled' ? TagType.ACTIVE : TagType.INACTIVE}><Translate id={ ssoMethodSaml !== undefined && ssoMethodSaml?.status == 'enabled' ? 'common.statuses.enabled' : 'common.statuses.disabled'} /> </Tag>}
        statusLabel={translate('common.status') as string}
        size={SizeTypes.STANDARD}
      />

      <Card
        headline={translate('common.form.signInMethodTFA') as string}
        link={canEdit && canEditMfa ?  `${BasePathConst}${fromPlatformSettings ? '/settings/' : '/accounts/'}${tenant.id}/editMfa` : undefined}
        description={translate('singleSignOn.mfaDesc') as string}
        showStatusContent
        status={<Tag type={standardSignInMethod?.mfaStatus == 'enabled' ? TagType.ACTIVE : TagType.INACTIVE}><Translate id={`common.statuses.${standardSignInMethod?.mfaStatus}`} /></Tag>}
        statusLabel={translate('common.status') as string}
        size={SizeTypes.STANDARD}
      />

      {accountDetailsPage &&
        <Card
          headline={translate('clientAuthCertLoginUpdate.cardHeadline') as string}
          link={canEdit ?  `${BasePathConst}${fromPlatformSettings ? '/settings/' : '/accounts/'}${tenant.id}/updateClientAuthCertLogin` : undefined}
          description={translate('clientAuthCertLoginUpdate.cardDescription') as string}
          showStatusContent
          statusLabel={translate('common.status') as string}
          status={
            <Tag type={clientAuthCertLoginEnabled ? TagType.ACTIVE : TagType.INACTIVE}>
              <Translate id={ clientAuthCertLoginEnabled !== undefined && clientAuthCertLoginEnabled ? 'common.statuses.enabled' : 'common.statuses.disabled'} />
            </Tag>
          }
          size={SizeTypes.STANDARD}
        />
      }

      <Card
        headline={translate('common.form.singleSignOnOidc') as string}
        link={canEdit ?  `${BasePathConst}${fromPlatformSettings ? '/settings/' : '/accounts/'}${tenant.id}/updateOpenidConnectIntegration` : undefined}
        description={translate('singleSignOn.openIdDesc') as string}
        showStatusContent
        status={<Tag type={ssoMethodOidc !== undefined && ssoMethodOidc?.status == 'enabled' ? TagType.ACTIVE : TagType.INACTIVE}><Translate id={ ssoMethodOidc !== undefined && ssoMethodOidc?.status == 'enabled' ? 'common.statuses.enabled' : 'common.statuses.disabled'} /> </Tag>}
        statusLabel={translate('common.status') as string}
        size={SizeTypes.STANDARD}
      />
    </>
  )}

const renderAccountSignIn = (tenant: ITenant, accountDetailsPage: boolean, fromPlatformSettings: boolean | undefined, canManageSystemSettings: boolean): React.ReactNode => {
  return (
    <>
      <CardContainer>
        {renderSignInCards(tenant, accountDetailsPage, fromPlatformSettings ? fromPlatformSettings : false, canManageSystemSettings)}
      </CardContainer>
    </>
  )
}

const SignInSettings = (props: signInProps) => {
  const {accountDetailsPage, tenant, fromSettings, canEdit } = props
  return (
    <div className={styles.signInSettings}>
      {renderAccountSignIn(tenant, accountDetailsPage, fromSettings, canEdit)}
    </div>
  )
}

export default SignInSettings