import ActionsList from 'core/actions/Actions.list'
import { buildActionType } from 'core/utils/api'
import { IAction } from 'core/actions/Actions.interfaces'
import { IAuth } from './Reducers.interfaces'

const initialState: IAuth = {
  isAuth: false,
  accessToken: '',
  expiresAt: '',
  licenseStatus: '',
  isHubVisible: false,
  redirectUrl: '',

}

export default function auth (state: IAuth = initialState, action: IAction) {
  switch (action.type) {
    case buildActionType(ActionsList.AuthToken, 'success'):
      return {
        ...state,
        isAuth: true,
        accessToken: action.payload.accessToken,
        expiresAt: action.payload.expiresAt
      }
    case buildActionType(ActionsList.AuthToken, 'failure'):
      return {
        ...state,
        isAuth: false
      }
    case buildActionType(ActionsList.FetchLicense, 'success'):
      return {
        ...state,
        licenseStatus: action.payload.status,
        redirectUrl: action.payload.redirect_url
      }
    case ActionsList.SetHubVisible:
      return {
        ...state,
        isHubVisible: true
      }
    case ActionsList.AuthLogout:
      return initialState
    default:
      return state
  }
}
