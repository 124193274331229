import { ReactElement } from 'react'
import { getTranslate } from 'react-localize-redux'
import { last, startCase } from 'lodash-es'

import store from 'core/store/store'

export function translate(id: string, data: Record<string, string | ReactElement> | undefined = undefined): string {
  return store ? getTranslate(store.getState().localize)(id, data, { onMissingTranslation }).toString() : id
}

export function translateIfExists(id: string, data: Record<string, string | ReactElement> | undefined = undefined): string {
  return store ? getTranslate(store.getState().localize)(id, data, { onMissingTranslation: () => '' }).toString() : ''
}

const MISSING_DEFAULT_TRANSLATION_TEXT = "No default translation found! Ensure you've added translations for your default langauge."

export const handleMissingTranslation = (translationId = ''): string => {
  const customOidFieldPath = 'san.other_name.'
  if (translationId.includes(customOidFieldPath)) {
    const offset = customOidFieldPath.length
    const extractionStartIndex = translationId.indexOf(customOidFieldPath) + offset
    return `SAN: Other Name (Custom) / ${translationId.slice(extractionStartIndex)}`
  }

  return (startCase(last(translationId.split('.'))) as string) || ''
}

export const onMissingTranslation = ({ translationId, defaultTranslation }): string => {
  if (defaultTranslation !== MISSING_DEFAULT_TRANSLATION_TEXT) {
    return defaultTranslation
  }

  return handleMissingTranslation(translationId)
}
