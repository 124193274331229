import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { LocalizeProvider } from 'react-localize-redux'
import App from './App'
import Store from './core/store/store'

ReactDOM.render(
  <Provider store={Store}>
    <LocalizeProvider store={Store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </LocalizeProvider>
  </Provider>,
  document.getElementById('root')
)
