import React, { useEffect, useState } from 'react'
import styles from './OauthCard.module.scss'
import { RouterConfig } from '../../../../core/utils/constants'
import { OauthCardType, clientCard } from './OauthCard.types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import { fetchAllOauthClients } from '../../../tenants/create-account-signing-client/AccountSigningClient.actions'
import { Translate } from 'react-localize-redux'
import { deleteOauthClient, enableDisableOauthClient, regenerateOauthClientSecret } from '../../Settings.actions'
import { translate } from '../../../../shared/helpers/utils'
import { CopyModal, CopyModalStepType, ModalTypes, StandardModal, Tag, TagType } from '@digicert/dcone-common-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const OauthCard = (props: OauthCardType) => {
  const [clients, setClients] = useState<clientCard[]>()
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [generateModalVisible, setGenerateModalVisible] = useState(false)
  const [clientId, setClientId] = useState('')
  const [secret, setSecret] = useState('')

  const onDisableClient = (id: string):void => {
    if(clients) {
      const client = clients.find(client => client.id === id)
      if (client) {
        const payload = {
          enabled: client.status !== 'ENABLED'
        }
        props.enableDisableOauthClient(payload, id).then(() => {
          props.fetchAllOauthClients(props.accountId ? props.accountId : undefined).then((response: any) => {
            setClients(response[0])
          })
        })
      }
    }
  }

  const menu = (client) => (
    <Menu>
      {!client.dtaClient && <Menu.Item key='0' style={{ padding: '0' }}>
        <Link to={`${client.accountId ? RouterConfig.accounts.editOauth(client.accountId, client.id) :  RouterConfig.platformSettings.editOauth(client.id)}`} className={styles.menuItem}>
          <Translate id={'oauthIntegration.update'} />
        </Link>
      </Menu.Item> }
      <Menu.Item key='1' style={{ padding: '0' }}>
        <button className={styles.menuItem} onClick={() => onDisableClient(client.id)}>{client.status === 'ENABLED' ? <Translate id='clientAuth.disable'/> : <Translate id='clientAuth.enable'/>}</button>
      </Menu.Item>
       {!client.dtaClient && <Menu.Item key='2' style={{ padding: '0' }}>
        <button className={styles.menuItem} onClick={() => showDeleteModal(client.id)}><Translate id='clientAuth.delete'/></button>
      </Menu.Item> }
      {!client.dtaClient && <Menu.Item key='3' style={{ padding: '0' }}>
        <button className={styles.menuItem} onClick={() => showGenerateSecretModal(client.id)}><Translate id='clientAuth.generateNewSecret'/></button>
      </Menu.Item>}
    </Menu>
  )

  const showDeleteModal = (id: string) => {
    setClientId(id)
    setDeleteModalVisible(true)
  }

  const onDeleteClient = () => {
    props.deleteOauthClient(clientId).then(() => {
      setDeleteModalVisible(false)
      props.fetchAllOauthClients(props.accountId ? props.accountId : undefined).then((response: any) => {
        setClients(response[0])
      })
    })
  }

  const handleCancel = () => {
    setDeleteModalVisible(false)
  }

  const showGenerateSecretModal = (id: string) => {
    setClientId(id)
    setGenerateModalVisible(true)
  }

  const cancelGenerateSecretModal = () => {
    setGenerateModalVisible(false)
  }

  const onGenerateNewSecret = () => {
    props.regenerateOauthClientSecret(clientId).then((response) => {
      setSecret(response[0].client_secret)
    })
  }

  const generateNewSecretMessage = () => {
    return (
      <>
        <strong><Translate id={'generateSecretOauthIntegration.messageType'}/>:</strong> <Translate id={'generateSecretOauthIntegration.alternateMessage'} />
      </>
    )
  }

  const closeGenerateSecretModal = () => {
    setGenerateModalVisible(false)
  }

  useEffect(() => {
    props.fetchAllOauthClients(props.accountId ? props.accountId : undefined).then((response: any) => {
      if(response) {
        setClients(response[0])
      }
    })
  }, [props, clientId])

  return (
    <div className={styles.oAuthCardContainer}>
      {clients && clients.map(client => {
        return (
          <div className={styles.oAuthCard} key={client.id}>
            {props.canManagePermission &&
              <Dropdown trigger={['click']} overlay={menu(client)}>
                <button
                  className={styles.oAuthCardOptions}
                  aria-label={translate('addOauthIntegration.menuActionsForLabel') as string + ' ' + client.name}
                >
                  <FontAwesomeIcon size='lg' icon={faEllipsisV as IconProp} />
                </button>
              </Dropdown>
            }
            <h2 className={styles.oAuthCardHeadline}>{client.name}</h2>

            <ul className={styles.oAuthCardDetails}>
              <li>
                <p className={styles.oAuthCardLabel}><Translate id={'oauthIntegration.integrationCardStatusLabel'} /></p>
                <Tag type={client.status.toLowerCase() == 'enabled' ? TagType.ACTIVE : TagType.INACTIVE}>
                  <Translate id={`common.statuses.${client.status.toLowerCase()}`} />
                </Tag>
              </li>
              <li>
                <p className={styles.oAuthCardLabel}><Translate id={'oauthIntegration.integrationCardDateLabel'} /></p>
                <p>{client.createdDate.split('T')[0]}</p>
              </li>
              <li>
                <p className={styles.oAuthCardLabel}><Translate id={'oauthIntegration.integrationCardClientIdLabel'} /></p>
                <p>{client.clientId}</p>
              </li>
            </ul>
          </div>)
      })}

      <StandardModal
        modalType={ModalTypes.DESTRUCTIVE}
        headline={translate('deleteOauthIntegration.headline') as string}
        display={deleteModalVisible}
        buttonLabel={translate('deleteOauthIntegration.buttonLabel') as string}
        buttonCancelLabel={translate('deleteOauthIntegration.cancelButtonLabel') as string}
        onOkCallBack={onDeleteClient}
        onCancelCallBack={handleCancel}
      >
        <p><Translate id={'deleteOauthIntegration.description'}/></p>
        <p><Translate id={'deleteOauthIntegration.message'}/></p>
      </StandardModal>

      <CopyModal
        step={CopyModalStepType.INTRO}
        display={generateModalVisible}
        valueToBeCopied={secret}
        headline={translate('generateSecretOauthIntegration.headline')}
        buttonLabel={translate('generateSecretOauthIntegration.completeButtonLabel')}
        buttonCancelLabel={translate('deleteOauthIntegration.cancelButtonLabel')}
        onCancelCallBack={cancelGenerateSecretModal}
        buttonAlternateLabel={translate('generateSecretOauthIntegration.alternateButtonLabel')}
        toolTipMessage={translate('generateSecretOauthIntegration.clipBoardTooltipMessage') as string}
        copiedToolTipMessage={translate('generateSecretOauthIntegration.clipBoardTooltipSuccessMessage') as string}
        introSubHeading={translate('generateSecretOauthIntegration.description')}
        introMessage={translate('generateSecretOauthIntegration.message')}
        introButtonLabel={translate('generateSecretOauthIntegration.buttonLabel')}
        onIntroComplete={onGenerateNewSecret}
        message={generateNewSecretMessage()}
        onOkCallBack={closeGenerateSecretModal}
      />
    </div>
  )
}
export default connect(
  null,
  { fetchAllOauthClients, enableDisableOauthClient, deleteOauthClient, regenerateOauthClientSecret }
)(OauthCard)